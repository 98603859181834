import gql from "graphql-tag";

/**
 * @description Retrieves the table of contents by Subject ID
 */
export const GET_TABLE_OF_CONTENTS = gql`
  query getTableOfContents($subjectId: String!) {
    getTableOfContents(subjectId: $subjectId) {
      _id
      name
      subjectTutorials {
        _id
        displayName
      }
      learningUnits {
        _id
        name
        isArchived
        subjectAssessments {
          _id
          displayName
        }
      }
    }
  }
`;

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { get, map } from "lodash";
import { GET_COURSES } from "./queries";
import CourseCard from "./courseCard";
import { CourseLayout, CardsRow, CardsCol } from "./styled";
import Loading from "../../presentational/Loading";
import { extractMessageFromGraphQLError } from "../../../utils/helpers";
import { openMaintenanceNotification } from "../LearningUnitBuilder/Builder/helpers";

const renderLoadingMenu = () => (
  <CourseLayout>
    <Loading />
  </CourseLayout>
);

const renderErrorMenu = (error) => <CourseLayout>Error: {extractMessageFromGraphQLError(error)}</CourseLayout>;

const CourseCards = () => {
  const { data, loading, error } = useQuery(GET_COURSES);
  if (loading) return renderLoadingMenu();
  if (error) return renderErrorMenu(error);

  const cws = data.getCoursesWithSubjects;

  return (
    <CardsRow>
      {map(cws, (course, i) => (
        <CardsCol key={i}>
          <CourseCard course={course} mockCoverImage={get(course, "cardCover")} />
        </CardsCol>
      ))}
    </CardsRow>
  );
};

export default CourseCards;

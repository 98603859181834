import React from "react";
import { Popconfirm, Icon } from "antd";

const ConfirmDialog = ({ children, placement = "top", title, onConfirm, ...props }) => (
  <Popconfirm
    placement={placement}
    title={title}
    onConfirm={onConfirm}
    okText="Yes"
    cancelText="No"
    icon={<Icon type="exclamation-circle" theme="twoTone" />}
    overlayStyle={{zIndex: 2000}}
    {...props}
  >
    {children}
  </Popconfirm>
);

export default ConfirmDialog;

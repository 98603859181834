import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { INSERT_LEARNING_UNIT_TO_TUTORIAL } from "./mutations";
import { addListItem } from "../reducer";
import { LearningUnitLinkContainer, LearningUnitLinkTextContainer, LearningUnitLinkText } from "../Builder/styled";
import { raiseErrorIfNotString, raiseErrorIfNotFunction, raiseErrorIfNotNumber, raiseErrorIfNotArray } from "../../../../utils/typeErrors";
import {
  AddNewRow,
  AddNewIcon,
  AddNewInput,
  AddNewInputContainer,
  AddNewButtonsContainer,
  AddNewTemplateContainer,
  AddButton,
  CancelButton,
  TemplateRadioGroup,
  TemplateRadioButton,
  AddNewLoadingIcon,
  AddNewSectionContainer,
  CharCount,
} from "./styled";

const AddNew = ({ dispatch, addNewOrder, tutorialId, templates }) => {
  raiseErrorIfNotNumber(addNewOrder);
  raiseErrorIfNotString(tutorialId);
  raiseErrorIfNotArray(templates);
  raiseErrorIfNotFunction(dispatch);

  const [showAddDialog, setShowAddDialog] = useState(false);
  const [insertLearningUnitToTutorial, { loading: loadingInsert }] = useMutation(INSERT_LEARNING_UNIT_TO_TUTORIAL, {
    onCompleted: (data) => {
      dispatch(addListItem(data.insertLearningUnitToTutorial));
      setShowAddDialog(false);
    },
    onError: (data) => {
      setShowAddDialog(false);
    },
  });

  const AddNewButton = () => (
    <LearningUnitLinkContainer>
      <LearningUnitLinkTextContainer>
        {loadingInsert ? <AddNewLoadingIcon /> : <AddNewIcon />}
        <LearningUnitLinkText>ADD NEW</LearningUnitLinkText>
      </LearningUnitLinkTextContainer>
    </LearningUnitLinkContainer>
  );

  const AddNewDialog = () => {
    const [learningUnitName, setLearningUnitName] = useState("");
    const [selectedTemplateId, setSelectedTemplateId] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const maxLength = 80;

    const onTemplateSelect = (e) => {
      setSelectedTemplateId(e.target.value);
    };

    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      if (inputValue.length <= maxLength) {
        setLearningUnitName(inputValue);
      }
    };

    const handleInputFocus = () => {
      setIsFocused(true);
    };
    const handleInputBlur = () => {
      setIsFocused(false);
    };

    const onAddHandler = () => {
      insertLearningUnitToTutorial({
        variables: {
          tutorialId,
          order: addNewOrder.toString(),
          templateId: selectedTemplateId,
          learningUnit: {
            name: learningUnitName,
            isArchived: false,
          },
        },
      });
    };

    return (
      <AddNewSectionContainer>
        <AddNewInputContainer>
          <AddNewInput
            placeholder={"Learning Unit Name"}
            onChange={handleInputChange}
            value={learningUnitName}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          {isFocused && (
            <CharCount>
              {learningUnitName.length}/{maxLength}
            </CharCount>
          )}
        </AddNewInputContainer>
        <AddNewTemplateContainer>
          <TemplateRadioGroup disabled={loadingInsert} onChange={onTemplateSelect} value={selectedTemplateId}>
            {templates.map((template) => (
              <TemplateRadioButton key={template._id} value={template._id}>
                {template.name}
              </TemplateRadioButton>
            ))}
          </TemplateRadioGroup>
        </AddNewTemplateContainer>
        <AddNewButtonsContainer>
          <CancelButton disabled={loadingInsert} onClick={() => setShowAddDialog(false)}>
            CANCEL
          </CancelButton>
          <AddButton disabled={loadingInsert || !learningUnitName} onClick={onAddHandler}>
            ADD
          </AddButton>
        </AddNewButtonsContainer>
      </AddNewSectionContainer>
    );
  };

  return (
    <AddNewRow onClick={showAddDialog ? () => {} : () => setShowAddDialog(!showAddDialog)}>
      <AddNewButton />
      {showAddDialog ? <AddNewDialog /> : ""}
    </AddNewRow>
  );
};

export default AddNew;

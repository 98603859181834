import React from "react";
import { Content } from "../styled";
import { Wrapper, Label } from "./styled";
import StatefulInput from "../../../presentational/StatefulInput";

/**
 * Settings component for snippets
 * 
 * @param  {string} styleClass
 * @param  {callback} onFieldValueChange
 */
export default ({ styleClass, onFieldValueChange }) => {
  const handleChange = (key, value) => {
    onFieldValueChange({ [key]: value });
  };

  return (
    <Content>
      <Wrapper>
        <Label>Style Class:</Label>
        <StatefulInput maxLength={255} value={styleClass} onChange={e => handleChange("styleClass", e.target.value)} />
      </Wrapper>
    </Content>
  );
};

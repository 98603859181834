import React, { useState, Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_TUTORIAL_NAME } from "./queries";
import EditableText from "../../../presentational/EditableText";
import {
  EditIcon,
  TutorialNameEditable,
  TutorialNameInput,
  TutorialLabel,
  TutorialHeadCardContainer,
  TutorialHeadCardContainerTop,
  TutorialHeadCardContainerMiddle,
  TutorialHeadCardContainerBottom,
  TutorialStatusPill,
  DoneIcon,
  CharCount,
} from "./styled";
import CollapseArrowIcon from "../../../presentational/CollapseArrowIcon";

const getLatestStatus = (studentSpecificObjects) =>
  studentSpecificObjects && studentSpecificObjects.length > 0 ? studentSpecificObjects[0].status : "";

const TutorialCardHeader = ({ title, learningUnitsCount, status, tutorialId, isActive, isReadOnly, subjectTutorialId }) => {
  const [editTitle, setEditTitle] = useState(false);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [tutorialTitleInput, setTutorialTitleInput] = useState(title);
  const [tutorialTitle, setTutorialTitle] = useState(title);
  const maxLength = 100;

  const [updateSubjectTutorialDisplayName, { loading: loadingRemoveLU }] = useMutation(UPDATE_TUTORIAL_NAME, {
    onCompleted: (data) => {
      setTutorialTitle(data.updateSubjectTutorialDisplayName.displayName);
    },
    onError: (data) => {
      // TODO: show error toast?
      // console.warn("ERROR PLEASE REFRESH PAGE");
    },
  });
  const handleInputChange = (value) => {
    if (value.length <= maxLength) {
      setTutorialTitleInput(value);
    }
  };

  const handleInputFocus = (editing) => {
    setIsTitleEditing(editing);
  };

  return (
    <TutorialHeadCardContainer
      onClick={(e) => {
        if (editTitle) e.stopPropagation();
      }}
    >
      <TutorialHeadCardContainerTop>
        <TutorialLabel>{!isReadOnly ? "TUTORIAL" : "TUTORIAL (READ ONLY)"}</TutorialLabel>
        <div>{<CollapseArrowIcon direction={isActive ? "up" : "down"} />}</div>
      </TutorialHeadCardContainerTop>
      <TutorialHeadCardContainerMiddle>
        <EditableText
          isEditable={!isReadOnly}
          value={tutorialTitleInput}
          onChange={handleInputChange}
          onEditStateChange={handleInputFocus}
          onBlur={() => {
            if (tutorialTitleInput !== tutorialTitle) {
              updateSubjectTutorialDisplayName({
                variables: {
                  tutorialId,
                  subjectTutorialId,
                  name: tutorialTitleInput,
                },
              });
            }
          }}
        />
        {isTitleEditing && (
          <CharCount>
            {tutorialTitleInput.length}/{maxLength}
          </CharCount>
        )}
      </TutorialHeadCardContainerMiddle>
      <TutorialHeadCardContainerBottom>
        <TutorialLabel>
          {learningUnitsCount} {learningUnitsCount === 1 ? "Learning Unit" : "Learning Units"}
        </TutorialLabel>
      </TutorialHeadCardContainerBottom>
    </TutorialHeadCardContainer>
  );
};

export default TutorialCardHeader;

import React from "react";
import { Layout } from "antd";
import Header from "../container/Header";
import PageFooter from "../container/Footer";
import { ContentLayout } from "./styled";
import CourseCards from "../container/CourseCards";

const Home = () => (
  <Layout>
    <Header fixed={true} />
    <ContentLayout>
      <CourseCards />
    </ContentLayout>
    <PageFooter />
  </Layout>
);

export default Home;

import React from 'react';
import {PdfIcon, ImgIcon, TxtIcon, VideoIcon, FileIcon, AudioIcon} from "../../container/LearningUnitComponentBuilder/FileUploadBuilder/icons";

const WORD_DOC_FORMATS = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template", 
    "application/vnd.ms-word.document.macroEnabled.12", 
    "application/vnd.ms-word.template.macroEnabled.12",
]

/* Returns Icon Depending on Mime Type*/ 
export const getIcon = (type) => {
    if (type === "application/pdf") {
        return <PdfIcon className="iconText"/>
    }
    else if (type.startsWith("image")) {
        return <ImgIcon className="iconText"/>
    }
    else if (type.startsWith("video")) {
        return <VideoIcon className="iconText"/>
    }
    else if (type.startsWith("audio")) {
        return <AudioIcon className="iconText"/>
    }
    else if (WORD_DOC_FORMATS.includes(type)) {
        return <TxtIcon className="iconText"/>
    }
    return <FileIcon className="iconText"/>
}

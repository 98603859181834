import epub from "epub-gen-memory";
import {
  ALLOWED_SNIPPET_TYPES,
  UNALLOWED_SNIPPET_TYPES,
  IMAGE_MIME_TYPES,
  VIDEO_MIME_TYPES,
  ACCESS_PERMISSION_DENIED_PLACEHOLDER_IMAGE_BASE64,
} from "../constants";

const UNSUPPORTED_TYPES = {
  H5P: "H5P",
  VIDEO: "VIDEO",
}

const buildPlaceHolder = (type, url) => {
  if (type === UNSUPPORTED_TYPES.VIDEO) {
    return `<p>Video: <a href="${url}">${url}</a></p>` // 
  }
  return `
  <p style="text-align:center;">
    <table style="width:100%;height:200px;border:1px solid #000">
      <tr>
        <th style="text-align:center;">Placeholder for ${type}</th>
      </tr>
    </table>
  <p>`;
};

const getColorBoxStyle = (styleClass) => {
  if (styleClass.includes("outcomes")) {
    return `
      padding: 2rem;
      background: rgba(0, 209, 178, 0.06);
      border-left: solid 5px #00D1B2;
      margin-bottom: 1.5rem;
    `;
  }
  if (styleClass.includes("example")) {
    return `
      padding: 2rem;
      border-left: solid 5px #FEC058;
      background: #fffaeb;
      margin-bottom: 1.5rem;
    `;
  }
  if (styleClass.includes("case_study")) {
    return `
      padding: 2rem;
      border-left: solid 5px #ff3860;
      background: #fff3f6;
      margin-bottom: 1.5rem;
    `;
  }
  if (styleClass.includes("hint")) {
    return `
      padding: 2rem;
      background: rgba(132, 97, 255, 0.08);
      border-left: solid 5px #8461ff;
      margin-bottom: 1.5rem;
    `;
  }
  if (styleClass.includes("reflect")) {
    return `
      padding: 2rem;
      background: rgba(234, 242, 255, 0.58);
      border-left: solid 5px #2979FF;
      margin-bottom: 1.5rem;
    `;
  }
  return '';
};

const cssStyles = `
.image-style-align-center {
  text-align: center;
}
table td ol, table td ul {
 padding-left: 20px;
 line-height: 1.7;
}
table {
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
  border: #bcbcbc9c 1px solid;
}
figure.table table, figure.table table p {
  color: #172B4D;
}
thead {
  font-weight: normal;
  background-color: #eaf2ff94;
  color: #172b4d;
  font-weight: 600;
}
thead th {
  border-left: #bcbcbc9c 1px solid;
  font-weight: 600;
  border-bottom: #bcbcbc9c 1px solid;
}
th, td {
  text-align: left;
  padding: 10px;
}
tbody tr:nth-child(2n) {
  background-color: #eee6;
}
`;

export const buildEPUB = async ({ subjectData, setExporting, setPercent }) => {
  const subjectName = subjectData.subject.name;
  const chapters = [];
  let imagesToDownloadLength = 0;

  setExporting(true);

  try {
    // tutorials
    subjectData.tutorialsWithPublishedLearningUnits.forEach(({ name: tutorialName, learningUnits }) => {
      let LUs = "";
      learningUnits.forEach(({ learningUnitComponents }) => {
        learningUnitComponents.forEach(({ html, styleClass, fileList, type }) => {
          if (ALLOWED_SNIPPET_TYPES.includes(type)) {
            if (html) {
              const allowedImageFileTypes = [".jpeg", ".jpg", ".svg", ".gif", ".apng", ".webp", ".bmp", ".png"];
              const updateLinksInHTML = (htmlText) => {
                const imageRegex = /<img\s*src=\s*(['"])(https?:\/\/.+?)\1/gi;
                const h5pRegex = /<iframe.*src=".*h5p\.com.*"><\/iframe>/gi;
                const youtubeRegex = /<iframe.*src="(.*?youtube\.com.*?(?=[\?"])).*><\/iframe>/gi;

                if (htmlText.match(imageRegex)) {
                  let link;
                  // eslint-disable-next-line no-cond-assign
                  while ((link = imageRegex.exec(htmlText)) !== null) {
                    const url = link[2];
                    const fileType = url.split("?")[0].substring(url.lastIndexOf(".")).toLowerCase();
                    if (!allowedImageFileTypes.includes(fileType)) {
                      // eslint-disable-next-line no-param-reassign
                      htmlText = htmlText.replace(url, ACCESS_PERMISSION_DENIED_PLACEHOLDER_IMAGE_BASE64);
                    } else {
                      imagesToDownloadLength += 1;
                    }
                  }
                }
                if (htmlText.match(h5pRegex)) {
                  htmlText = htmlText.replaceAll(h5pRegex, buildPlaceHolder(UNSUPPORTED_TYPES.H5P));
                }
                if (htmlText.match(youtubeRegex)) {
                  const url = youtubeRegex.exec(htmlText)[1];
                  htmlText = htmlText.replaceAll(youtubeRegex, buildPlaceHolder(UNSUPPORTED_TYPES.VIDEO, url));
                }
                return htmlText;
              };

              let sanitizedHTML = updateLinksInHTML(html);
              if (styleClass) {
                sanitizedHTML = `<div style="${getColorBoxStyle(styleClass)}">${sanitizedHTML}</div>`
              }

              // console.log("sanitizedHTML: ", sanitizedHTML);
              LUs += sanitizedHTML;
            }
            if (fileList) {
              const fileListArr = JSON.parse(fileList);
              fileListArr.forEach((file) => {
                const { description, url, type: fileType } = file;
                if (IMAGE_MIME_TYPES.includes(fileType)) {
                  LUs += `<figure class="image"><img src="${url}"><figcaption style="text-align: center;"><i>${description}</i></figcaption></figure>`;
                  imagesToDownloadLength += 1;
                } else if (VIDEO_MIME_TYPES.includes(fileType)) {
                  LUs += `<p>Video: <a href="${url}">${description}</a></p>`;
                } else {
                  // placeholder for unsupported formats
                  LUs += buildPlaceHolder(fileType);
                }
              });
            }
          }
          if (UNALLOWED_SNIPPET_TYPES.includes(type)) {
            LUs += buildPlaceHolder(type);
          }
        });
      });
      chapters.push({ title: tutorialName, content: `<html>${LUs}</html>` });
    });

    // console.log("chapters: ", chapters);
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.style = "display: none";
    // https://github.com/cpiber/epub-gen-memory#options
    let i = 0;
    const epubOptions = {
      title: subjectName,
      author: "Mobile Learning Unit",
      ignoreFailedDownloads: true,
      // fonts: [
      //   {
      //     filename: "Merriweather-Regular.ttf",
      //     url: "https://cdn.jsdelivr.net/gh/google/fonts/ofl/merriweather/Merriweather-Regular.ttf"
      //   }
      // ],
      css: cssStyles,
      verbose(type, ...args) {
        if (type === "warn") console.warn(args[0]);
        if (type === "log") {
          const log = args[0];
          console.log(log);
          if (log.includes("Downloaded image")) {
            i++;
            if (imagesToDownloadLength) {
              setPercent(Math.floor(i*100/imagesToDownloadLength));
            }
          }
        }
      },
    };
    const blob = await epub(epubOptions, chapters);
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `${subjectName}.epub`;
    link.click();
    // TODO: remove link after download to prevent memory leak
    setExporting(false);
  } catch (error) {
    console.error("Error when building EPUB:", error);
    setExporting(false);
    setPercent(0);
  }
};

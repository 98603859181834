import React from "react";
import PropTypes from "prop-types";
import defer from "lodash/defer";
import { ButtonMenu, ButtonItem, CallbackButton } from "./styled";
import { getNewSnippet, getNewAssessment, getNewMediaCarousel, getNewFileUpload } from "../Builder/helpers";
import { add } from "../actions";

const handleAdd = (dispatch, fn) => {
  dispatch(add(fn));
  defer(() => document.querySelector(".content-end").scrollIntoView({ behaviour: "smooth" }));
};

const AddNew = ({ title, isOverLimit, dispatch }) => {
  const dataSource = [
    { label: "Add New Snippet", callback: () => handleAdd(dispatch, getNewSnippet()) },
    { label: "Add New Assessment", callback: () => handleAdd(dispatch, getNewAssessment()) },
    { label: "Add New Multimedia", callback: () => handleAdd(dispatch, getNewMediaCarousel()) },
    { label: "Add New File Attachment", callback: () => handleAdd(dispatch, getNewFileUpload())}
  ];

  return (
    <ButtonMenu>
      <ButtonMenu.SubMenu title={title} popupOffset={[10, -10]} selectable={false} disabled={isOverLimit} className="addNewMenu">
        {dataSource.map((item, index) => (
          <ButtonItem key={index}>
            <CallbackButton onClick={item.callback}>
              {item.label}
            </CallbackButton>
          </ButtonItem>
        ))}
      </ButtonMenu.SubMenu>
    </ButtonMenu>
  );
};

AddNew.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  isOverLimit: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default AddNew;

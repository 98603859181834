/**
 * @description Create an image element to capture the image's height and width
 * @param {string} dataUrl - Base 64 Data URL
 */
export const handleImage = (dataUrl) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    // Set the Base64 string as source.
    image.src = dataUrl;
    image.onload = () => {
      resolve({
        height: image.height,
        width: image.width,
      });
    };
  });
};

/**
 * @description Create a video element to capture the video's height and width
 * @param {string} dataUrl - Base 64 Data URL
 */
export const handleVideo = (dataUrl) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    // Set the Base64 string as source.
    video.src = dataUrl;
    video.onloadedmetadata = () => {
      resolve({
        height: video.videoHeight,
        width: video.videoWidth,
      });
    };
  });
};
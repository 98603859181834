import React from "react";
import { parseIfJSON } from "../../../../utils/helpers";
import FileUpload from "../../../presentational/FileUpload";

const FileUploadPreview = ({ fileList, fileName }) => {
    const mediaFileList = Array.isArray(parseIfJSON(fileList)) ? parseIfJSON(fileList) : fileList.toJSON();

    return (
        <FileUpload contents={mediaFileList} />
    )
}
export default FileUploadPreview;


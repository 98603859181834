import styled from "styled-components";
import { Icon } from "antd";
import COLORS from "../../../constants/colors";

export const MediaContainer = styled.div`
  background: ${COLORS.WHITE};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
`;

export const ControlContainer = styled.div`
  ${({ side }) => (side === "left" ? "margin-left: 5px; margin-right: 5px;" : "margin-right: 5px; margin-left: 5px;")}
`;

export const ControlButton = styled(Icon)`
  svg {
    height: 30px;
    width: 30px;
    color: ${COLORS.TRANSLUCENT_LIGHT_BLUE_GRAY};
    &:hover {
      opacity: 0.7;
      color: ${COLORS.DARKGRAY};
    }
  }
`;

export const BarContainer = styled.div`
  height: 80px;
  background: ${COLORS.VERY_LIGHT_BLUE_GRAY};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    display: none;
  }

  // &::-webkit-scrollbar {
  //   -webkit-appearance: none;
  //   width: 7px;
  //   height: 7px;
  // }
  // &::-webkit-scrollbar-thumb {
  //     border-radius: 4px;
  //     background-color: rgba(84,84,84,0.5);
  //     -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.5);
  // }
`;

export const ThumbnailContainer = styled.div`
  position: relative;
  border-radius: 3px;
  height: 60px;
  width: 100px;
  max-height: 50px;
  max-width: 100px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.GRAY};
  background-color: ${COLORS.SOFT_BLUE_GRAY};
  opacity: ${({ selected }) => (selected ? "1" : "0.5")};
  cursor: pointer;
  &:hover {
    opacity: 1;
    border: 2px solid ${COLORS.CREAM};
    // img {
    //   height: 46px;
    // }
    svg {
      color: ${COLORS.CREAM};
    }
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
`;

export const Thumbnail = styled.img`
  height: 50px;
  width: 100px;
  max-height: 50px;
  max-width: 100px;
  border-radius: 6px;
`;

export const PlayOverlay = styled(Icon).attrs({ type: "play-square", theme: "filled" })`
  position: absolute;
  svg {
    height: 30px;
    width: 40px;
    color: ${COLORS.TRANSLUCENT_WHITE};
  }
`;

export const PageContainer = styled.div`
  background: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border-bottom: 2px solid ${COLORS.SOFT_BLUE_GRAY};
  border-top: 2px solid ${COLORS.SOFT_BLUE_GRAY};
  border-right: 2px solid ${COLORS.SOFT_BLUE_GRAY};
  border-left: 2px solid ${COLORS.SOFT_BLUE_GRAY};
  border-radius: 5px;
`;

export const MediaTroubleLink = styled.div`
  background: ${COLORS.WHITE};
  text-align: right;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  font-size: 11px;
  font-family: "Open sans";
`;

import styled from "styled-components";

export const VersionTitle = styled.h3`
  font-weight: 550;
`;

export const VersionAuthor = styled.div`
  padding: 0;
  & > span {
    font-size: 14pt;
    font-weight: bolder;
    padding-left: 15px;
    padding-right: 3px;
  }
`;

export const VersionTimestamp = styled.h6``;

export const VersionInfo = styled.div`
  flex: 1;
`;
export const VersionMenu = styled.div`
  width: 20px;
`;

export const VersionMenuButton = styled.h1``;

export const VersionContainer = styled.div`
  &&& {
    width: 250px;
    padding: 10px 0px 10px 10px;
    text-align: left;
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? "aqua" : "white")};
    border-bottom: solid 1px lightgrey;
    &:hover {
      background-color: ${({ selected }) => (selected ? "aqua" : "#f0f0f0")};
    }
  }
`;

export const VersionDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const VersionActionContainer = styled.div`
  border-top: 1px blue solid;
  padding-top: 5px;
  display: flex;
  justify-content: center;
`;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, InputNumber } from "antd";

/**
 * Reusable input component using useState
 * By default this creates a standard <Input /> for text
 * Or add a 'numeric' prop to create an <InputNumber /> that takes only numeric
 * Or add a 'percentage' prop to create an <InputNumber /> with an '%'
 * 
 * @param {integer/string} value 
 * @param {onChange} callback 
 * @param {*} props 
 */
const StatefulInput = ({ numeric, percentage, value, onChange, ...props }) => {
  const [ ownValue, setOwnValue ] = useState(value);

  useEffect(() => {
    if (ownValue !== value) {
      setOwnValue(value);
    }
  }, [value])

  /**
   * Parse the input to integer before saving to state. If the output is Nan, return null instead
   * @param {*} val 
   * @returns {integer|null}
   */
  const handleNumeric = val => {
    let newValue;
    const parsed = parseInt(val, 10);
    if (Number.isNaN(parsed)) newValue = null;
    else newValue = parsed;
    setOwnValue(newValue);
    onChange(newValue);
  };

  if (percentage) {
    return (
      <InputNumber {...props} formatter={val => `${val}%`} parser={val => val.replace("%", "")} value={ownValue} onChange={handleNumeric} />
    );
  }

  if (numeric) {
    return <InputNumber {...props} value={ownValue} onChange={handleNumeric} />;
  }

  return (
    <Input
      {...props}
      value={ownValue}
      onChange={e => {
        setOwnValue(e.target.value);
        onChange(e);
      }}
    />
  );
};

StatefulInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default StatefulInput;

import styled, { css } from "styled-components";
import { Typography } from "antd";
import COLORS from "../constants/colors";

// For components that needs to expand across the page, plain 100vw/h will cause scrollbars to show, 
// so we add a max-____ to adjust it while keeping the viewport property.
export const FullWidth = css`
  width: 100vw;
  max-width: 100%;
`;
export const FullHeight = css`
  height: 100vh;
  max-height: 100%;
`;
export const FullPage = css`
  ${FullWidth} 
  ${FullHeight}
`;

// A standard flex container that fills up the whole webpage
export const Container = styled.div.attrs({
  className: "Container"
})`
  ${FullPage}
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Label for forms throughout the app
export const Label = styled(Typography.Text)`
  color: ${COLORS.DARKGREY};
`;
// Label but bolded
export const Strong = styled(Label).attrs({ strong: true })``;
import React, { useState, Fragment } from "react";
import { Typography } from "antd";
import { isEmpty } from "lodash";
import { Accordion, CleanPanel, Arrow } from "./styled";

const { Text } = Typography;

const Header = ({ header, isActive }) => (
  <Text strong={true}>
    {header}
    <Arrow rotate={isActive ? 90 : 0} />
  </Text>
);

/**
 * Creates a collapsable help text over a component
 * 
 * @param  {string} heading
 * @param  {string} body
 */
export default ({ heading, body }) => {
  const [ isActive, setIsActive ] = useState(true);
  return (
    <Accordion onChange={key => setIsActive(!!key)}>
      <CleanPanel header={<Header header={heading} isActive={isActive} />}>
        <Text>{body}</Text>
      </CleanPanel>
    </Accordion>
  );
};

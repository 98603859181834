import { List } from "immutable";

export const REORDER = "REORDER";
export const REMOVE = "REMOVE";
export const ADD = "ADD";

export const reorderListItem = (sourceIndex, destinationIndex) => ({ type: REORDER, sourceIndex, destinationIndex });
export const removeListItem = (_id) => ({ type: REMOVE, _id });
export const addListItem = (learningUnit) => ({
  type: ADD,
  learningUnit,
});

// helper
const reorder = (list, startIndex, endIndex) => {
  const itemAtStartIndex = list.get(startIndex);
  return list.delete(startIndex).insert(endIndex, itemAtStartIndex);
};

// Reducer
export const reducer = (state, action) => {
  switch (action.type) {
    case REORDER:
      return {
        ...state,
        learningUnits: reorder(state.learningUnits, action.sourceIndex, action.destinationIndex),
      };
    case REMOVE:
      return { ...state, learningUnits: state.learningUnits.filter((lu) => lu._id !== action._id) };
    case ADD:
      return { ...state, learningUnits: List([...state.learningUnits, action.learningUnit]) };
    default:
      return state;
  }
};

import React, { useState, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { List } from "immutable";
import DroppablePalette from "../../../presentational/DroppablePalette";
import DraggableItem from "../../../presentational/DraggableItem";
import DragHandle from "../../../presentational/DragHandle";
import { FILE_UPLOAD_MUTATION } from "./queries";
import {
  BuilderContainer,
  FileInput,
  FileInputIcon,
  FileInputLabel,
  FileUploadContainer,
  FileUploadErrorMessage,
  IconAndTextContainer,
  UploadIcon,
  StyledInputText,
  MediaEditorContainer,
  MediaEditorFormContainer,
  MediaEditorForm,
  FieldsContainer,
  VideoSettingsContainer,
  ThumbnailContainer,
  Thumbnail,
  PlayOverlay,
  RemoveIcon,
  DraggableItemContainer,
  VideoSettingInputContainer,
  VideoSettingInput,
  VideoSettingLabel,
} from "./styled";
import { Content } from "../styled";
import { getUploadInfo, getAcceptedMimeTypes } from "./helpers";
import { addFile, updateFile, reorderFile, removeFile } from "./actions";
import { parseIfJSON, extractMessageFromGraphQLError } from "../../../../utils/helpers";
import reducer from "./reducer";

const { useMutation } = require("@apollo/react-hooks");

/**
 * @description Video Setting checkbox
 * @param {string} id - The element Id of a video setting
 * @param {string} label - Label for the checkbox element
 * @param {boolean} value - Value is true if checked. Otherwise false
 * @param {function} onChange - Event function when the checkbox value is changed
 */
const VideoSetting = ({ id, label, value, onChange }) => {
  return (
    <VideoSettingInputContainer>
      <VideoSettingInput id={id} checked={value} onChange={onChange} />
      <VideoSettingLabel htmlFor={id}>{label}</VideoSettingLabel>
    </VideoSettingInputContainer>
  );
};

/**
 * @description Media Editor for each file on the list
 * @param {string} mediaId - The file id
 * @param {object} file - File metadata
 * @param {function} localDispatch - Dispatches a reducer action
 * @param {number} fileIndex - A file's index on the list
 */
const MediaEditor = ({ mediaId, file, localDispatch, fileIndex }) => {
  return (
    <MediaEditorFormContainer key={mediaId} className="mediaEditorItem">
      <MediaEditorForm>
        <ThumbnailContainer key={`${file._id}.thumbnail`}>
          {!!file.thumbnailUrl && <Thumbnail src={file.thumbnailUrl} alt="" />}
          {(file.type.startsWith("video") || file.type.startsWith("audio")) && <PlayOverlay />}
        </ThumbnailContainer>

        <FieldsContainer>
          <StyledInputText
            className="mediaEditorItemDescription"
            maxLength={1000}
            value={file.description}
            onChange={(event) =>
              localDispatch(
                updateFile(fileIndex, {
                  description: event.target.value,
                }),
              )
            }
          />

          <VideoSettingsContainer>
            {(file.type.startsWith("video") || file.type.startsWith("audio")) && (
              <>
                <VideoSetting
                  id={`video-auto-play-${mediaId}-${fileIndex}`}
                  label="Auto Play"
                  value={file.autoPlay}
                  onChange={(event) =>
                    localDispatch(
                      updateFile(fileIndex, {
                        autoPlay: event.target.checked,
                      }),
                    )
                  }
                />

                <VideoSetting
                  id={`video-auto-loop-${mediaId}-${fileIndex}`}
                  label="Auto Loop"
                  value={file.autoLoop}
                  onChange={(event) =>
                    localDispatch(
                      updateFile(fileIndex, {
                        autoLoop: event.target.checked,
                      }),
                    )
                  }
                />
              </>
            )}

            <VideoSetting
              id={`video-hide-captions-${mediaId}-${fileIndex}`}
              label="Hide Captions"
              value={file.hideCaptions}
              onChange={(event) =>
                localDispatch(
                  updateFile(fileIndex, {
                    hideCaptions: event.target.checked,
                  }),
                )
              }
            />
          </VideoSettingsContainer>
        </FieldsContainer>
      </MediaEditorForm>
      <RemoveIcon onClick={() => localDispatch(removeFile(fileIndex))} className="mediaEditorItemRemoveIcon" />
    </MediaEditorFormContainer>
  );
};

/**
 * @description Initialise the reducer
 * @param {object} initialValues - Initial Values
 */
const init = (initialValues) => {
  // Ensure that the file list is immutable
  const immutableFileList = Array.isArray(parseIfJSON(initialValues.fileList))
    ? List(parseIfJSON(initialValues.fileList))
    : initialValues.fileList;

  return {
    fileList: immutableFileList,
    lucId: initialValues.lucId,
    commitChanges: initialValues.commitChanges,
  };
};

// Verify if component is running Jest
const isTestRunning = process.env.NODE_ENV === "test" && process.env.JEST_WORKER_ID !== undefined;

/**
 * @description The main component for the Media Carousel Builder
 * @param {object} media - The learning unit component element
 * @param {function} onContentChange - Handles the change action
 * @param {boolean} showComments - Show/Hide commenting buttons
 */
const MediaCarouselBuilder = ({ learningUnitId, tutorialId, media, onContentChange }) => {
  const fileList = !!media && media.fileList;
  const mediaId = !!media && media._id;
  const [localState, localDispatch] = useReducer(
    reducer,
    { fileList, lucId: mediaId, commitChanges: onContentChange },
    init,
  );
  
  const [uploadFiles, { loading, called, error, data: mediaCarouselData }] = useMutation(FILE_UPLOAD_MUTATION);
  const [fileUploadError, setFileUploadError] = useState("");

  useEffect(() => {
    if (!!error) {
      setFileUploadError("Your request has timed out. Please check your internet connection or upload a smaller file. ");
    }
  }, [error])

  /**
   * @description Handle the event when files have been selected
   * @param {object} validity - Object which contains validity info about the files
   * @param {object} files - Object which contains the list of files selected
   */
  const onChangeFiles = async ({ target: { validity, files } }) => {
    if (files) {
      // Reset error message
      setFileUploadError("");
      // Make files immutable
      const filesToUpload = List(files);
      try {
        // Get the file info (e.g. height, width)
        const filesWithInfo = await Promise.all(filesToUpload.map((file) => getUploadInfo(file)));
        // Check if the files are valid
        if (!!filesWithInfo && (validity.valid || isTestRunning)) {
          try {
            const result = await uploadFiles({ variables: { files: filesWithInfo, learningUnitId, tutorialId } });
            const newContent = List(result.data.uploadFiles);
            // Add the file to the learning unit component
            localDispatch(addFile(newContent));
          } catch (graphQLError) {
            setFileUploadError(extractMessageFromGraphQLError(graphQLError));
          }
        }
      } catch (uploadInfoError) {
        setFileUploadError(uploadInfoError.message);
      }
    }
  };

  /**
   * @description Handle drag end
   * @param {*} result
   */
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;
    localDispatch(reorderFile(result.source.index, result.destination.index));
  };

  // Get accepted mime types
  const acceptedMimeTypes = getAcceptedMimeTypes();

  // Render
  return (
    <Content>
      <BuilderContainer>
        <FileUploadContainer>
          {loading ? ( // Loading state
            <IconAndTextContainer>
              <UploadIcon />
              <div>Uploading new media files...</div>
            </IconAndTextContainer>
          ) : (
            // Render file input
            <FileInputLabel htmlFor={`file-upload-${mediaId}`}>
              <FileInput
                id={`file-upload-${mediaId}`}
                className="fileUploadField"
                accept={acceptedMimeTypes}
                multiple
                required
                onChange={onChangeFiles}
              />
              <IconAndTextContainer>
                <FileInputIcon />
                <div>Add or Drop Media Files</div>
              </IconAndTextContainer>
            </FileInputLabel>
          )}

          {fileUploadError && <FileUploadErrorMessage className="fileUploadErrorMsg">{fileUploadError}</FileUploadErrorMessage>}
        </FileUploadContainer>

        <MediaEditorContainer className="mediaEditorContainer">
          <DroppablePalette onDragEnd={onDragEnd}>

            {!!localState.fileList &&
              localState.fileList.map((file, index) => (
                <DraggableItemContainer key={file._id}>
                  <DraggableItem draggableId={file._id} index={index} dragHandle={DragHandle}>
                    <MediaEditor mediaId={mediaId} file={file} localDispatch={localDispatch} fileIndex={index} />
                  </DraggableItem>
                </DraggableItemContainer>
              ))}
          </DroppablePalette>
        </MediaEditorContainer>
      </BuilderContainer>
    </Content>
  );
};

MediaCarouselBuilder.propTypes = {
  learningUnitId: PropTypes.string.isRequired,
  tutorialId: PropTypes.string.isRequired,
  media: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    fileList: PropTypes.object.isRequired,
  }).isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default MediaCarouselBuilder;

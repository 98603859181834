import styled, { css } from "styled-components";
import { Icon } from "antd";
import COLORS from "../../../constants/colors";

const hide = css`display: none;`;
const show = css`display: block;`;

const actionIcons = styled(Icon)`
  padding: 3px;
  font-size: 1rem;
  + .anticon {
    padding-top: 6px;
  }
`;

export const Container = styled.div`display: flex;`;

export const LearningUnitActionsContainer = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  min-height: 45px;
`;

export const ShowActionsIcon = styled(actionIcons).attrs({ className: "ShowActionsIcon", type: "ellipsis" })`
  /* ${props => (props.show ? show : hide)}; */
`;

export const ActionIconsContainer = styled.div.attrs({ className: "ActionIconsContainer" })`
  display: flex;
  flex-direction: column;`;

export const RemoveFromPalette = styled(actionIcons).attrs({ className: "RemoveFromPalette", type: "delete" })``;

export const CloneComponent = styled(actionIcons).attrs({ className: "CloneComponent", type: "copy" })``;

export const Settings = styled(actionIcons).attrs(props => ({
  className: "Settings",
  type: "setting",
  theme: props.theme,
}))``;

// Container for child components
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 0.5px solid ${COLORS.LIGHTGRAY};
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  background-color: ${COLORS.WHITE};
`;

import React, { memo } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { updateQuestionTextInAllQuestions, removeQuestionTextInAllQuestions } from "../actions";
import { ListContainer, AnswerEditorContainer, Remove } from "../styled";
import { DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS, EDITOR_LENGTH } from "../../../../../constants/miscellaneous";
import Editor from "../../../Editor";

export const efficientUpdateQuestionText = debounce((dispatch, lucId, questionIndex, questionFields) => {
  dispatch(updateQuestionTextInAllQuestions(lucId, questionIndex, questionFields, true));
}, DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS);

/**
 * @param  {string} question
 * @param  {number} questionIndex
 * @param  {string} assessmentOpportunityId
 * @param  {callback} dispatch
 */
const QuestionRow = ({ question, questionIndex, assessmentOpportunityId, dispatch }) => {
  return (
    <ListContainer>
      <AnswerEditorContainer>
        <Editor
          maxLength={EDITOR_LENGTH.SURVEY_QUESTION}
          inline
          basic
          content={question}
          onChange={({ content }) => {
            efficientUpdateQuestionText(dispatch, assessmentOpportunityId, questionIndex, {
              question: content,
            });
          }}
        />
      </AnswerEditorContainer>
      <Remove
        className="RemoveQuestionRow"
        onClick={() => {
          dispatch(removeQuestionTextInAllQuestions(assessmentOpportunityId, questionIndex));
        }}
      />
    </ListContainer>
  );
};

QuestionRow.propTypes = {
  question: PropTypes.string.isRequired,
  questionIndex: PropTypes.number.isRequired,
  assessmentOpportunityId: PropTypes.string.isRequired,
};

export default memo(QuestionRow);

import React from "react";
import {
  ScaleRowContainer,
  ScaleQuestionRowContainer,
  ScaleQuestionCellContainer,
  ScaleCellsContainer,
  CenteredScaleCellsContainer,
  RichText,
  ScaleRadioGroup,
  ScaleRadio,
} from "./styled";

const SurveyScaleNames = ({ answerOptions }) => {
  return (
    <ScaleRowContainer>
      <ScaleQuestionCellContainer />
      <CenteredScaleCellsContainer>
        {answerOptions.map((option) => (
          <RichText key={option._id} html={option.value} />
        ))}
      </CenteredScaleCellsContainer>
    </ScaleRowContainer>
  );
};

const SurveyQuestion = ({ question, answerOptions, selectedAnswerValue, isLoading, isSubmitted, onOptionSelect }) => {
  const disabled = isLoading || isSubmitted;

  const onRadioButtonSelect = (e) => {
    const { value } = e.target;
    onOptionSelect(value);
  };

  return (
    <ScaleQuestionRowContainer>
      <ScaleQuestionCellContainer>
        <RichText html={question} />
      </ScaleQuestionCellContainer>
      <ScaleCellsContainer>
        <ScaleRadioGroup disabled={disabled} onChange={onRadioButtonSelect} value={selectedAnswerValue}>
          {answerOptions.map(({ _id }) => (
            <ScaleRadio key={_id} value={_id} />
          ))}
        </ScaleRadioGroup>
      </ScaleCellsContainer>
    </ScaleQuestionRowContainer>
  );
};

export { SurveyScaleNames, SurveyQuestion };

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  TitleRow,
  TitleLeftArea,
  TitleRightArea,
  BuilderContainer,
  PaletteContainer,
  TutorialsColumn,
  SubjectTitleContainer,
} from "./styled";
import { TitleContainer } from "../styled";
import SubjectTitle from "../SubjectTitle";
import TutorialBuilder from "../TutorialBuilder";
import Export from "../Export";
import { GET_USER_EXPORT_PERMISSION } from "./queries";

const Builder = ({ course, campaigns, subject, tutorials, templates }) => {

  const { data } = useQuery(GET_USER_EXPORT_PERMISSION)

  // Only one tutorial should be marked as an exam per subject 
  return (
    <BuilderContainer>
      <SubjectTitleContainer>
        <TitleContainer>
          <TitleRow>
            <TitleLeftArea>
              <SubjectTitle courseName={course.name} subjectName={subject.name} />
            </TitleLeftArea>
            <TitleRightArea>
              {data?.getUserExportPermission && <Export subjectId={subject._id} />}
            </TitleRightArea>
          </TitleRow>
        </TitleContainer>
      </SubjectTitleContainer>
      <PaletteContainer>
        <TutorialsColumn>
          {tutorials.map((tutorial, index) => (
            <TutorialBuilder
              key={tutorial._id}
              // collapse does not detect key with 0
              panelKey={index+1}
              subjectId={subject._id}
              tutorial={tutorial}
              templates={templates}
            />
          ))}
        </TutorialsColumn>
      </PaletteContainer>
    </BuilderContainer>
  );
};

export default Builder;

import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Popover } from "antd";
import { useHistory } from "react-router-dom";
import { CLONE_EXISTING_LEARNING_UNIT_VERSION } from "./mutations";
import {
  VersionDisplayContainer,
  VersionTitle,
  VersionAuthor,
  VersionTimestamp,
  VersionInfo,
  VersionMenu,
  VersionMenuButton,
  VersionContainer,
} from "./styled";

const VersionDisplay = ({ version, selected, setSelectedVersion, subjectId, tutorialId, learningUnit }) => {
  const history = useHistory();
  const [cloneNewLearningUnitVersion] = useMutation(CLONE_EXISTING_LEARNING_UNIT_VERSION, {
    onCompleted: (data) => {
      const newVersion = data.cloneExistingLearningUnitVersion._id;
      history.push(`/sbj${subjectId}/tut${tutorialId}/buildlearningunit${learningUnit._id}/`);
      setSelectedVersion("");
    },
  });

  const [visible, setVisible] = useState(false);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setVisible(true);
    setSelectedVersion(version._id);
  };

  const handleCreateCopy = (event) => {
    event.stopPropagation();
    setVisible(false);
    cloneNewLearningUnitVersion({
      variables: {
        learningUnitVersionId: version._id,
      },
    });
  };

  const handleVersionSelect = () => {
    setSelectedVersion(version._id);
    history.push(`/sbj${subjectId}/tut${tutorialId}/buildlearningunit${learningUnit._id}/v${version._id}`);
  };

  const handleVisibleChange = (v) => {
    setVisible(v);
  };

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const timestamp = new Date(Number(version.createdDateTime));
  const dateString = timestamp.toLocaleDateString("en-GB", { timeZone: "Australia/Melbourne" });
  // const timeString = timestamp.toLocaleTimeString("en-GB", { timeZone: "Australia/Melbourne" });
  const timeString = formatAMPM(timestamp);

  return (
    <VersionContainer selected={selected} onClick={handleVersionSelect}>
      <VersionDisplayContainer>
        <VersionInfo>
          <VersionTitle>v{version.currentVersionNumber}</VersionTitle>
          <VersionAuthor>{version.publishedByContact === null ? " " : version.publishedByContact.name}</VersionAuthor>
          <VersionTimestamp>
            {dateString}
            <br />
            {timeString}
            <br />
          </VersionTimestamp>
        </VersionInfo>
        {selected && (
          <VersionMenu>
            <Popover
              placement="left"
              content={<a onClick={handleCreateCopy}>Create a Copy</a>}
              trigger="click"
              visible={visible}
              onVisibleChange={handleVisibleChange}
            >
              <VersionMenuButton onClick={handleMenuClick}>⋮</VersionMenuButton>
            </Popover>
          </VersionMenu>
        )}
      </VersionDisplayContainer>
    </VersionContainer>
  );
};

export default VersionDisplay;

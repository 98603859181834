import React from "react";
import DOMPurify from "dompurify";
import { SnippetContainer, RichTextContainer } from "./styled";
import { pipe } from "../../../utils/helpers";

// Add a hook to make all links open a new window
DOMPurify.addHook("afterSanitizeAttributes", (node) => {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
    node.setAttribute("rel", "noopener noreferrer");
  }
});

const sanitizeWithIframes = (html) => DOMPurify.sanitize(html, { ADD_TAGS: ["iframe"] });
const removeStyleTags = (html) => html.replace(/(<[^>]+) (style=".*?")/i, (_p1, p2) => p2);
const removeNBSP = (html) => html.replace(/&nbsp;/g, " "); // removed to preserve new line spacing
const appendBaseURLToHashLink = (baseURL) => (html) =>
  html.replace(/(<a.+href=")(#.+)(")/g, (_fullMatch, grp1, grp2, grp3) => `${grp1}${baseURL}${grp2}${grp3}`);

export const RichText = ({ html, pageURL }) => {
  const finalHtml = pipe(DOMPurify.sanitize, removeStyleTags, appendBaseURLToHashLink(pageURL))(html);
  return <RichTextContainer dangerouslySetInnerHTML={{ __html: finalHtml }} />;
};

const Snippet = ({ html, pageURL, styleClass }) => {
  const finalHtml = pipe(sanitizeWithIframes, appendBaseURLToHashLink(pageURL))(html);
  return <SnippetContainer className={styleClass} dangerouslySetInnerHTML={{ __html: finalHtml }} />;
};

export default Snippet;

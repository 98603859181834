import React from "react";
import { useHistory } from "react-router-dom";
import {
  VersionDisplayContainer,
  VersionTitle,
  VersionAuthor,
  VersionTimestamp,
  VersionInfo,
  VersionMenu,
  VersionMenuButton,
  VersionContainer,
} from "./styled";

const DraftVersionDisplay = ({ lastModifiedBy, selected, setSelectedVersion, subjectId, tutorialId, learningUnitId }) => {
  const history = useHistory();
  const url = `/sbj${subjectId}/tut${tutorialId}/buildlearningunit${learningUnitId}/`;
  const handleVersionSelect = () => {
    setSelectedVersion("");
    history.push(url);
  };

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const timestampStr = lastModifiedBy.timestamp || "0";
  const timestamp = new Date(Number.parseInt(timestampStr, 10));
  const dateString = timestamp.toLocaleDateString("en-GB", { timeZone: "Australia/Melbourne" });
  // const timeString = timestamp.toLocaleTimeString("en-GB", { timeZone: "Australia/Melbourne" });
  const timeString = formatAMPM(timestamp);
  return (
    <VersionContainer selected={selected} onClick={handleVersionSelect}>
      <VersionDisplayContainer>
        <VersionInfo>
          <VersionTitle>Draft</VersionTitle>
          <VersionAuthor>{lastModifiedBy.name}</VersionAuthor>
          <VersionTimestamp>
            {dateString}
            <br />
            {timeString}
          </VersionTimestamp>
        </VersionInfo>
      </VersionDisplayContainer>
    </VersionContainer>
  );
};

export default DraftVersionDisplay;

import { useEffect, useState, useRef } from "react";
import debounce from "lodash/debounce";
import { DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS } from "../constants/miscellaneous";

export const usePrevious = (state, deps) => {
  const prevState = useRef();
  useEffect(() => {
    prevState.current = state;
  }, deps);
  return prevState.current;
};

export const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};

export const useWindowSize = () => {
  const isClient = typeof window === "object";

  const getSize = () => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  });

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = () => {
      setWindowSize(getSize());
    };

    const debuoncedHandleResize = debounce(handleResize, DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS);

    window.addEventListener("resize", debuoncedHandleResize);
    return () => window.removeEventListener("resize", debuoncedHandleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export function useDidUpdate(callback, deps) {
  const hasMount = useRef(false);
  useEffect(() => {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export const useNavigatorOnline = (whenOnline = "online", whenOffline = "offline") => {
  const [value, setValue] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setValue(window.navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  const isOnline = value === true;
  const isOffline = value === false;
  const status = isOnline ? whenOnline : whenOffline;

  return { status, isOnline, isOffline };
};

import { toast } from "react-toastify";

export const toastConfig = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnVisibilityChange: true,
  draggable: true,
  pauseOnHover: true,
};

export const notifyOfflineToastConfig = {
  autoClose: false,
  closeOnClick: false,
  closeButton: false,
};

export const configureToasts = () => toast.configure(toastConfig);

import styled from "styled-components";
import { Card as StyledCard, Radio as StyledRadio, Input } from "antd";
import Title from "antd/lib/typography/Title";
import COLORS from "../../../constants/colors";

const Card = styled(StyledCard).attrs({
  bodyStyle: { padding: "20px" },
})`
  text-align: left;
  &&& {
    border-width: 0px;
  }
`;

const RadioGroup = styled(StyledRadio.Group)`
  width: 100%;
  display: flex;
  flex-direction: column;
  &&& {
    .ant-radio-button-wrapper {
      margin: 5px 0;
      padding: 0;
      line-height: 1.7rem;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      width: 0px;
    }
  }
`;

const SolutionCard = styled(StyledCard).attrs({
  headStyle: {
    backgroundColor: `${COLORS.LIGHTGREY}`,
    color: `${COLORS.DARKGREY}`,
    fontWeight: "bolder",
    padding: "0px 10px",
  },
  bodyStyle: {
    backgroundColor: `${COLORS.LIGHTGREY}`,
    padding: "10px 10px",
  },
  type: "inner",
})`
  &&& {
    border-radius: 3px;
    margin-top: 5px;
    border: solid 1px ${COLORS.GREY};
    max-width: 800px;
    width: 100%;
  }
`;

const TextInput = styled(Input)`
  &&& {
    border-color: ${COLORS.GREY};
    color: ${COLORS.DARKGREY};

    :hover {
      border-color: ${COLORS.BLUE};
      color: ${COLORS.BLUE};
    }

    :focus {
      border-color: ${COLORS.BLUE};
      color: ${COLORS.BLUE};
    }
  }
`;
const TextArea = styled(TextInput.TextArea).attrs({ rows: 2, maxLength: 130000, autoSize: true })``;

const QuestionText = styled.div`
  padding-bottom: 10px;
  > div {
    font-size: 16px;
    color: ${COLORS.DARKERGREY};
  }
`;

const SurveyCardWrapper = styled(StyledCard).attrs()`
  text-align: left;
  &&& {
    border-width: 0px;
  }
`;

export { Card, RadioGroup, QuestionText, SolutionCard, TextInput, TextArea, SurveyCardWrapper };

import styled from "styled-components";
import { Radio as StyledRadio, Icon as StyledIcon } from "antd";

export { RichText } from "../../Snippet";

export const ScaleRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.5rem 0;
`;

export const ScaleQuestionRowContainer = styled(ScaleRowContainer).attrs({ className: "scaleQuestionRow" })`
  & p {
    word-break: break-word;
    hyphens: auto;
  }
  :nth-of-type(2) {
    border-top: solid #e6e6e6 2px;
  }

  :nth-of-type(even) {
    background: #f6f6f6;
  }

  :last-of-type {
    border-bottom: solid #e6e6e6 2px;
    margin-bottom: 0.5rem;
  }
  padding-left: 0.5rem;
`;

export const ScaleQuestionCellContainer = styled.div`
  width: 30%;
`;

export const ScaleCellsContainer = styled.div`
  width: 70%;
  text-align: center;

  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  & > div {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow: hidden;
    padding-right: 0.5rem;
  }
`;

export const CenteredScaleCellsContainer = styled(ScaleCellsContainer)`
  & > div {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  & p {
    word-break: break-word;
    hyphens: auto;
  }
`;

export const ScaleRadioGroup = styled(StyledRadio.Group)`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const ScaleRadio = styled(StyledRadio)`
  margin: 0;
`;

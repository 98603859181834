import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, RadioGroup, QuestionText } from "../styled";

import Explanation from "../Explanation";
import AnswerOption from "../AnswerOption";
import { RichText } from "../../Snippet";

const renderAnswerOption = (answerOption, selectedAnswerValue, correctAnswerValue, isSubmitted, showExplanation) => {
  const { _id, value } = answerOption;
  const isSelected = _id === selectedAnswerValue;
  const isCorrect = _id === correctAnswerValue;

  let answerType = "unselected";

  if (isSelected) {
    answerType = "selected";
    if (isSubmitted) {
      answerType = isCorrect ? "correct" : "incorrect";
    }
  }

  if (isSubmitted && isCorrect && showExplanation) {
    answerType = "correct";
  }

  return (
    <AnswerOption key={_id} value={value} type={answerType}>
      <RichText html={value} />
    </AnswerOption>
  );
};

const SingleAnswerQuestion = ({
  question,
  isSubmitted,
  isLoading,
  explanation,
  selectedAnswerValue,
  correctAnswerValue,
  answerOptions,
  onAnswerSelect,
  showExplanation,
}) => {
  const handleAnswerChange = (e) => {
    onAnswerSelect(e.target.value);
  };

  const disabled = isSubmitted || isLoading;
  const isCorrectAnswerSelected = selectedAnswerValue === correctAnswerValue;
  return (
    <Card>
      <QuestionText>
        <RichText html={question} />
      </QuestionText>
      <RadioGroup
        size="large"
        className={disabled ? "inactiveRadio" : "activeRadio"}
        disabled={disabled}
        value={selectedAnswerValue}
        onChange={handleAnswerChange}
      >
        {answerOptions.map((answerOption) =>
          renderAnswerOption(answerOption, selectedAnswerValue, correctAnswerValue, isSubmitted, showExplanation),
        )}
      </RadioGroup>
      <Explanation
        isSubmitted={isSubmitted}
        explanation={explanation}
        showExplanation={(isSubmitted && isCorrectAnswerSelected) || showExplanation}
      />
    </Card>
  );
};

SingleAnswerQuestion.defaultProps = {
  showExplanation: true,
};

SingleAnswerQuestion.propTypes = {
  question: PropTypes.string,
  explanation: PropTypes.string,
  showExplanation: PropTypes.bool,
  onAnswerSelect: PropTypes.func,
  isSubmitted: PropTypes.bool,
  correctAnswerValue: PropTypes.string,
  selectedAnswerValue: PropTypes.string,

  answerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export { SingleAnswerQuestion };

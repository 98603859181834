import styled from "styled-components";
import { Layout } from "antd";
import { HEADER_HEIGHT, FOOTER_HEIGHT, TABLE_OF_CONTENT_WIDTH } from "../../constants/miscellaneous";
import COLORS from "../../constants/colors";

export const PageLayout = styled.div`
  display: flex;
`;

export const PageContent = styled.div`
  width: ${({ collapsedMenu }) => (collapsedMenu ? `calc(100vw - ${TABLE_OF_CONTENT_WIDTH}px)` : "100vw")};
  ${({ viewHeight }) => (!viewHeight ? "" : `height: ${viewHeight}px`)};
  ${({ viewHeight }) => (!viewHeight ? "" : `max-height: ${viewHeight}px`)};
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ContentLayout = styled(Layout.Content)`
  padding-top: 50px;
  min-height: calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px);
`;

export const VersionMenuContainer = styled.div`
  background-color: ${COLORS.WHITE};
  margin: 5px 5px 0px 5px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  width: ${({ collapsed }) => (collapsed ? 0 : 250)}px;
  transition: width 0.2s;
  // To avoid lines from wrapping during transition
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VersionMenuTitle = styled.div`
  padding: 10px;
  border-bottom: solid 1px lightgrey;
`;

export const VersionMenuSubTitle = styled.div`
  padding: 10px;
  border-bottom: solid 1px lightgrey;
  text-transform: uppercase;
  font-weight: 900;
  color: darkgrey;
`;

export const VersionDisplay = styled.div`
  width: 100px;
  padding: 5px;
  margin: 5px;
  margin-bottom: 0;
  border: solid 2px blue;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? "blue" : "white")};
  color: ${({ selected }) => (selected ? "white" : "blue")};
`;

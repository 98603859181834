import styled from "styled-components";
import { Collapse, message } from "antd";
import { UpToMobileWidth } from "../../../../constants/mediaQueries";
import COLORS from "../../../../constants/colors";
import { TUTORIAL_STATUS } from "../helpers";

export const TutorialsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
  @media only screen and ${UpToMobileWidth} {
  }
`;

// Tutorial Collapse
export const TutorialCollapse = styled(Collapse).attrs({
  accordion: true,
})`
  &&& {
    background-color: transparent;
    border-radius: 10px;
    & > div > div:not(:first-child) > div {
      padding-right: 0px;
      padding-top: 0px;
    }

    & > div > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }
`;

// REMOVE !IMPORTANT
export const TutorialCollapsePanel = styled(TutorialCollapse.Panel).attrs({
  className: "TutorialCollapsePanel",
  showArrow: false,
})`
  &&& {
    background-color: white;
    font-family: "Source Sans Pro", sans-serif !important;
    border: solid;
    border-width: ${({ status }) => (status === TUTORIAL_STATUS.COMPLETED ? "4px" : "2px")};
    border-color: ${({ status }) => (status === TUTORIAL_STATUS.COMPLETED ? COLORS.DARKGREEN : COLORS.GRAY)};
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    &:not(:first-child) {
      margin-top: 25px;
      @media only screen and ${UpToMobileWidth} {
        margin-top: 15px;
      }
    }

    & > .ant-collapse-header {
      padding: 25px 25px 20px 30px;
      border: 0;
      border-radius: 10px;
      /* considtional when collapsed */
      background-color: white;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    & > .ant-collapse-content {
      margin: 0px;
      padding: 0px;
      border-top: 2px solid ${COLORS.LIGHTERGRAY};
      background-color: transparent;
    }
  }
`;

export const EmptyListMessageContainer = styled.div`
  display: block;
  color: ${COLORS.LIGHTERGRAY};
  padding: 15px 10px 15px 30px;
  border-top: 1px solid ${COLORS.LIGHTERGRAY};
  font-size: 1.2em;
  font-weight: 500;
`;

export const ToastMessage = message;

import { hot } from "react-hot-loader/root";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { ApolloProvider } from "@apollo/react-hooks";
import App from "./components/container/App";
import client from "./services/graphql/apolloClient";
import { setSessionUUID } from "./utils/sessionUtils";
import { toastConfig as defaultToastConfig, notifyOfflineToastConfig } from "./utils/toastConfig";
import { useNavigatorOnline } from "./utils/hooks";

import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.less";

const wrapper = document.getElementById("root");
setSessionUUID();

toast.configure(defaultToastConfig);

const notifyOffline = () => toast.info("You appear to have lost connection. Please check your network.", notifyOfflineToastConfig);
const closeOfflineToast = (toastId) => toast.dismiss(toastId);

// the app container provides toast and offline detecion capability to the app
const ToastContainer = ({ children }) => {
  const { isOffline } = useNavigatorOnline();
  const offlineToastId = useRef(null);
  useEffect(() => {
    if (isOffline) {
      offlineToastId.current = notifyOffline();
    } else {
      closeOfflineToast(offlineToastId.current);
    }
  }, [isOffline]);

  return <div>{children}</div>;
};

const AppWithApolloAndToasts = () => (
  <ApolloProvider client={client}>
    <ToastContainer>
      <App />
    </ToastContainer>
  </ApolloProvider>
);

const HotApp = hot(AppWithApolloAndToasts);

if (!!wrapper) {
  ReactDOM.render(<HotApp />, wrapper);
}

import styled from "styled-components";
import COLORS from "../../../constants/colors";

export const Container = styled.div`
  width: 100%;
  /* min-height: 100px; */
  .ck.ck-content {
    word-break: break-word;
    background-color: ${({ isLimitOver }) => (isLimitOver ? COLORS.RED_FADED : "inherit")};
  }
`;

export const MaxLengthReachedMessageContainer = styled.div`
  padding: 0.5rem 1rem;
  color: ${COLORS.RED};
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
`;

import React, { useState, useReducer, createContext, useEffect, useRef } from "react";
import { Layout } from "antd";
import Header from "../container/Header";
import LearningUnitBuilder from "../container/LearningUnitBuilder";
import ContentContainer from "../presentational/ContentContainer";
import Sidebar from "../container/Sidebar";
import { useWindowSize } from "../../utils/hooks";
import { PageLayout, PageContent } from "./styled";
import VersionMenu from "./VersionMenu";
import { AUTOSAVE_STATUS } from "../../constants/types";
import LearningUnitSaveStatusContext from "../container/LearningUnitBuilder/LearningUnitSaveStatusContext";

export const PageContentRefContext = createContext();

const BuildLearningUnit = ({ computedMatch: match }) => {
  const { subjectId, tutorialId, learningUnitId, version } = match.params;

  const { height: windowInnerHeight } = useWindowSize();
  const vh = Math.max(document.documentElement.clientHeight, windowInnerHeight || 0);

  const [lastModifiedBy, setLastModifiedBy] = useState({ name: "", timestamp: "" });

  const [collapsedMenu, setCollapsedMenu] = useState(true);
  const [tocRefetchFn, setTocRefetchFn] = useState({ refetch: () => {} });
  const [vmRefetchFn, setVMRefetchFn] = useState({ refetch: () => {} });
  const toggleCollapsed = () => setCollapsedMenu(!collapsedMenu);
  const [collapsedVersion, setCollapsedVersion] = useState(true);
  const toggleVersion = () => setCollapsedVersion(!collapsedVersion);
  const [showComments, setShowComments] = useState(false);
  const toggleShowComments = (setValue) => (setValue === undefined ? setShowComments(!showComments) : setShowComments(setValue));
  const [selectedVersion, setSelectedVersion] = useState(version);

  const initialRenderVersion = useRef(true);
  const initialRenderComments = useRef(true);
  const PageContentRef = useRef(null);

  useEffect(() => {
    if (initialRenderComments.current) {
      initialRenderComments.current = false;
    } else {
      if (showComments) setCollapsedVersion(true);
    }
  }, [showComments]);

  useEffect(() => {
    if (initialRenderVersion.current) {
      initialRenderVersion.current = false;
    } else {
      if (!collapsedVersion) setShowComments(false);
    }
  }, [collapsedVersion]);

  useEffect(() => {
    setSelectedVersion(version);
  }, [learningUnitId, version]);

  const learningUnitSaveStatusReducer = (state, action) => {
    switch (action) {
      case AUTOSAVE_STATUS.ANOTHER_USER_IS_EDITING:
        return AUTOSAVE_STATUS.ANOTHER_USER_IS_EDITING;
      case AUTOSAVE_STATUS.SUCCESS:
        return AUTOSAVE_STATUS.SUCCESS;
      default:
        return state;
    }
  };
  const [saveStatus, dispatchSaveStatus] = useReducer(learningUnitSaveStatusReducer, "");

  return (
    <PageContentRefContext.Provider value={PageContentRef}>
      <PageLayout>
        <LearningUnitSaveStatusContext.Provider value={{ saveStatus, dispatchSaveStatus }}>
          <Sidebar
            collapsedMenu={collapsedMenu}
            subjectId={subjectId}
            learningUnitId={learningUnitId}
            tutorialId={tutorialId}
            viewHeight={vh}
            setRefetch={setTocRefetchFn}
          />
          <PageContent ref={PageContentRef} collapsedMenu={collapsedMenu} viewHeight={vh}>
            <Layout>
              <Header
                collapsedMenu={collapsedMenu}
                toggleCollapsed={toggleCollapsed}
                subjectId={subjectId}
                showVersionIcon={tutorialId !== undefined}
                fixed={true}
                toggleVersion={toggleVersion}
                toggleShowComments={toggleShowComments}
              />
              <ContentContainer>
                <LearningUnitBuilder
                  collapsedMenu={collapsedMenu}
                  learningUnitId={learningUnitId}
                  tutorialId={tutorialId}
                  subjectId={subjectId}
                  refetchToC={tocRefetchFn}
                  refetchVersionMenu={vmRefetchFn}
                  version={selectedVersion}
                  setSelectedVersion={setSelectedVersion}
                  setLastModifiedBy={setLastModifiedBy}
                  showComments={showComments}
                  toggleShowComments={toggleShowComments}
                />
                <VersionMenu
                  collapsed={collapsedVersion}
                  subjectId={subjectId}
                  tutorialId={tutorialId}
                  learningUnitId={learningUnitId}
                  selectedVersion={selectedVersion}
                  setSelectedVersion={setSelectedVersion}
                  lastModifiedBy={lastModifiedBy}
                  setVMRefetchFn={setVMRefetchFn}
                />
              </ContentContainer>
            </Layout>
            <div className="content-end"></div>
          </PageContent>
        </LearningUnitSaveStatusContext.Provider>
      </PageLayout>
    </PageContentRefContext.Provider>
  );
};

export default BuildLearningUnit;

import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { VersionMenuContainer, VersionMenuTitle, VersionMenuSubTitle } from "./styled";
import VersionDisplay from "../presentational/VersionDisplay";
import NoVersionDisplay from "../presentational/VersionDisplay/NoVersionDisplay";
import DraftVersionDisplay from "../presentational/VersionDisplay/DarftVersionDisplay";
import { GET_LEARNING_UNIT_VERSIONS_MANIFEST } from "./queries";

const VersionMenu = ({
  collapsed,
  subjectId,
  tutorialId,
  learningUnitId,
  selectedVersion,
  setSelectedVersion,
  lastModifiedBy,
  setVMRefetchFn,
}) => {
  const { data, error, loading, refetch } = useQuery(GET_LEARNING_UNIT_VERSIONS_MANIFEST, {
    variables: {
      tutorialId,
      learningUnitId,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setVMRefetchFn({ refetch });
  }, []);

  useEffect(() => {
    refetch();
  }, [selectedVersion, refetch]);

  // const [selectedVersion, setSelectedVersion] = useState(version);

  if (error) return <div> ERROR </div>;
  if (loading) return <div> </div>;

  const { getLearningUnitVersionsManifest } = data;
  const { learningUnit, versions } = getLearningUnitVersionsManifest;

  if (selectedVersion === undefined) setSelectedVersion(get(!learningUnit.isPublished || versions, "0._id", ""));

  let subHead = "";

  return (
    <VersionMenuContainer collapsed={collapsed}>
      <VersionMenuTitle>
        <h2>Version Management</h2>
      </VersionMenuTitle>
      {!learningUnit.isPublished ? (
        <>
          <VersionMenuSubTitle>Unpublished Versions</VersionMenuSubTitle>
          <DraftVersionDisplay
            lastModifiedBy={lastModifiedBy}
            selected={selectedVersion === ""}
            setSelectedVersion={setSelectedVersion}
            subjectId={subjectId}
            tutorialId={tutorialId}
            learningUnitId={learningUnitId}
          />
        </>
      ) : (
        ""
      )}
      <VersionMenuSubTitle>Published Versions</VersionMenuSubTitle>
      {versions.length > 0 ? (
        versions
          .filter((v) => v.isPublished)
          .map((version, key) => {
            const shouldDisplaySubHead = version.month === subHead;
            subHead = "month";
            return (
              <div key={key}>
                {shouldDisplaySubHead ? <VersionMenuSubTitle>MONTH</VersionMenuSubTitle> : ""}
                <VersionDisplay
                  version={version}
                  selected={version._id === selectedVersion}
                  setSelectedVersion={setSelectedVersion}
                  subjectId={subjectId}
                  tutorialId={tutorialId}
                  learningUnit={learningUnit}
                  refetch={refetch}
                />
              </div>
            );
          })
      ) : (
        <NoVersionDisplay />
      )}
    </VersionMenuContainer>
  );
};

export default VersionMenu;

import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button as StyledButton, PageHeader, Typography, Popover as StyledPopover, Icon, Divider } from "antd";
import { HEADER_HEIGHT, TABLE_OF_CONTENT_WIDTH } from "../../../constants/miscellaneous";
import COLORS from "../../../constants/colors";
import { FullWidth } from "../../../utils/globalStyled";

export const ShadowHeader = styled(PageHeader)`
  &&& {
    ${FullWidth};
    position: ${({ fixed }) => (fixed ? "fixed " : "static")};
    /* top: ${({ fixed }) => (fixed ? "20px" : "")}; */
    ${({ fixed, collapsedMenu }) => `width: calc(100vw - ${!fixed || !collapsedMenu ? "0" : TABLE_OF_CONTENT_WIDTH}px)`};
    background: ${COLORS.WHITE};
    height: ${HEADER_HEIGHT}px;
    padding: 0;
    z-index: 1000;
    display: flex;
    .ant-page-header-heading {
      display: flex;
      align-items: center;
      padding-left: 1rem;
    }
    .ant-page-header-heading-title {
      flex-grow: 1;
      white-space: nowrap;
    }
    .ant-page-header-heading-sub-title {
      float: right;
      height: 100%;
      display: flex;
    }
    .ant-page-header-back {
      margin-right: 0;
    }
    .ant-btn {
      padding-right: 5px;
    }
    .ant-avatar {
      margin-right: 0;
    }
  }
`;

export const Hamburger = styled(Icon).attrs({
  type: "menu",
})`
  font-size: 20px;
`;
export const HomeIcon = styled(Icon).attrs({
  type: "home",
})``;
export const VersionIcon = styled(Icon).attrs({
  type: "down-circle",
})``;
export const AppIcon = styled(Icon).attrs({
  type: "appstore",
  theme: "filled",
})``;

export const IconContainer = styled.div`
  padding: 5px;
  i {
    svg {
      color: rgba(0, 0, 0, 0.65);
      &:hover {
        color: ${COLORS.BLUE};
      }
    }
  }
`;

export const Button = styled(StyledButton)`
  width: auto;
  &&& {
    border: none;
    height: 30px;
  }
`;

export const TrailingItem = styled.div`
  text-align: right;
  font-weight: bold;
  padding: 5px 0;
  display: flex;
  align-items: center;
`;

export const Popover = styled(StyledPopover).attrs({
  trigger: "click",
  placement: "bottomRight",
})``;

export const Name = styled(Typography.Text).attrs({})``;

export const Logo = styled.img.attrs({
  alt: "logo",
})`
  max-width: 225px;
  max-height: 70px;
  padding: 5px 10px;
`;

export const PopIcon = styled(Icon)`
  margin-right: 0.5em;
`;

export const PopDivider = styled(Divider)`
  margin: 0.5rem 0;
`;

export const LEARNING_UNIT_COMPONENT_TYPES = {
  SNIPPET: "Snippet",
  MEDIA_CAROUSEL: "Media Carousel",
  CASE_STUDY_SOLUTION_SNIPPET: "Case Study Solution",
  ASSESSMENT_OPPORTUNITY: "Assessment Opportunity",
  FILE_UPLOAD: "File Attachment",
};

export const QUESTION_TYPES = {
  MULTIPLE_CHOICE: "Multiple choice",
  FREE_TEXT: "Free Text",
  SURVEY: "Survey",
  BINARY_RESPONSE: "Binary Response",
};

export const ANSWER_ORDER = {
  ORDERED: "Ordered",
  RANDOMISED: "Randomised",
};

export const AUTOSAVE_STATUS = {
  SUCCESS: "SUCCESS",
  OUTDATED: "OUTDATED",
  ANOTHER_USER_IS_EDITING: "ANOTHER_USER_IS_EDITING",
  ARCHIVED: "ARCHIVED",
};

export const ACCESS_LEVEL = {
  READ_ONLY: "READ_ONLY",
  WRITE: "WRITE",
};

export const LEARNING_UNIT_TYPES = {
  CASE_STUDY: "Case Study",
  LOG_BOOK: "Log Book",
  EXAM: "Exam",
  SIMULATOR_CASE_STUDY: "Simulator Case Study",
};

export const ASSESSMENT_STYLE = {
  NOT_SCORED: "Not Scored",
  SCORED: "Scored",
};

export const ASSESSMENT_STYLE_LABEL = {
  NOT_SCORED: "Not Scored",
  SCORED: "Scored",
};

import React from "react";
import styled from "styled-components";
import {DownloadIcon} from "../../container/LearningUnitComponentBuilder/FileUploadBuilder/icons";

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
}

const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
};
  
const ButtonWrapper = styled.a`
outline: none;
border: none;
background: none;
display: flex;
flex-direction: row;
&:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.5s ease-in-out;
}
`

export const FileUploadWrapper = styled.div`
margin-top: 1rem;
margin-bottom: 1rem;
max-width: 800px;
width: calc(100% - 100px);
background: yellow;
`


export const FileUploadContentContainer = styled.div`
border: 2px solid #91A3C4;
border-left: 5px solid #4D5C76;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
padding-left: 1rem;
padding-right: 1rem;
display: flex;
align-items: center;
justify-content: space-between;
background: ${props => props.odd ? "#F0F2F5" : "#FDFDFD"};
`

export const FileNameSection = styled.div`
width: 50%;
text-overflow: ellipsis;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
overflow: hidden;
flex-wrap: nowrap;
white-space: nowrap;
`

export const FileSizeSection = styled.div`
width: 30%;
text-overflow: ellipsis;
white-space: no-wrap;
overflow: hidden;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
`


export const FileBoldText = styled.span`
font-size: 1rem;
font-weight: 600;
color: #4D5C76;
text-overflow: ellipsis;
white-space: no-wrap;
overflow: hidden;
align-items: center;
`

export const FileRegularText = styled.span`
font-size: 1rem;
color: #4D5C76;
text-overflow: ellipsis;
white-space: no-wrap;
align-items: center;
overflow: hidden;
`

export const FileDownloadText = styled.div`
@media max-width ${device.mobileM} {
    display: none;
}
`

export const FileDownloadSection = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 17%;
`

export const DownloadButton = ({href}) => 
<ButtonWrapper href={href} download target="_blank">
                    <FileDownloadText>
                    <FileBoldText className="downloadText">Download</FileBoldText>
                </FileDownloadText>
                <DownloadIcon/>


</ButtonWrapper>


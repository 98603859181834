import styled from "styled-components";
import COLORS from "../../../constants/colors";

export const MediaContainer = styled.div`
  background-color: ${COLORS.WHITE};
  height: ${({ height }) => height}px;
  max-height: ${({ height }) => height}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
`;

export const Video = styled.video`
  outline: none;
  background-color: ${COLORS.BLACK};
`;

export const Image = styled.img`
  outline: none;
  background-color: ${COLORS.BLACK};
`;
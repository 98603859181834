import React, { memo } from "react";
import PropTypes from "prop-types";
import { debounce, isEmpty } from "lodash";
import { updateScaleOptionToAllQuestions, removeScaleOptionToAllQuestions } from "../actions";
import { ListContainer, AnswerEditorContainer, Remove } from "../styled";
import { DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS, EDITOR_LENGTH } from "../../../../../constants/miscellaneous";
import Editor from "../../../Editor";

export const efficientUpdateScaleOption = debounce((dispatch, lucId, answerOptionIndex, answerOptionFields) => {
  dispatch(updateScaleOptionToAllQuestions(lucId, answerOptionIndex, answerOptionFields, true));
}, DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS);

/**
 * @param  {object} answerOption
 * @param  {number} answerOptionIndex
 * @param  {string} assessmentOpportunityId
 * @param  {callback} dispatch
 */
const ScaleOption = ({ answerOption, answerOptionIndex, assessmentOpportunityId, dispatch }) => {
  if (isEmpty(answerOption)) {
    // return <ListContainer><Skeleton avatar={{shape: "circle"}} paragraph={false} /></ListContainer>;
    return <ListContainer>Error creating scale options!</ListContainer>;
  }

  return (
    <ListContainer>
      <AnswerEditorContainer>
        <Editor
          maxLength={EDITOR_LENGTH.SURVEY_OPTION}
          inline
          basic
          content={answerOption.value}
          onChange={({ content }) => {
            efficientUpdateScaleOption(dispatch, assessmentOpportunityId, answerOptionIndex, {
              value: content,
            });
          }}
        />
      </AnswerEditorContainer>
      <Remove
        className="RemoveScale"
        onClick={() => {
          dispatch(removeScaleOptionToAllQuestions(assessmentOpportunityId, answerOptionIndex));
        }}
      />
    </ListContainer>
  );
};

ScaleOption.propTypes = {
  answerOption: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
  }).isRequired,
  answerOptionIndex: PropTypes.number.isRequired,
  assessmentOpportunityId: PropTypes.string.isRequired,
};

export default memo(ScaleOption);

import styled from "styled-components";
import { Button, Radio, Icon, Select, Input as StyledInput } from "antd";
import COLORS from "../../../../constants/colors";
import { PLACEHOLDER_QUESTION_COMPONENT_HEIGHT, PLACEHOLDER_ANSWER_COMPONENT_HEIGHT } from "../../../../constants/miscellaneous";
import { Strong } from "../../../../utils/globalStyled";

// To match the selector border radius
const borderRadius = "4px";

const dashedBlockButton = styled(Button).attrs({
  type: "dashed",
  block: true,
})``;

// Reusable editor container
export const EditorContainer = styled.div`
  flex: 1;
  border: 1px solid ${COLORS.LIGHTGRAY};
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadius};
`;

export const BuilderContainer = styled.div`
  display: flex;
`;

export const QuestionBuilderContainer = styled(EditorContainer)`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: ${COLORS.CREAM};
  padding: 1rem;
  margin-bottom: 20px;
`;

export const Section = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

export const QuestionEditorContainer = styled(EditorContainer)`
  .ck.ck-content.ck-editor__editable {
    min-height: ${PLACEHOLDER_QUESTION_COMPONENT_HEIGHT}px;
  }
`;

export const AnswerBuilderListContainer = styled(EditorContainer)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const ScaleBuilderListContainer = styled(AnswerBuilderListContainer)``;

// Makes radio button show green instead of primary blue
export const AnswerRadioGroup = styled(Radio.Group)`
  margin-bottom: 1rem;
  /* Replaces the button look and replace with round buttons */
  .ant-radio-button-wrapper {
    padding: 0;
    :first-child {
      border: 1px solid ${COLORS.LIGHTGREY};
      border-radius: 1.5rem;
    }
    :hover {
      color: ${COLORS.DARKGREEN};
      border-color: ${COLORS.GREEN};
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background-color: ${COLORS.GREEN_FADED};
    border-color: ${COLORS.GREEN};
    color: ${COLORS.DARKGREEN};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    background-color: ${COLORS.DARKGREEN};
    border-color: ${COLORS.DARKGREEN};
    color: ${COLORS.WHITE};
  }
`;

export const AddAnswerOptionButton = styled(dashedBlockButton).attrs({
  className: "AddAnswerOptionButton",
  icon: "plus",
})`
  /* To match the answer option height */
  height: ${PLACEHOLDER_ANSWER_COMPONENT_HEIGHT}px;
`;

export const AddScaleOptionButton = styled(dashedBlockButton).attrs({
  className: "AddScaleOptionButton",
  icon: "plus",
})`
  /* To match the scale option height */
  height: ${PLACEHOLDER_ANSWER_COMPONENT_HEIGHT}px;
`;

export const Label = styled(Strong)`
  margin-bottom: 0.4rem;
`;

export const AddQuestionButton = styled(dashedBlockButton).attrs({
  className: "AddQuestionButton",
  icon: "plus-circle",
})`
  height: 3rem;
  ${({ spread }) => spread === "1" && "border-color: transparent;"}
  ${({ spread }) => spread === "1" && "box-shadow: none;"}
`;

export const AssessmentTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

// ANSWER OPTIONS

export const ListContainer = styled.div`
  min-height: ${PLACEHOLDER_ANSWER_COMPONENT_HEIGHT}px;
  display: flex;
  align-items: center;
  padding: ${borderRadius} 0;
`;

export const RadioText = styled.span.attrs({
  className: "RadioText",
})``;

export const AnswerRadioButton = styled(Radio.Button).attrs({ className: "AnswerRadioButton" })`
  width: 2rem;
  height: 2rem;
  transition: width 200ms ease;
  margin-right: 0.5rem;
  color: ${COLORS.LIGHTGREY};

  span:not([class]) {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    i {
      margin: 0 0.5rem;
    }
    .RadioText {
      display: none;
      overflow: hidden;
      margin-left: 1rem;
    }
  }

  :hover {
    width: 8rem;
    i {
      display: none;
    }
    span .RadioText {
      display: inline;
    }
  }
`;

export const CheckIcon = styled(Icon).attrs({
  type: "check",
})``;

export const AnswerEditorContainer = styled(EditorContainer)`
  .ck.ck-content.ck-editor__editable {
    min-height: ${PLACEHOLDER_ANSWER_COMPONENT_HEIGHT}px;
  }
`;

export const Remove = styled(Icon).attrs({ type: "delete" })`
  padding: 3px;
  margin-left: 5px;
  height: 25px;
`;

export const QuestionSelect = styled(Select).attrs({ className: "QuestionSelect" })``;

export const AnswerSelect = styled(Select).attrs({
  className: "AnswerSelect",
  size: "small",
})`
  width: 120px;
`;

export const Input = styled(StyledInput)`
  margin-bottom: 16px
`;

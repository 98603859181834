import styled from "styled-components";
import { Icon, Input } from "antd";
import COLORS from "../../../../constants/colors";

const DISABLED_USER_SELECT = `
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 0.5px solid ${COLORS.LIGHTGRAY};
  max-width: 750px;
  width: calc(100% - 100px);
  padding: 20px;
`;

export const BuilderContainer = styled.div`
  background-color: ${COLORS.VERYLIGHTERGRAY};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
`;

export const FileInput = styled.input.attrs({ type: "file" })`
  position: absolute;
  left: -100px;
  width: calc(100% + 100px);
  opacity: 0;
  cursor: pointer;
`;

export const FileInputLabel = styled.label`
  border: 1px dashed ${COLORS.BLUE};
  color: ${COLORS.BLUE};
  padding: 20px 15px;
  cursor: pointer;
  position: relative;
  width: 100%;
  min-width: 141px;
  display: flex;
  justify-content: center;
`;

export const FileInputIcon = styled(Icon).attrs({ type: "cloud-download" })`
  margin-right: 5px;
  svg {
    height: 25px;
    width: 25px;
    color: ${COLORS.BLUE};
  }
`;

export const FileUploadContainer = styled.div`
  width: calc(100% - 200px);
`;

export const FileUploadErrorMessage = styled.div`
  color: ${COLORS.RED};
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
`;

export const IconAndTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.BLUE};
`;

export const UploadIcon = styled(Icon).attrs({ type: "loading" })`
  margin-right: 5px;
  svg {
    height: 25px;
    width: 25px;
    color: ${COLORS.BLUE};
  }
`;

export const StyledInputText = styled(Input).attrs({ type: "text" })`
  width: 100%;
`;

export const MediaEditorContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  & > div.droppablePalette {
    width: 100%;
  }
`;

export const DraggableItemContainer = styled.div`
  div.draggableItemChildren {
    width: 100%;
  }
`;

export const MediaEditorFormContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const MediaEditorForm = styled.div`
  // border: 1px solid ${COLORS.BLUE};
  // color: ${COLORS.BLUE};
  margin-bottom: 2px;
  display: flex;
  width: calc(100% - 24px);
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const VideoSettingsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const VideoSettingInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
`;

export const VideoSettingInput = styled(Input).attrs({ type: "checkbox" })`
  width: 15px;
  margin-right: 5px;
  display: block;
`;

export const VideoSettingLabel = styled.label`
  width: 100%;
  display: block;
  color: ${COLORS.DARKGRAY};
`;

export const ThumbnailContainer = styled.div`
  position: relative;
  height: 60px;
  width: 100px;
  max-height: 50px;
  max-width: 100px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.BLACK};
  ${DISABLED_USER_SELECT}
`;

export const Thumbnail = styled.img`
  height: 50px;
  width: 100px;
  max-height: 50px;
  max-width: 100px;
`;

export const PlayOverlay = styled(Icon).attrs({ type: "play-square", theme: "filled" })`
  position: absolute;
  svg {
    height: 30px;
    width: 40px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const RemoveIcon = styled(Icon).attrs({ type: "delete" })`
  padding: 3px;
  svg {
    margin-top: 5px;
  }
`;

import React, { memo } from "react";
import SnippetPreview from "./SnippetPreview";
import MediaCarouselPreview from "./MediaCarouselPreview";
import AssessmentOpportunityPreview from "./AssessmentOpportunityPreview";
import CaseStudySolutionSnippetPreview from "./CaseStudySolutionSnippetPreview";
import FileUploadPreview from "./FileUploadPreview";
import { LEARNING_UNIT_COMPONENT_TYPES } from "../../../constants/types";

const LearningUnitComponentPreview = ({ luc }) => {
  const pageURL = window.location.pathname;

  switch (luc.type) {
    case LEARNING_UNIT_COMPONENT_TYPES.SNIPPET:
      return (
        <SnippetPreview
          html={
            !luc.html
              ? luc.html
              : luc.html
                  .replace(new RegExp(/(<suggestion[^>]*suggestion-type="deletion" type="start"><\/suggestion>[^<]*)/, "ig"), "")
                  .replace(new RegExp(/<\/?(comment|suggestion)[^>]*>/, "ig"), "")
          }
          styleClass={`snippet ${luc.styleClass || ""}`}
          pageURL={pageURL}
        />
      );
    case LEARNING_UNIT_COMPONENT_TYPES.CASE_STUDY_SOLUTION_SNIPPET:
      return <CaseStudySolutionSnippetPreview html={luc.html} styleClass={`snippet ${luc.styleClass || ""}`} pageURL={pageURL} />;
    case LEARNING_UNIT_COMPONENT_TYPES.MEDIA_CAROUSEL:
      return <MediaCarouselPreview fileList={luc.fileList} />;
    case LEARNING_UNIT_COMPONENT_TYPES.ASSESSMENT_OPPORTUNITY:
      return <AssessmentOpportunityPreview luc={luc} />;
    case LEARNING_UNIT_COMPONENT_TYPES.FILE_UPLOAD:
      return <FileUploadPreview fileList={luc.fileList} fileName={luc.fileName} />;
    default:
      return null;
  }
};

export default memo(LearningUnitComponentPreview);

import styled from "styled-components";
import { Typography, Icon, Input } from "antd";
import { UpToMobileWidth } from "../../../../constants/mediaQueries";
import COLORS from "../../../../constants/colors";
import { LEARNING_UNIT_STATUS, TUTORIAL_STATUS } from "../helpers";

const { Text } = Typography;

export const TutorialNameEditable = styled(Text)`
  font-size: 24px;
`;

export const TutorialNameInput = styled(Input)`
  &&& {
    .ant-input {
      padding: 10px 0px;
      font-size: 24px;
    }
  }
`;

export const TutorialLabel = styled.div`
  &&& {
    color: ${COLORS.GRAY};
    font-weight: 600;
    font-size: 0.95em;
  }
`;

export const EditIcon = styled(Icon).attrs({ type: "edit" })`
  &&& {
    margin-top: 10px;
    margin-left: 5px;
    font-size: 24px;
    color: blue;
  }
`;

export const DoneIcon = styled(Icon).attrs({ type: "check-circle" })`
  &&& {
    font-size: 24px;
    color: blue;
  }
`;

// Tutorial Card Header
export const TutorialHeadCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TutorialHeadCardContainerTop = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const TutorialHeadCardContainerMiddle = styled.div`
  &&& {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 35px;
  }
`;

export const TutorialHeadCardContainerBottom = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const TutorialStatusPill = styled.span`
  color: ${COLORS.DARKGREY};
  font-weight: bolder;
  font-size: 0.9em;
  letter-spacing: 0.8px;
  background-color: transparent;
  // border: 2px solid ${COLORS.DARKGREY};
  padding: 7px 25px;
  border-radius: 20px;
  white-space: nowrap;
`;

export const LearningUnitStatusPill = styled(TutorialStatusPill)`
  font-size: 0.7em;
  padding: 0;
  border: 0;
  margin-right: 15px;
`;

export const CharCount = styled.span`
  position: absolute;
  right: 25px;
  top: 31%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #999;
`;

import styled from "styled-components";
import { Button } from "antd";

export const StyledButton = styled(Button).attrs({ type: "primary" })``

export const StyledButtonContainer = styled.div`
  display: inline-block;
  height: ${({isVisible}) => !isVisible ? "0" : "32"}px;
  overflow: hidden;
  padding-right: 10px;
`;
import styled from "styled-components";
import COLORS from "../../../../constants/colors";
import { PLACEHOLDER_LU_COMPONENT_HEIGHT } from "../../../../constants/miscellaneous";
import { Content } from "../styled";

export const EditorContainer = styled(Content)`
  padding: 0;
  .ck.ck-content.ck-editor__editable {
    min-height: ${PLACEHOLDER_LU_COMPONENT_HEIGHT}px;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  /* To ensure style of h2, h3, h4 and p match with preview*/
  .ck.ck-content.ck-editor__editable h2,
  .ck.ck-content.ck-editor__editable h3,
  .ck.ck-content.ck-editor__editable h4,
  .ck.ck-content.ck-editor__editable p
   {
    font-family: 'Open Sans', sans-serif;
    line-height: normal;
    margin-top: 0em;
    margin-bottom: 0.4em !important;
  }

  .ck.ck-content.ck-editor__editable h2 {
    font-size: 1.75rem;
    font-weight: 700;
  }
  .ck.ck-content.ck-editor__editable h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .ck.ck-content.ck-editor__editable h4 {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .ck.ck-content.ck-editor__editable p {
    font-size: 1.05rem;
    margin: 0 0 1rem 0;
    line-height: 2;
  }

  .ck-widget.image-style-align-left {
    margin-right: 1.5rem;
  }

  .ck-widget.image-style-align-right {
    margin-left: 1.5rem;
  }
`;

import React from "react"
import styled from "styled-components";
import { Icon, Input } from "antd";
import COLORS from "../../../../constants/colors";

const DISABLED_USER_SELECT = `
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
`;
export const ThumbnailContainer = styled.div`
position: relative;
height: 60px;
width: 100px;
max-height: 50px;
max-width: 100px;
margin-right: 5px;
display: flex;
justify-content: center;
align-items: center;
background: none;
${DISABLED_USER_SELECT}
`;

export const StyledInputText = styled(Input).attrs({ type: "text" })`
  width: 100%;
  display: inline-block;
  align-items: center;
`;

export const FileExtensionContainer = styled.span`
position: absolute;
width: auto;
border: none;
padding: 1px;
right: 4px;
display: inline;
margin-top: 4px;
`;

export const IconContainer = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
`;

export const MediaEditorForm = styled.div`
  //border: 1px solid ${COLORS.BLUE};
  display: flex;
  width: calc(100% - 24px);
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative; 

`;
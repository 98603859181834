import { initialiseUsers, initialiseComments } from "../Editor/helpers";

export const handleInitContext = (context, { target, userId, users, comments }) => {
  if (!userId || !users) return { commentsRepository: null };

  // Initialise users
  initialiseUsers(context, { userId, users });

  // Initialise comments
  const commentsRepository = initialiseComments(context, { target, comments });

  return {
    commentsRepository,
  };
};

export const getCommentThreads = (commentThreadsData) => {
  const commentThreads = !commentThreadsData
    ? []
    : commentThreadsData.map((commentThread) => {
        const comments = commentThread.comments.map((comment) => {
          return {
            commentId: comment.id,
            authorId: comment.author.id,
            content: comment.content,
            createdAt: comment.createdAt,
          };
        });

        return {
          threadId: commentThread.id,
          comments,
        };
      });
  return commentThreads;
};

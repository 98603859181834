import { imageUploadURL } from "../../../config";
import { getIdToken } from "../../utils/authHelpers";

const env = process.env.RUN_ENV;

// The URL that the images are uploaded to.
export const IMAGE_UPLOAD_URL = imageUploadURL[env];

/**
 * @description Get the Headers sent along with the XMLHttpRequest to the upload server.
 * @param {string} learningUnitId - Learning Unit Id
 */
export const getImageUploadHeaders = (learningUnitId) => {
  return {
    "X-CSRF-TOKEN": "CSFR-Token",
    Authorization: getIdToken(),
    luid: learningUnitId,
  }
}
import React from "react";
import { Container } from "./styled";

/**
 * @description Higher order component for CKEditor images to enable styles for alignment/resizing.
 *              It also ensures image sizes are responsive to its immediate parent container to avoid overflows.
 * @param {@} children - Child components
 */
const EnhancedEditorImages = ({ children }) => <Container>{children}</Container>;

export default EnhancedEditorImages;

import React, { memo, useEffect, useMemo, useState } from "react";
import { Select, Icon, Checkbox, Row, Col, Tooltip, InputNumber } from "antd";
import { get } from "lodash";
import { Content } from "../styled";
import { Strong } from "../../../../utils/globalStyled";
import { SettingsForm, LeftPanel, RightPanel, FormRow, FormItem } from "./styled";
import StatefulInput from "../../../presentational/StatefulInput";
import { ASSESSMENT_STYLE, ASSESSMENT_STYLE_LABEL, ANSWER_ORDER, QUESTION_TYPES } from "../../../../constants/types";
import { boolToTerm, termToBool } from "./helpers";

const { Option } = Select;
const SHOW_EXPLANATIONS_WHEN = {
  NEVER: "NEVER",
  PASS_OR_FAIL: "PASS_OR_FAIL",
  RESITS_EXHAUSTED: "RESITS_EXHAUSTED",
};
/**
 * Settings form component for assessment opportunity
 *
 * @param {object} assessmentOpportunity
 * @param {function} onFieldValueChange - handles any changes in field value in the component
 */
const AssessmentOpportunitySettings = ({ assessmentOpportunity, onFieldValueChange }) => {
  const {
    title,
    assessmentStyle,
    displayStyle,
    mandatory,
    showExplanationUponSubmission,
    showExplanationAfterEachAttempt,
    showExplanationAfterResitsAreExhausted,
    maxNumberOfResitsAllowed,
    numberOfQuestionsToBeAssessed,
    passThreshold,
    questions,
    enableQuestionPooling,
    alsoShowExplanationWhen,
  } = assessmentOpportunity;
  const isSurvey = useMemo(
    () => get(questions.get(0), "type") === QUESTION_TYPES.SURVEY || get(questions.get(0), "type") === QUESTION_TYPES.BINARY_RESPONSE,
    [questions],
  );

  const showResultsUponSubmission = termToBool(assessmentOpportunity.showResultsUponSubmission);
  const resitsAllowed = termToBool(assessmentOpportunity.resitsAllowed);
  // const showResultsExhaustedResits = termToBool(showExplanationAfterResitsAreExhausted);
  const maxNumberOfQuestions = !questions ? 0 : questions.size;

  const [showExplanationsAlsoWhen, setShowExplanationsAlsoWhen] = useState(alsoShowExplanationWhen);

  /**
   * Wraps up a {key, value} pair of fieldName and form value to callback
   *
   * @param {String} key
   * @param {String|Boolean} value
   */
  const handleSingleField = (key, value) => onFieldValueChange({ [key]: value });

  /**
   * Due to Salesforce setup we need to convert certain checked value to "Yes" or "No" before firing the callback
   *
   * @param {event} e
   */
  const handleSpecialCheckbox = (e) => {
    const decision = boolToTerm(e.target.checked);
    handleSingleField(e.target.name, decision);
  };

  const setSolnCheckboxFalseFlags = (decision) =>
    onFieldValueChange({
      showExplanationUponSubmission: false,
      showResultsUponSubmission: decision,
    });

  const handleSolutionCheckbox = (e) => {
    const decision = boolToTerm(e.target.checked);
    if (!e.target.checked) {
      setSolnCheckboxFalseFlags(decision);
    } else {
      handleSingleField(e.target.name, decision);
    }
  };

  useEffect(() => {
    if (!showExplanationUponSubmission) setShowExplanationsAlsoWhen(SHOW_EXPLANATIONS_WHEN.NEVER);
  }, [showExplanationUponSubmission]);

  useEffect(() => {
    onFieldValueChange({
      alsoShowExplanationWhen: showExplanationsAlsoWhen,
    });
  }, [showExplanationsAlsoWhen]);

  const handleExplanationSelect = (option) => {
    setShowExplanationsAlsoWhen(option);
    return onFieldValueChange({
      alsoShowExplanationWhen: option,
    });
  };

  const handleAssessmentStyleChange = (value) => {
    switch (value) {
      case ASSESSMENT_STYLE.NOT_SCORED:
        onFieldValueChange({
          mandatory: false,
          showResultsUponSubmission: boolToTerm(true),
          showExplanationUponSubmission: true,
          showExplanationAfterEachAttempt: false,
          resitsAllowed: boolToTerm(false),
          enableQuestionPooling: false,
          numberOfQuestionsToBeAssessed: null,
          assessmentStyle: value,
        });
        break;
      case ASSESSMENT_STYLE.SCORED:
        onFieldValueChange({
          mandatory: true,
          showResultsUponSubmission: boolToTerm(true),
          showExplanationUponSubmission: false,
          showExplanationAfterEachAttempt: false,
          resitsAllowed: boolToTerm(true),
          enableQuestionPooling: false,
          numberOfQuestionsToBeAssessed: null,
          assessmentStyle: value,
        });
        break;
      default:
        break;
    }
  };

  return (
    <Content>
      <SettingsForm>
        <FormRow>
          <LeftPanel>
            <FormItem label="Title">
              <StatefulInput maxLength={80} value={title} placeholder="Quiz" onChange={(e) => handleSingleField("title", e.target.value)} />
            </FormItem>
            <FormItem
              label={
                <span>
                  Assessment Style&nbsp;
                  <Tooltip title="Non-Assessable (Not Scored) or Assessable (Scored)">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              <Select value={assessmentStyle} disabled={isSurvey} onChange={handleAssessmentStyleChange}>
                <Option value={ASSESSMENT_STYLE.NOT_SCORED}>{ASSESSMENT_STYLE_LABEL.NOT_SCORED}</Option>
                <Option value={ASSESSMENT_STYLE.SCORED}>{ASSESSMENT_STYLE_LABEL.SCORED}</Option>
              </Select>
            </FormItem>

            <FormItem
              label={
                <span>
                  Display Style&nbsp;
                  <Tooltip title="Ordered or Randomised">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              <Select value={displayStyle} disabled={isSurvey} onChange={(value) => handleSingleField("displayStyle", value)}>
                <Option value={ANSWER_ORDER.ORDERED}>{ANSWER_ORDER.ORDERED}</Option>
                <Option value={ANSWER_ORDER.RANDOMISED}>{ANSWER_ORDER.RANDOMISED}</Option>
              </Select>
            </FormItem>
          </LeftPanel>
          <RightPanel>
            <FormItem>
              <Checkbox
                name="mandatory"
                disabled={isSurvey}
                checked={!!mandatory}
                onChange={(e) => handleSingleField(e.target.name, e.target.checked)}
              >
                <Strong>Mandatory</Strong>
              </Checkbox>
            </FormItem>
            <FormItem>
              <Checkbox name="resitsAllowed" disabled={isSurvey} checked={resitsAllowed} onChange={handleSpecialCheckbox}>
                <Strong>Resits allowed</Strong>
              </Checkbox>
            </FormItem>
            <FormItem>
              <Checkbox
                name="enableQuestionPooling"
                disabled={isSurvey}
                checked={!!enableQuestionPooling}
                onChange={(e) =>
                  onFieldValueChange({
                    enableQuestionPooling: e.target.checked,
                    numberOfQuestionsToBeAssessed: e.target.checked ? maxNumberOfQuestions : null,
                  })
                }
              >
                <Strong>Enable question pooling</Strong>
              </Checkbox>
            </FormItem>
            <FormItem>
              <Checkbox
                name="showResultsUponSubmission"
                disabled={isSurvey}
                checked={showResultsUponSubmission}
                onChange={handleSolutionCheckbox}
              >
                <Strong>Show results upon submission</Strong>
              </Checkbox>
            </FormItem>
            <FormItem>
              <Checkbox
                name="showExplanationUponSubmission"
                checked={!!showExplanationUponSubmission}
                onChange={(e) => handleSingleField(e.target.name, e.target.checked)}
                disabled={!showResultsUponSubmission}
              >
                <Strong>Show explanation when pass</Strong>
              </Checkbox>
            </FormItem>
            <FormItem>
              <Strong>Also show explanations when:</Strong>
              <Select disabled={!showExplanationUponSubmission} value={showExplanationsAlsoWhen} onChange={handleExplanationSelect}>
                <Option value={SHOW_EXPLANATIONS_WHEN.NEVER}>Never</Option>
                <Option value={SHOW_EXPLANATIONS_WHEN.PASS_OR_FAIL}>A student fails</Option>
                <Option value={SHOW_EXPLANATIONS_WHEN.RESITS_EXHAUSTED}>All resits are exhausted</Option>
              </Select>
            </FormItem>
          </RightPanel>
        </FormRow>

        <Row>
          <Col span={9}>
            <FormItem
              label={
                <span>
                  Max Number Of Resits Allowed&nbsp;
                  <Tooltip title='If resits are allowed, a "0" here means "infinite"'>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
              disabled={!resitsAllowed}
            >
              <StatefulInput
                numeric
                min={0}
                max={999}
                disabled={!resitsAllowed}
                value={maxNumberOfResitsAllowed}
                onChange={(value) => handleSingleField("maxNumberOfResitsAllowed", value)}
              />
            </FormItem>
          </Col>
          <Col span={10}>
            <FormItem label="Questions To Select From Pool">
              <InputNumber
                min={1}
                max={maxNumberOfQuestions}
                value={numberOfQuestionsToBeAssessed}
                disabled={!enableQuestionPooling}
                onChange={(value) => {
                  let newValue;
                  const parsed = parseInt(value, 10);
                  if (Number.isNaN(parsed)) newValue = maxNumberOfQuestions;
                  else newValue = parsed;
                  handleSingleField("numberOfQuestionsToBeAssessed", newValue);
                }}
              />
            </FormItem>
          </Col>
          <Col span={5}>
            <FormItem label="Pass Threshold">
              <StatefulInput
                percentage
                min={0}
                max={100}
                disabled={isSurvey}
                value={passThreshold}
                onChange={(value) => handleSingleField("passThreshold", value)}
              />
            </FormItem>
          </Col>
        </Row>
      </SettingsForm>
    </Content>
  );
};

export default memo(AssessmentOpportunitySettings);

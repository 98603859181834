import gql from "graphql-tag";

export const RESOLVE_SNAPSHOT_AND_GET_MANIFEST = gql`
  mutation upsertSnapshotAndGetManifest($learningUnitId: String, $tutorialId: String, $version: String, $subjectId: String) {
    upsertSnapshotAndGetManifest(learningUnitId: $learningUnitId, tutorialId: $tutorialId, version: $version, subjectId: $subjectId) {
      _id
      name
      type
      accessLevel
      isPublished
      isSubmittedForReview
      saveStatus
      lastSaveTimeStamp
      lastSnapshotSaveTimeStamp
      lastModifiedByContact {
        name
      }
      subjectAssessments {
        _id
        displayName
      }
      learningUnitComponents {
        _id
        assessmentStyle
        displayStyle
        html
        comments
        suggestions
        fileList
        fileName
        mandatory
        numberOfQuestionsAvailable
        numberOfQuestionsDisplayed
        numberOfQuestionsToBeAssessed
        enableQuestionPooling
        position
        passThreshold
        resitsAllowed
        maxNumberOfResitsAllowed
        title
        showResultsUponSubmission
        showExplanationUponSubmission
        showExplanationAfterEachAttempt
        showExplanationAfterResitsAreExhausted
        alsoShowExplanationWhen
        type
        styleClass
        questions {
          _id
          name
          active
          assessment
          assessmentSyncId
          correctAnswerValue
          correctAnswer
          explanation
          preQuestionText
          questionSetting
          question
          type
          order
          answerDisplayStyle
          answerOptions {
            _id
            name
            question
            questionSyncId
            correctValue
            value
            position
          }
        }
        templateHelpTextBody
        templateHelpTextHeading
      }
    }
  }
`;

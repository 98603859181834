import React from "react";
import {AudioIcon, PdfIcon, ImgIcon, TxtIcon, VideoIcon, FileIcon} from "./icons"; 

/**
 * @description Media MIME Types of Microsoft Word Documents
 */
const WORD_DOC_FORMATS = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template", 
  "application/vnd.ms-word.document.macroEnabled.12", 
  "application/vnd.ms-word.template.macroEnabled.12",
]

/**
 * @description Convert a file upload buffer to an object url
 * @param {*} buffer - The file buffer
 * @param {string} mimeType - The file mime type
 */
const createObjectUrlFromBuffer = (buffer, mimeType) => {
  // Convert the buffer to a blob
  const blob = new Blob([new Uint8Array(buffer)], { type: mimeType });
  // Create an object URL from the blob
  const objectUrl = URL.createObjectURL(blob);
  return objectUrl;
};

/**
 * 
 * @param {String} type - The mime type of the file
 * @returns An icon component with the appropriate icon
 */

export const getIcon = (type) => {
  if (type === "application/pdf") {
      return <PdfIcon className="fileIcon-pdf"/>
  }
  else if (type.startsWith("image")) {
      return <ImgIcon className="fileIcon-img"/>
  }
  else if (type.startsWith("video")) {
      return <VideoIcon className="fileIcon-video"/>
  }
  else if (type.startsWith("audio")) {
      return <AudioIcon className="fileIcon-audio"/>
  }
  else if (WORD_DOC_FORMATS.includes(type)) {
      return <TxtIcon className="fileIcon-word"/>
  }
  return <FileIcon className="fileIcon-generic"/>
}

/**
 * @description Retrieve the file upload information 
 * @param {*} file - Value of event.target.files[x]
 */

export const getUploadInfo = (file) =>
  new Promise((resolve, reject) => {
    const mimeType = file.type;
    const reader = new FileReader();
    reader.onload = (event) => {
      // Capture the buffer
      const buffer = event.target.result;

      // Convert the buffer to object url
      const objectUrl = createObjectUrlFromBuffer(buffer, mimeType);

      resolve({file})
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsArrayBuffer(file);
  });

import styled from "styled-components";
import { UpToMobileWidth } from "../../../constants/mediaQueries";

export const RichTextContainer = styled.div``;

/**
 * @description Main styling for the Snippet Container
 */
export const SnippetContainer = styled.div`
  padding: 0.5rem 0rem;
  width: 100%;
  max-width: 800px;

  img {
    max-width: 80%;
  }

  video {
    max-width: 80%;
  }

  iframe {
    max-width: 80%;
  }

  h1 {
    font-size: 3rem;
  }

  figure {
    text-align: center;
  }

  figure .title {
    font-weight: bold;
    font-size: large;
    margin-bottom: 0;
  }

  figure .caption {
    font-size: medium;
    margin-bottom: 0;
  }

  figure .source {
    font-style: italic;
    font-size: small;
  }
`;

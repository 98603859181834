import React, { memo, forwardRef, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { debounce, isEmpty } from "lodash";
import { updateAnswerOption, removeAnswerOption } from "../actions";
import { ListContainer, RadioText, AnswerRadioButton, CheckIcon, AnswerEditorContainer, Remove } from "../styled";
import { DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS, EDITOR_LENGTH } from "../../../../../constants/miscellaneous";
import Editor from "../../../Editor";

export const efficientUpdateAnswerOption = debounce(
  (dispatch, lucId, questionId, questionIndex, answerOptionId, answerOptionIndex, answerOptionFields) => {
    dispatch(updateAnswerOption(lucId, questionId, questionIndex, answerOptionId, answerOptionIndex, answerOptionFields, true));
  },
  DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS,
);

/**
 * AnswerOption Component for MultipleChoiceQuestion of AssessmentOpportunityBuilder
 * Do not set defaultChecked or checked as we handle changes in a Radio Button Group
 *
 * @param  {object} answerOption
 * @param  {number} answerOptionIndex
 * @param  {string} questionId
 * @param  {number} questionIndex
 * @param  {string} assessmentOpportunityId
 * @param  {callback} dispatch
 */
const AnswerOption = ({ answerOption, answerOptionIndex, questionId, questionIndex, assessmentOpportunityId, dispatch }) => {
  if (isEmpty(answerOption)) {
    // return <ListContainer><Skeleton avatar={{shape: "circle"}} paragraph={false} /></ListContainer>;
    return <ListContainer>Error creating answer options!</ListContainer>;
  }

  return (
    <ListContainer>
      <AnswerRadioButton value={answerOptionIndex}>
        <CheckIcon />
        <RadioText>Correct Answer</RadioText>
      </AnswerRadioButton>
      <AnswerEditorContainer>
        <Editor
          maxLength={EDITOR_LENGTH.DEFAULT}
          inline
          basic
          content={answerOption.value}
          onChange={({ content }) =>
            efficientUpdateAnswerOption(dispatch, assessmentOpportunityId, questionId, questionIndex, answerOption._id, answerOptionIndex, {
              value: content,
            })
          }
        />
      </AnswerEditorContainer>
      <Remove
        className="RemoveAnswer"
        onClick={() =>
          dispatch(removeAnswerOption(assessmentOpportunityId, questionId, questionIndex, answerOption._id, answerOptionIndex))
        }
      />
    </ListContainer>
  );
};

AnswerOption.propTypes = {
  answerOption: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
  }).isRequired,
  answerOptionIndex: PropTypes.number.isRequired,
  questionId: PropTypes.string.isRequired,
  questionIndex: PropTypes.number.isRequired,
  assessmentOpportunityId: PropTypes.string.isRequired,
};

export default memo(AnswerOption);

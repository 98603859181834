import React from "react";
import styled from "styled-components";
import downSvg from "../../../../public/pointer.down.svg";
import COLORS from "../../../constants/colors";

export const ArrowSvg = styled(downSvg)``;

export const ArrowIconContainer = styled(({ direction, ...props }) => <div {...props} />)`
  display: inline-block;
  width: 20px;
  height: 20px;
  fill: ${COLORS.DARKGRAY};
  transform: ${({ direction }) => (direction === "up" ? "rotate(-180deg)" : "rotate(0deg)")};
  transition: ${(direction) => (direction === "up" ? "transform 0.25s ease" : "transform 0.3s ease")};
`;

import React from "react";
import debounce from "lodash/debounce";
import { v4 } from "uuid";
import { List, Stack } from "immutable";
import fireNotification from "../../../presentational/Notification";
import { saveStart, updateLearningUnit, add } from "../actions";
import { NotificationButton } from "./styled";
import { getSessionUUID, getLastSaveTimestampFor } from "../../../../utils/sessionUtils";
import { LEARNING_UNIT_COMPONENT_TYPES, AUTOSAVE_STATUS, ACCESS_LEVEL, ASSESSMENT_STYLE, ANSWER_ORDER } from "../../../../constants/types";
import { parseIfJSON, omitPropertyFromObject } from "../../../../utils/helpers";

const makeListsImmutable = (learningUnitComponents) => {
  return List(
    learningUnitComponents.map((luc) => ({
      ...luc,
      questions: Array.isArray(luc.questions)
        ? List(
            luc.questions.map((question) => ({
              ...question,
              answerOptions: Array.isArray(question.answerOptions) ? List(question.answerOptions) : List(),
            })),
          )
        : List(),
      fileList: Array.isArray(parseIfJSON(luc.fileList)) ? List(parseIfJSON(luc.fileList)) : List(),
      fileName: Array.isArray(parseIfJSON(luc.fileName)) ? List(parseIfJSON(luc.fileName)) : List(),
    })),
  );
};

export const shouldBuilderBeReadOnly = ({ isPublished, isSubmittedForReview, isArchived, saveStatus, accessLevel }) =>
  isPublished ||
  isSubmittedForReview ||
  isArchived ||
  saveStatus === AUTOSAVE_STATUS.ANOTHER_USER_IS_EDITING ||
  accessLevel === ACCESS_LEVEL.READ_ONLY;

export const initState = (learningUnit) => {
  const {
    _id,
    learningUnitComponents,
    __typename,
    isPublished,
    isSubmittedForReview,
    isArchived,
    saveStatus,
    accessLevel,
    ...restLearningUnitFields
  } = learningUnit;
  const sortedlearningUnitComponents = !learningUnitComponents ? [] : learningUnitComponents.sort((a, b) => a.position - b.position);
  const immutableLearningUnitComponents = makeListsImmutable(sortedlearningUnitComponents);
  const isReadOnly = shouldBuilderBeReadOnly(learningUnit);

  return {
    learningUnitFields: restLearningUnitFields,
    learningUnitComponents: immutableLearningUnitComponents,
    isPreviewMode: false,
    isPublished,
    isSubmittedForReview,
    isArchived,
    saveStatus,
    accessLevel,
    isReadOnly,
    isShowComments: false,
    undoStack: Stack(),
    redoStack: Stack(),
    snapshotSaveStatusText: "",
  };
};

export const getNewItemId = (type) => {
  const idTypeMap = {
    [LEARNING_UNIT_COMPONENT_TYPES.SNIPPET]: "snippet",
    [LEARNING_UNIT_COMPONENT_TYPES.ASSESSMENT_OPPORTUNITY]: "assessment",
    [LEARNING_UNIT_COMPONENT_TYPES.MEDIA_CAROUSEL]: "media",
    [LEARNING_UNIT_COMPONENT_TYPES.CASE_STUDY_SOLUTION_SNIPPET]: "solution",
    [LEARNING_UNIT_COMPONENT_TYPES.FILE_UPLOAD]: "file",
  };
  return `LOCAL-${idTypeMap[type]}-${v4()}`;
};

export const getNewMediaCarousel = () => ({
  _id: getNewItemId(LEARNING_UNIT_COMPONENT_TYPES.MEDIA_CAROUSEL),
  type: LEARNING_UNIT_COMPONENT_TYPES.MEDIA_CAROUSEL,
  fileList: List(),
});

export const getNewSnippet = () => ({
  _id: getNewItemId(LEARNING_UNIT_COMPONENT_TYPES.SNIPPET),
  type: LEARNING_UNIT_COMPONENT_TYPES.SNIPPET,
  html: "",
});

export const getNewAssessment = () => ({
  _id: getNewItemId(LEARNING_UNIT_COMPONENT_TYPES.ASSESSMENT_OPPORTUNITY),
  type: LEARNING_UNIT_COMPONENT_TYPES.ASSESSMENT_OPPORTUNITY,
  questions: List(),
  assessmentStyle: ASSESSMENT_STYLE.SCORED,
  displayStyle: ANSWER_ORDER.ORDERED,
  maxNumberOfResitsAllowed: 0,
  numberOfQuestionsToBeAssessed: null,
  enableQuestionPooling: false,
  passThreshold: 100,
  mandatory: true,
  showResultsUponSubmission: "Yes",
  resitsAllowed: "Yes",
});

export const openMaintenanceNotification = (key) => {
  const message = (
    <div style={{ width: "700px" }}>
      <div>The site will be down for maintenance</div>
      <div>from Wed 7 July, 6:00pm AEDT</div>
      <div>to Wed 7 July, 11:59pm AEDT</div>
    </div>
  );
  const button = "";
  fireNotification(message, button, key, "error");
};

export const getNewFileUpload = () => ({
  _id: getNewItemId(LEARNING_UNIT_COMPONENT_TYPES.FILE_UPLOAD),
  type: LEARNING_UNIT_COMPONENT_TYPES.FILE_UPLOAD,
  fileList: List(),
  fileName: List(),
});

export const openOutdatedNotification = (handleButtonClick, key) => {
  const message = "Changes have been made to this learning unit since your last save. Please reload the page.";
  const button = <NotificationButton onClick={handleButtonClick}>Reload</NotificationButton>;
  fireNotification(message, button, key);
};

export const openSaveErrorNotification = (handleButtonClick, key) => {
  const message = "Save failed. Please check your connection and retry saving.";
  const button = <NotificationButton onClick={handleButtonClick}>Save</NotificationButton>;
  fireNotification(message, button, key, "error");
};

export const openSubmitForReviewErrorNotification = (key) => {
  const message =
    "Error occured while submitting learning unit for review. Please check your connection and retry. If this persists, contact support.";
  fireNotification(message, null, key, "error");
};

export const openPublishErrorNotification = (key) => {
  const message = "Error occured while publishing learning unit. Please refresh the page and retry. If this persists, contact support.";
  fireNotification(message, null, key, "error");
};

export const openMCQwithNoAnswerNotification = (key) => {
  const message =
    "Please ensure all Multiple Choice Questions have at least 2 answer options and one answer selected before submitting for review.";
  fireNotification(message, null, key, "error");
};

export const openEmptyAssessmentNotification = (key) => {
  const message = "Please ensure all assessments have questions before submitting for review.";
  fireNotification(message, null, key, "error");
};

export const openEmptyQuestionDescriptionNotification = (key) => {
  const message = "Please ensure all questions have a question description before submitting for review.";
  fireNotification(message, null, key, "error");
};

// Except for the FreeTextQuestion, all other answer options should not be empty
export const openEmptyAnswerptionNotification = (key) => {
  const message = "Please ensure all questions have answer options before submitting for review.";
  fireNotification(message, null, key, "error");
};

/**
 * @description Omit type __typename property
 * @param {object} obj - Any object
 */
const omitTypeName = (obj) => omitPropertyFromObject(obj, "__typename");

const omitFieldsFromLearningUnit = (lu) => {
  const luWOType = omitTypeName(lu);
  const luWOTimestamp = omitPropertyFromObject(luWOType, "lastSnapshotSaveTimeStamp");
  const luWOTimestampAndContact = omitPropertyFromObject(luWOTimestamp, "lastModifiedByContact");
  return luWOTimestampAndContact;
};
/**
 * @description Format the Learning Unit Components before saving
 * @param {object} lucList - List of Learning Unit Component
 */
export const formatForSaving = (lucList) =>
  lucList.map((luc, index) => ({
    ...omitTypeName(luc),
    fileList: JSON.stringify(luc.fileList),
    fileName: JSON.stringify(luc.fileName),
    position: index,
    questions: List.isList(luc.questions)
      ? omitTypeName(luc.questions).map((question, questionIndex) => ({
          ...question,
          order: questionIndex,
          answerOptions: List.isList(question.answerOptions)
            ? omitTypeName(question.answerOptions).map((answerOption, answerOptionIndex) => ({
                ...answerOption,
                position: answerOptionIndex,
              }))
            : [],
        }))
      : [],
  }));

export const saveSnapshot = (dispatch, lucList, learningUnit, learningUnitId, tutorialId, updateContentSnapshotMutation) => {
  // order by position and stringify filelist before saving
  const formattedLucForSave = formatForSaving(lucList);
  const luToSave = {
    ...omitFieldsFromLearningUnit(learningUnit),
    learningUnitComponents: formattedLucForSave,
    subjectAssessments: learningUnit.subjectAssessments.map((sa) => omitTypeName(sa)),
  };
  dispatch(saveStart());
  updateContentSnapshotMutation({
    variables: {
      learningUnitId,
      tutorialId,
      content: luToSave,
      sessionUUID: getSessionUUID(),
      lastUpdateRequestTimeStamp: getLastSaveTimestampFor(learningUnitId),
    },
  });
};

export const saveLearningUnitChanges = (
  dispatch,
  learningUnit,
  learningUnitId,
  tutorialId,
  updateContentSnapshotAndLearningUnitMutation,
) => {
  dispatch(saveStart());
  updateContentSnapshotAndLearningUnitMutation({
    variables: {
      learningUnit,
      learningUnitId,
      tutorialId,
      sessionUUID: getSessionUUID(),
      lastUpdateRequestTimeStamp: getLastSaveTimestampFor(learningUnitId),
    },
  });
};

export const submitSnapshotForReview = (learningUnitId, tutorialId, submitLearningUnitForReview) => {
  submitLearningUnitForReview({
    variables: {
      learningUnitId,
      tutorialId,
      sessionUUID: getSessionUUID(),
      lastUpdateRequestTimeStamp: getLastSaveTimestampFor(learningUnitId),
    },
  });
};

export const efficientLearningUnitFieldsUpdate = debounce((dispatch, learningUnitFields) =>
  dispatch(updateLearningUnit(learningUnitFields)),
);

export const setLearningUnitPublished = (learningUnitId, tutorialId, publishLearningUnit) => {
  publishLearningUnit({
    variables: {
      learningUnitId,
      tutorialId,
    },
  });
};

export const setLearningUnitEditable = (learningUnitId, tutorialId, makeLearningUnitEditable) => {
  makeLearningUnitEditable({
    variables: {
      learningUnitId,
      tutorialId,
    },
  });
};

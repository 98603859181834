import styled from "styled-components";
import { Card as StyledCard } from "antd";
import COLORS from "../../../../constants/colors";

export const ScrollContainer = styled.div`
  width: calc(100% - 100px);
  max-width: 800px;
`;

export const Card = styled(StyledCard).attrs({
  headStyle: {
    backgroundColor: COLORS.BLUE,
    color: COLORS.WHITE,
    fontSize: "1.5em",
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  bodyStyle: { padding: "0px", flexDirection: "column" }
})`
&&& {
  margin-bottom: 10px;
  border: 2px solid ${COLORS.BLUE};
  border-radius: 5px;
}
`;

export const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  border-radius: 1rem;
  border: 1px dotted ${COLORS.LIGHTGREY};
  font-style: italic;
  color: ${COLORS.GREY};
`;

export const MandatoryLabel = styled.div`
  color: ${COLORS.PINK};
  font-weight: bolder;
`;
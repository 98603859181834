import React, { memo } from "react";
import PropTypes from "prop-types";
import {FileUploadContentContainer, FileNameSection, FileSizeSection, FileRegularText, FileBoldText, FileDownloadSection, DownloadButton, FileUploadWrapper} from "./styled";
import Loading from "../Loading";
import {getIcon} from "./helper";
import isArray from "lodash/isArray";
import prettyBytes from'pretty-bytes';


const FileUploadContent = (file, idx) => (
    <FileUploadContentContainer key={idx} odd={idx % 2}>
        {getIcon(file.type)}
        <FileNameSection>
            <FileBoldText className="fileNameText" style={{marginRight: "3%"}}>
                {file.filename}
            </FileBoldText>
        </FileNameSection>
        <FileSizeSection>
            <FileRegularText className="sizeText">{prettyBytes(file.size)}</FileRegularText>
            </FileSizeSection>
       <FileDownloadSection>

            <DownloadButton href={file.url} download>
            </DownloadButton>
        </FileDownloadSection>
    </FileUploadContentContainer>
)

const FileUpload = ({ contents}) => {
    if (!contents || !isArray(contents)) {
            return <Loading />;
    }
    
    return(
        <FileUploadWrapper>
            {contents.map((file, idx) => FileUploadContent(file, idx))}
        </FileUploadWrapper>
    )
    
}

FileUpload.propTypes = {
    contents: PropTypes.array.isRequired,
};
  

export default memo(FileUpload);
export const getItemStyle = (isDragging, draggableStyle, grid = 8) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,

  // styles we need to apply on draggables
  ...draggableStyle,

  // conditional styles if dragging
  // height: isDragging ? "100px" : "auto",
  backgroundColor: isDragging ? "white" : "transparent",
  boxShadow: isDragging ? "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)" : "none",
  display: "flex",
});

import styled from "styled-components";
import { Pagination } from "antd";
import COLORS from "../../../constants/colors";

const PaginationContainer = styled.div`
  height: 100%;
`;

const Page = styled.div``;

const PaginationBarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px;
`;

const PaginationBar = styled(Pagination)`
  .ant-pagination-item-active {
    background-color: ${COLORS.LIGHTGREY};
  }
`;

export { PaginationContainer, Page, PaginationBar, PaginationBarContainer };

export const COVER_FRONT_IMAGE = "https://d2qfkbijqeoqjh.cloudfront.net/pdf-assets/front_cover.png";
export const COVER_BACK_IMAGE = "https://d2qfkbijqeoqjh.cloudfront.net/pdf-assets/back_cover.png";
export const MLU_LOGO = "https://d2qfkbijqeoqjh.cloudfront.net/pdf-assets/MLU.png";

export const ALLOWED_SNIPPET_TYPES = ["Snippet", "Media Carousel"];
export const UNALLOWED_SNIPPET_TYPES = ["Assessment Opportunity"];
export const IMAGE_MIME_TYPES = [
  "image/jpeg",       // .jpg
  "image/jpg",        // .jpg
  "image/svg+xml",    // .svg
  "image/gif",        // .gif
  "image/apng",       // .apng
  "image/webp",       // .webp
  "image/bmp",        // .bmp
  "image/png",        // .png
];
export const VIDEO_MIME_TYPES = [
  "video/x-flv",            // .flv
  "video/mp4",              // .mp4
  "application/x-mpegURL",  // .m3u8
  "video/MP2T",             // .ts
  "video/3gpp",             // .3gp
  "video/quicktime",        // .mov
  "video/x-msvideo",        // .avi
  "video/x-ms-wmv",         // .wmv
];
export const ACCESS_PERMISSION_DENIED_PLACEHOLDER_IMAGE_BASE64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAf4AAAFABAMAAACvgQMTAAAAG1BMVEXMzMyWlpacnJy3t7eqqqqjo6PFxcWxsbG+vr7NhispAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEWElEQVR4nO3Yz2/bNhTA8SfLsX00Ff/IkUSToke72+72MBQ5Rhsw9GhvmbdjDAxBj/aWFP2z+x4pB25aVN4lcpfv58AwIv3gJ1FPlEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACALylqZ3TcrD5M5lzx9mvj/rCv8+Tq88+drw+j+Tt38+mxsD9+QIxG1Offdmf1YbJXH/4tH837n+SfDcb1YTIv0n0U6+9H40epPv/tZf0cybw2YfP18WNUSKv/upi0w68i1+Fio/d7+SLz0vlxOEkz5hO3EBvU+qZHN9b9xbrFG+ue26DoJ0TWN9VYyEs9HKSKkiIeJ81/XJTj8LNbdIKzW33t3NTL1rnTNCMsSs2hp/VtY0fPYnciv2nFjx95aZMyr832qhoL64vw0vJPUaYx4nHS/N2kq0nMZ93h4nqoj7vLO6344fJPFyf0hrK+EmkN3s/t6HVh3XVf5pd/lbP24P39wGZlXpulr8bCWE7Gln+K4i7vg28ku3qWjuY8keXVyUi/qnQ1n9Ln2q7jDdAdy7Ifr+3JSM+FlIvpTLqnll3mW/2qgmTemr5UYzM7bUFSFGuDbyi/Opr/maUtmc9vrHOi/069nYvllU3Qns2YbyR/0dbO60m5kM5QtCj0NplO+2CzMq+Nno00poWwU2j+KYpFXPumEqyh+Y/iNcy8lqs7Z0noQm6NVqultwnatm1JWP+kb21YrVaFlOONHnDvUhj7tDVpLCwsZJAUxSJu/VMl9B8VUq3hzMu9Fi/7qznH/VzMVouXK6rNTCsesSGn9U/rv9a/vfqvCyaNBUn5pyhZPKNPmtXh9vLvufPbEP/N/NK++cgmlNZbSCyGlokWi3Tke3sg5PO0O8y8NttZNbbLP0XJ+kf+/K/y15Jt92y8WjHTyOmKnt/ES7qwqZ10KsQe61YgfhhaP/MSn/9p7OH6xyitb+T6W8dJO9Y/Kwq51f/c0pteSbnRwh4r2cTu7u/kJ1sO7ZlUK8OL7RQkje3yT1GsCm59A7kdYi9/vVq5iwmXvj225azjbdsFLUeynmk29mwMC10NvYFlmvmqdqb82/rJakxS/ilKru3cN5JdvU/Wf2et93V4o/ufjrv9XW9viZdQWqe6S1zpcg+3fwxlOV5Nz2T+1vY/w93+p3r/S2O7/KsoYdM75B26EXv5586NNQfds5bedsHxtW/rtdEHYB5r3tSKYje+6i/j/tft9r8ubnyrsV3+VZTpsJz7hvKrs//80/egf15Jrzxfem0v4vZP1739BCJyb687PXuv0e7n7z+733/i2EP+KUqvHGj0b0ja+T1PuV5V3cU/W1aty0nT36I5HffuLv7g8VytH375eJ664XzT8FcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAsOcjuU2aDjHBRV0AAAAASUVORK5CYII=";

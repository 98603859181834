import styled from "styled-components";
import COLORS from "../../../constants/colors";

export const Container = styled.div`
  width: ${({isVisible}) => !isVisible ? "0" : "450"}px;
  overflow: hidden;
`;

export const Content = styled.div`
  background: ${COLORS.WHITE};
  width: 300px;
  min-width: 300px;
  min-height: 30px;
`;

import React, { useState, useEffect, useReducer } from "react";
import { get } from "lodash";
import { List } from "immutable";
import { useMutation } from "@apollo/react-hooks";
import { extractMessageFromGraphQLError } from "../../../../utils/helpers";
import { usePrevious, useDidUpdate, useNavigatorOnline } from "../../../../utils/hooks";
import { REMOVE_LEARNING_UNIT_FROM_TUTORIAL, UPDATE_LEARNING_UNIT_ORDER } from "./mutations";
import { TutorialsContainer, TutorialCollapse, TutorialCollapsePanel, EmptyListMessageContainer, ToastMessage } from "./styled";
import { reorderListItem, removeListItem, addListItem, reducer } from "../reducer";
import ConditionalDisplay from "../../../presentational/ConditionalDisplay";
import DroppablePalette from "../../../presentational/DroppablePalette";
import TutorialCardHeader from "../TutorialCardHeader";
import TutorialListItem from "./TutorialListItem";
import AddNew from "../AddNew";
import { shouldBuilderBeReadOnly } from "./helpers";
import { raiseErrorIfNotString, raiseErrorIfNotObject, raiseErrorIfNotNumber, raiseErrorIfNotArray } from "../../../../utils/typeErrors";

const hasLearingUnitOrderChanged = (prevLearningUnitList, currLearningUnitList) => {
  // conditional on length matching because currently the updateOrder query will not be called
  // if something has been added to or removed from the list
  // we only care about updating a list if a reorder has happened

  if (prevLearningUnitList.size === currLearningUnitList.size) {
    const elemsOutOfOrder = prevLearningUnitList.filter((prevLU, idx) => prevLU._id !== currLearningUnitList.get(idx)._id);
    return elemsOutOfOrder.size > 0;
  }
  return false;
};

const TutorialBuilder = ({ panelKey, tutorial, subjectId, templates, accessLevel }) => {
  raiseErrorIfNotNumber(panelKey);
  raiseErrorIfNotString(subjectId);
  raiseErrorIfNotArray(templates);
  raiseErrorIfNotObject(tutorial);

  let maxOrder = -1;
  const luList = List(tutorial.learningUnits);
  const [state, dispatch] = useReducer(reducer, { ...tutorial, learningUnits: luList });
  const prevLearningUnits = usePrevious(state.learningUnits);

  const [loadingLUID, setLoadingLUID] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [tutorialName, setTutorialName] = useState(get(tutorial, "subjectTutorials.0.displayName", tutorial.name) || tutorial.name);
  const [isReadOnly, setReadOnly] = useState(true);

  const { isOffline } = useNavigatorOnline();
  // we want the builder to be read only when app is offline
  useEffect(() => {
    const readOnlyState = shouldBuilderBeReadOnly({ isOffline, accessLevel: tutorial.accessLevel });
    setReadOnly(readOnlyState);
  }, [isOffline]);

  const [removeLearningUnitFromTutorial, { loading: loadingRemoveLU }] = useMutation(REMOVE_LEARNING_UNIT_FROM_TUTORIAL, {
    onCompleted: (data) => {
      dispatch(removeListItem(data.removeLearningUnitFromTutorial));
      setLoadingLUID("");
    },
    onError: (error) => {
      const msg = extractMessageFromGraphQLError(error);
      ToastMessage.error(msg);
      setLoadingLUID("");
    },
  });

  const handleItemRemove = async (luId) => {
    setLoadingLUID(luId);

    await removeLearningUnitFromTutorial({
      variables: {
        learningUnitSyncId: luId,
        tutorialId: tutorial._id,
      },
    });
  };

  const [updateLearningUnitOrder] = useMutation(UPDATE_LEARNING_UNIT_ORDER, {
    onCompleted: (data) => {
      // console.log("UPDATE ORDER", data.updateLearningUnitOrder);
    },
  });

  useDidUpdate(() => {
    if (hasLearingUnitOrderChanged(prevLearningUnits, state.learningUnits)) {
      const orderedLearningUnits = state.learningUnits.map((learningUnit, index) => ({ learningUnitId: learningUnit._id, order: index }));
      updateLearningUnitOrder({
        variables: {
          tutorialId: tutorial._id,
          learningUnitsWithOrder: orderedLearningUnits,
        },
      });
    }
  }, [state.learningUnits]);

  const onTutorialChange = (key) => {
    // if collapse is accordion type, key is either set or undefined instead of array type
    setIsActive(Array.isArray(key) ? key.length > 0 : !!key);
  };

  const renderTutorialListItems = (tutorialId, isReadOnlyItem) => {
    const { learningUnits } = state;
    if (learningUnits.length === 0)
      return <EmptyListMessageContainer>No learning materials. Click 'Add New' to add one!</EmptyListMessageContainer>;
    return learningUnits.map((lu, index) => {
      if (lu.isArchived) {
        return "";
      }
      const luOrder = Number.parseInt(lu.order, 10);
      maxOrder = luOrder > maxOrder ? luOrder : maxOrder;
      return (
        <TutorialListItem
          key={lu._id}
          index={index}
          tutorialId={tutorialId}
          subjectId={subjectId}
          learningUnit={lu}
          handleItemRemove={() => handleItemRemove(lu._id)}
          isReadOnly={isReadOnlyItem}
          loading={lu._id === loadingLUID}
        />
      );
    });
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;
    dispatch(reorderListItem(result.source.index, result.destination.index));
  };

  return (
    <TutorialsContainer>
      <TutorialCollapse defaultActiveKey={panelKey} bordered={false} onChange={onTutorialChange}>
        <TutorialCollapsePanel
          key={panelKey}
          header={
            <TutorialCardHeader
              title={tutorialName}
              tutorialId={tutorial._id}
              subjectTutorialId={get(tutorial, "subjectTutorials.0._id")}
              learningUnitsCount={state.learningUnits.filter((lu) => !lu.isArchived).size}
              onTutorialNameChange={(value) => setTutorialName(value)}
              isActive={isActive}
              isReadOnly={isReadOnly}
            />
          }
        >
          <DroppablePalette onDragEnd={!isReadOnly && onDragEnd}>
            {renderTutorialListItems(tutorial._id, isReadOnly)}
            <ConditionalDisplay showIf={!isReadOnly}>
              <AddNew dispatch={dispatch} tutorialId={tutorial._id} addNewOrder={maxOrder + 1} templates={templates} />
            </ConditionalDisplay>
          </DroppablePalette>
        </TutorialCollapsePanel>
      </TutorialCollapse>
    </TutorialsContainer>
  );
};

export default TutorialBuilder;

import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import Icon from "./icon.svg";

/**
 * @description H5P Plugin for CKEditor
 */
class H5PEmbed extends Plugin {
  init() {
    const { editor } = this;

    editor.ui.componentFactory.add("h5pEmbed", (locale) => {
      const view = new ButtonView(locale);

      /**
       * @description Show the error message
       * @param {string} error - Error message
       */
      const showError = (error) => {
        // eslint-disable-next-line no-alert
        alert(error);
      };

      /**
       * @description Validate a URL against a regex rule
       * @param {string} url - URL to validate
       */
      const validateUrl = (url) => {
        if (!url) return false;
        const rules = [/^h5p\.org\/(\d+)/, /^h5p\.org\/h5p\/embed\/(\d+)/, /^mobile-learning\.h5p\.com\/content\/(\d+)\/embed/];
        const exclude = new RegExp(/https:\/\/|http:\/\//, "i");
        const urlToMatch = url.replace(exclude, "");
        const matches = rules.map((rule) => !!urlToMatch.match(rule));
        return matches.includes(true);
      };

      view.set({
        label: "Insert H5P",
        icon: Icon,
        tooltip: true,
      });

      // Callback executed once "Insert H5P" is clicked.
      view.on("execute", () => {
        // eslint-disable-next-line no-alert
        const url = prompt("Paste the H5P URL in the input.");
        if (!url) {
          // Do nothing
        } else if (validateUrl(url)) {
          try {
            editor.execute("mediaEmbed", url); // e.g. https://h5p.org/h5p/embed/6729
          } catch(err) {
            showError("The H5P URL you entered is invalid.");
          }
        } else {
          showError("The H5P URL you entered is invalid.");
        }
      });

      return view;
    });
  }
}

export default H5PEmbed;

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import {
  ALLOWED_SNIPPET_TYPES,
  UNALLOWED_SNIPPET_TYPES,
  IMAGE_MIME_TYPES,
  VIDEO_MIME_TYPES,
  ACCESS_PERMISSION_DENIED_PLACEHOLDER_IMAGE_BASE64,
  COVER_FRONT_IMAGE,
  COVER_BACK_IMAGE,
  MLU_LOGO,
} from "../constants";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Merriweather: {
    normal: "https://cdn.jsdelivr.net/gh/google/fonts@master/ofl/merriweather/Merriweather-Regular.ttf",
    bold: "https://cdn.jsdelivr.net/gh/google/fonts@master/ofl/merriweather/Merriweather-Bold.ttf",
    italics: "https://cdn.jsdelivr.net/gh/google/fonts@master/ofl/merriweather/Merriweather-Italic.ttf",
    bolditalics: "https://cdn.jsdelivr.net/gh/google/fonts@master/ofl/merriweather/Merriweather-BoldItalic.ttf",
  },
};

const buildPlaceHolder = (type, width) => {
  return {
    margin: [0, 10, 0, 10],
    alignment: "center",
    table: {
      widths: width ? [width] : [510],
      heights: [50],
      body: [[{ text: `Placeholder for ${type}`, alignment: "center" }]],
    },
  };
};

const buildImageDescription = (text, width) => {
  return {
    table: {
      widths: width ? [width] : [450],
      body: [[{ text, style: "imageDescription", border: [false, false, false, true] }]],
    },
    margin: [0, 0, 0, 10],
  };
};

const getColorBoxColor = (styleClass) => {
  if (styleClass.includes("outcomes")) {
    return {
      background: "#00D1B2",
      backgroundOpacity: 0.06,
      border: ["#00D1B2"],
    };
  }
  if (styleClass.includes("example")) {
    return {
      background: "#FFFAEB",
      backgroundOpacity: 1,
      border: ["#FEC058"],
    };
  }
  if (styleClass.includes("case_study")) {
    return {
      background: "#FFF3F6",
      backgroundOpacity: 0.06,
      border: ["#FF3860"],
    };
  }
  if (styleClass.includes("hint")) {
    return {
      background: "#8461FF",
      backgroundOpacity: 0.08,
      border: ["#8461FF"],
    };
  }
  if (styleClass.includes("reflect")) {
    return {
      background: "#EAF2FF",
      backgroundOpacity: 0.58,
      border: ["#2979FF"],
    };
  }
  return undefined;
};

const getFileNameFromUrl = (url) => {
  return url.substring(url.lastIndexOf("/")).split("?")[0];
};

const htmlToPdfmakeDefaultOptions = {
  defaultStyles: {
    a: { color: "#0550AE", decoration: "underline" },
  },
  customTag: function (params) {
    let ret = params.ret;
    const { element, parents } = params;
    switch (ret.nodeName) {
      case "IFRAME": {
        ret = this.applyStyle({ ret: ret, parents: parents.concat([element]) });
        const iframeUrl = element.getAttribute("src");
        if (iframeUrl &&!iframeUrl.includes("h5p.com")) {
          ret = {
            margin: [0, 5, 0, 10],
            nodeName: "P",
            style: ['html-p'],
            text: [
              "\nVideo: ",
              {
                text: iframeUrl,
                link: iframeUrl,
                ...htmlToPdfmakeDefaultOptions.defaultStyles.a,
              },
              "\n",
            ],
          };
        } else {
          ret = buildPlaceHolder("H5P", 450);
        }
      }
    }

    return ret;
  },
  replaceText: function (text, nodes) {
    // 'nodes' contains all the parent nodes for the text
    return text.replace(/\s/g, " "); // remove irregular whitespaces
  },
};

export const buildPDF = async ({ subjectData, setExporting, setPercent }) => {
  try {
    setExporting(true);

    let imagesToDownload = [COVER_FRONT_IMAGE, COVER_BACK_IMAGE, MLU_LOGO];

    const docDefinition = {
      defaultStyle: {
        font: "Merriweather",
      },
      footer(currentPage, pageCount) {
        if (currentPage > 2 && currentPage < pageCount) {
          return {
            text: `${currentPage - 2}`,
            color: "#44495E",
            alignment: "center",
          };
        }
        return {};
      },
      content: [
        {
          image: getFileNameFromUrl(COVER_FRONT_IMAGE),
          width: 600,
          absolutePosition: { x: 0, y: 0 },
        },
        {
          text: subjectData.subject.name,
          fontSize: 30,
          bold: true,
          alignment: "left",
          color: "#172B4D",
          margin: [30, 640, 30, 0],
          pageBreak: "after",
        },
        {
          image: getFileNameFromUrl(MLU_LOGO),
          width: 120,
          margin: [0, 122, 0, 0],
          alignment: "center",
        },
        {
          text: "Copyright \xA9 2022 Mobile Learning Unit",
          margin: [0, 40, 0, 0],
          alignment: "center",
        },
        {
          text: "All rights reserved.",
          alignment: "center",
        },
        {
          text: "All rights reserved. No part of this book may be reproduced or",
          margin: [0, 40, 0, 0],
          alignment: "center",
        },
        {
          text: "used in any manner without the prior written permission of",
          alignment: "center",
        },
        {
          text: "the copyright owner.",
          alignment: "center",
        },
        {
          text: "To request permission, contact the publisher at",
          margin: [0, 40, 0, 0],
          alignment: "center",
        },
        {
          text: "mobile-learning@unimelb.edu.au.",
          alignment: "center",
          bold: true,
        },
      ],
      images: {},
      styles: {
        // author: {
        //   font: "SourceSansPro",
        //   fontSize: 21,
        //   color: "#5B667D",
        //   bold: true,
        //   margin: [30, 20, 30, 0],
        // },
        image: {
          margin: [0, 10, 0, 10],
        },
        imageDescription: {
          fontSize: 12,
          italics: true,
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        outcomes: {
          fillColor: getColorBoxColor("outcomes")?.background,
          fillOpacity: getColorBoxColor("outcomes")?.backgroundOpacity,
          marginBottom: 20,
        },
        example: {
          fillColor: getColorBoxColor("example")?.background,
          fillOpacity: getColorBoxColor("example")?.backgroundOpacity,
          marginBottom: 20,
        },
        case_study: {
          fillColor: getColorBoxColor("case_study")?.background,
          fillOpacity: getColorBoxColor("case_study")?.backgroundOpacity,
          marginBottom: 20,
        },
        reflect: {
          fillColor: getColorBoxColor("reflect")?.background,
          fillOpacity: getColorBoxColor("reflect")?.backgroundOpacity,
          marginBottom: 20,
        },
        hint: {
          fillColor: getColorBoxColor("hint")?.background,
          fillOpacity: getColorBoxColor("hint")?.backgroundOpacity,
          marginBottom: 20,
        },
        "image-style-align-left": {
          alignment: "left",
        },
        "image-style-align-center": {
          alignment: "center",
        },
        "image-style-align-right": {
          alignment: "centeright",
        },
      },
    };

    subjectData.tutorialsWithPublishedLearningUnits.forEach(({ name: tutorialName, learningUnits }) => {
      // subject name
      docDefinition.content.concat([
        {
          table: {
            widths: [350, 150, 10],
            body: [
              [
                { border: [false, false, false, false], text: "" },
                { border: [false, true, false, false], text: "" },
                { border: [false, false, false, false], text: "" },
              ],
            ],
          },
          layout: {
            hLineWidth: () => 5,
          },
          margin: [0, 190, 0, 10],
          pageBreak: "before",
        },
        {
          text: tutorialName,
          alignment: "right",
          fontSize: 26,
          bold: true,
          margin: [0, 10, 0, 70],
        },
      ]);

      // learning units
      learningUnits.forEach(({ learningUnitComponents }) => {
        docDefinition.content.push({
          pageBreak: "before",
          text: "",
        });
        learningUnitComponents.forEach((luc) => {
          const { html, styleClass, fileList, type } = luc;
          if (ALLOWED_SNIPPET_TYPES.includes(type)) {
            if (html) {
              const content = htmlToPdfmake(html, htmlToPdfmakeDefaultOptions);
              if (styleClass) {
                // color boxes
                docDefinition.content.push({
                  style: styleClass,
                  lineHeight: 1.2,
                  layout: {
                    vLineWidth: () => 0.75 * 5, // 5px
                  },
                  table: {
                    widths: ["*"],
                    body: content.map((c, i) => {
                      if (c.nodeName === "FIGURE") {
                        const stack = c.stack.map((s) => {
                          if (s.nodeName === "IMG") {
                            const url = s.image;
                            imagesToDownload.push(url);
                            const key = getFileNameFromUrl(url);
                            const hasWidth = html.match(/<figure.*style=["'].*width:([0-9\.]+?)%.*<img/) || [];
                            const percent = hasWidth[1] || 100;
                            return {
                              ...s,
                              image: key,
                              width: (450 * percent) / 100,
                            };
                          }
                          if (s.nodeName === "FIGCAPTION") {
                            return buildImageDescription(s.text, 450);
                          }
                          return s;
                        });
                        return [
                          {
                            ...c,
                            stack,
                            border: [true, false, false, false],
                            borderColor: getColorBoxColor(styleClass)?.border,
                            margin: i < content.length - 1 ? [20, 20, 20, 5] : [20, 0, 20, 20],
                          },
                        ];
                      }
                      return [
                        {
                          ...c,
                          border: [true, false, false, false],
                          borderColor: getColorBoxColor(styleClass)?.border,
                          margin: i < content.length - 1 ? [20, 20, 20, 5] : [20, 0, 20, 20],
                        },
                      ];
                    }),
                  },
                });
              } else {
                docDefinition.content = docDefinition.content.concat(
                  content.map((c) => {
                    if (c.nodeName === "FIGURE") {
                      const stack = c.stack.map((s) => {
                        if (s.nodeName === "IMG") {
                          const url = s.image;
                          imagesToDownload.push(url);
                          const key = getFileNameFromUrl(url);
                          const hasWidth = html.match(/<figure.*style=["'].*width:([0-9\.]+?)%.*<img/) || [];
                          const percent = hasWidth[1] || 100;
                          return {
                            ...s,
                            image: key,
                            width: (510 * percent) / 100,
                          };
                        }
                        if (s.nodeName === "FIGCAPTION") {
                          return {
                            table: {
                              widths: [510],
                              body: [[{ text: s.text, style: "imageDescription", border: [false, false, false, true] }]],
                            },
                            margin: [0, 0, 0, 10],
                          };
                        }
                        return s;
                      });
                      return {
                        ...c,
                        stack,
                      };
                    }
                    return {
                      ...c,
                      lineHeight: 1.2,
                    };
                  }),
                );
              }
            }

            // files uploaded on AWS S3
            if (fileList) {
              const fileListArr = JSON.parse(fileList);
              fileListArr.forEach((file) => {
                const { description, url, type: fileType, width } = file;
                if (IMAGE_MIME_TYPES.includes(fileType)) {
                  const key = getFileNameFromUrl(url);
                  imagesToDownload.push(url);
                  docDefinition.content.push({
                    image: key,
                    width: width < 510 ? width : 510,
                    style: "image",
                  });
                  docDefinition.content.push({
                    table: {
                      widths: [510],
                      body: [[{ text: description, style: "imageDescription", border: [false, false, false, true] }]],
                    },
                    margin: [0, 0, 0, 10],
                  });
                } else if (VIDEO_MIME_TYPES.includes(fileType)) {
                  docDefinition.content.push({
                    lineHeight: 1.2,
                    text: [
                      "\nVideo: ",
                      {
                        text: description || "Download",
                        link: url,
                        ...htmlToPdfmakeDefaultOptions.defaultStyles.a,
                      },
                      "\n",
                    ],
                  });
                } else {
                  docDefinition.content.push(buildPlaceHolder(fileType));
                }
              });
            }
          }
          if (UNALLOWED_SNIPPET_TYPES.includes(type)) {
            docDefinition.content.push(buildPlaceHolder(type));
          }
        });
      });
    });

    // back cover
    docDefinition.content.push({
      image: getFileNameFromUrl(COVER_BACK_IMAGE),
      width: 600,
      absolutePosition: { x: 0, y: 0 },
      pageBreak: "before",
    });

    let i = 0;
    const fetches = imagesToDownload.map((url) => {
      const key = getFileNameFromUrl(url);
      return fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          throw Error(`${response.url} returned code ${response.status}.`);
        })
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            }),
        )
        .then((base64) => {
          docDefinition.images[key] = base64;
          console.log(`Downloaded Image (${i++}/${imagesToDownload.length}) ${url}`);
          setPercent(Math.floor(i*100/imagesToDownload.length));
        })
        .catch((err) => {
          console.log("Image fetch Error: ", err);
          i++;
          docDefinition.images[key] = ACCESS_PERMISSION_DENIED_PLACEHOLDER_IMAGE_BASE64;
          setPercent(Math.floor(i*100/imagesToDownload.length));
        });
    });

    await Promise.all(fetches);

    console.log("docDefinition: ", docDefinition);
    const pdfDoc = pdfMake.createPdf(docDefinition);
    pdfDoc.open();
    setExporting(false);
  } catch (error) {
    console.error("Error when building PDF: ", error);
    setExporting(false);
    setPercent(0);
  }
};

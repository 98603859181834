import gql from "graphql-tag";

export const GET_LEARNING_UNIT_VERSIONS_MANIFEST = gql`
  query getLearningUnitVersionsManifest($tutorialId: String!, $learningUnitId: String!) {
    getLearningUnitVersionsManifest(tutorialId: $tutorialId, learningUnitId: $learningUnitId) {
      learningUnit {
        _id
        name
        currentVersion
        isPublished
        subjectAssessments {
          _id
          displayName
        }
      }
      versions {
        _id
        learningUnitName
        currentVersionNumber
        createdBy
        isPublished
        createdDateTime
        publishedByContact {
          _id
          name
        }
      }
    }
  }
`;

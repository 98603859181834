import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "../pages/Home";
import BuildLearningUnit from "../pages/BuildLearningUnit";
import BuildSubject from "../pages/SubjectBuilderPage";

import GuardRoute from "../authentication/GuardRoute";
import RedirectRoute from "../authentication/RedirectRoute";
import Callback from "../authentication/Callback";

const NotFound = (match) => {
  return <div>Page not found</div>;
};

const App = () => (
  <BrowserRouter>
    <Switch>
      <GuardRoute path="/" exact component={Home} />
      <GuardRoute path="/sbj:subjectId/tut:tutorialId/buildlearningunit:learningUnitId/v:version" exact component={BuildLearningUnit} />
      <GuardRoute path="/sbj:subjectId/tut:tutorialId/buildlearningunit:learningUnitId" exact component={BuildLearningUnit} />
      <GuardRoute path="/sbj:subjectId" exact component={BuildSubject} />
      <Route path="/callback" exact component={Callback} />
      <Route path="/redirect" exact component={RedirectRoute} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);
export default App;

import React, { useRef, useEffect, forwardRef, useContext, useState } from "react";
import { createContext } from "./plugins/Context";
import { StyledButton, StyledButtonContainer } from "./styled";
import CollaborationContext from "../CollaborationContext"
import { handleInitContext } from "./helpers";
import { getCommentThreads } from "../Editor/helpers";

const Commentable = forwardRef((props, ref) => {
  const { children, showComments, initialComments, onChange } = props;
  const buttonRef = useRef(null);
  // const dataRef = useRef(null);
  const loadDataRef = useRef(null);

  const collaborationContext = useContext(CollaborationContext.Context);
  const { userId, users } = collaborationContext;

  useEffect(() => {
    const initContext = async () => {
      
      const ContextAdapter = createContext({ sidebarElement: ref.current });
      const contextConfiguration = {};
      const context = await ContextAdapter.create( contextConfiguration );
      const button = buttonRef.current; 
      const field = !!button && button.parentNode;

      const { commentsRepository } = handleInitContext(context, { target: field, userId, users, comments: initialComments });

      if (!!commentsRepository) {
        // Add a new thread only if there isn't one.
        const doThreadsExists = (commentsRep) => {
          const commentThreadsData = commentsRep.getCommentThreads({
            skipNotAttached: true,
            skipEmpty: true,
          });
          
          if ( !!commentThreadsData && commentThreadsData.length > 0 ) {
            return true;
          }
          return false;
        };
        
        
        button.addEventListener("click", () => {
          const threadExists = doThreadsExists(commentsRepository);
          if (threadExists) return;
          
          // Create new thread
          commentsRepository.openNewCommentThread( {
            // channelId,
            threadId: "thread-1",
            target: field
          });       
          
        });

        commentsRepository.on("addComment", (evt, data) => {
          const comments = getCommentThreads(context);
          onChange({ comments: JSON.stringify(comments) })
        });
        
        // loadDataRef.current.addEventListener("click", () => {
        //   const threadExists = doThreadsExists(commentsRepository);
        //   if (threadExists) return;
          
        //   // Load the comment threads data.
        //   for (const commentThread of appData.commentThreads) {
        //     commentsRepository.addCommentThread({...commentThread, target: field});
        //   }
        // });
        
        
        // dataRef.current.addEventListener("click", () => {
        //   // const editorData = context.data.get();
        //   const commentThreadsData = commentsRepository.getCommentThreads({
        //     skipNotAttached: true,
        //     skipEmpty: true,
        //   });

        //   // Now, use `editorData` and `commentThreadsData` to save the data in your application.
        //   // For example, you can set them as values of hidden input fields.
        //   // ref.current.innerHTML = editor.plugins.get('Sidebar').view.element.toString();
        // });
      }
    };
    initContext();
  }, [userId, users]);

  return (
    <div>
      {children}

      <StyledButtonContainer ref={buttonRef} isVisible={showComments}>
        <StyledButton>Comment</StyledButton>
      </StyledButtonContainer>
      {/* <StyledButtonContainer ref={loadDataRef} isVisible={showComments}>
        <StyledButton>Load Data</StyledButton>
      </StyledButtonContainer> */}
      {/* <StyledButtonContainer ref={dataRef} isVisible={showComments}>
        <StyledButton>Get Data</StyledButton>
      </StyledButtonContainer> */}
    </div>
  );
});

export default Commentable;
import React, { useState } from "react";
import { map, get } from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  Card,
  CardCover,
  CardBody,
  CardBodyHeader,
  CardBodyContent,
  CardTitle,
  CardLogo,
  ExpandIndicator,
  ItemIcon,
  Link,
  ListContainer,
  SubjectSection,
  SubjectListItem,
} from "./styled";
import "./blur.css";

const CourseCard = ({ course, mockCoverImage, logoImage }) => {
  const [showList, setShowList] = useState(false);
  const toggleListShow = () => {
    setShowList(!showList);
  };

  function renderSubjectItems() {
    return map(
      course.subjects.sort((a, b) => a.subjectNumber - b.subjectNumber),
      (subject) => (
        <SubjectListItem key={`subject-${subject._id}`}>
          <ItemIcon type="appstore" />
          <Link to={`/sbj${subject._id}`}>{subject.name}</Link>
        </SubjectListItem>
      ),
    );
  }

  return (
    <Card>
      <CardCover
        show={!showList}
        onClick={() => {
          toggleListShow();
        }}
      >
        <LazyLoadImage alt="example" effect="blur" src={mockCoverImage} />
      </CardCover>
      <CardBody show={showList}>
        <CardBodyHeader
          show={showList}
          onClick={() => {
            toggleListShow();
          }}
        >
          <CardTitle show={showList}>{course.name}</CardTitle>
          <ExpandIndicator type={showList ? "ellipsis" : "more"} />
        </CardBodyHeader>
        <CardBodyContent show={!showList}>{logoImage && <CardLogo src={logoImage} />}</CardBodyContent>
      </CardBody>

      <ListContainer show={showList}>
        <SubjectSection>{renderSubjectItems()}</SubjectSection>
      </ListContainer>
    </Card>
  );
};

export default CourseCard;

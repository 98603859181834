import omit from "lodash/omit";
import { List } from "immutable";

/**
 * @description if the input is a JSON string it is parsed and the resulting object is returned, otherwise it returns the input unchanged
 * @param obj - anything
 */
export const parseIfJSON = (obj) => {
  try {
    return JSON.parse(obj);
  } catch (error) {
    return obj;
  }
};

/**
 * @description if the input is in array, the method returns the fist element of the array otherwise it returns the input unchanged
 * @param obj - anything
 */
export const unwrapIfInArray = (obj) => {
  return Array.isArray(obj) ? obj[0] : obj;
};

/**
 * @description returns a promise that resoves after the specified delay
 * @param ms - time in miliseconds
 */
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const extractMessageFromGraphQLError = (error) => {
  // extracting the message field from the first element of graphQLErrors
  const message = error && error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].message;
  return message;
};

export const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x);

export const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// generates interval that are randomized anywhere between 0ms (instant), and 2x the configured interval.
// This way you get the same result on average, but with random interval. This is useful for
export const generateJitterIntervals = (ms) => getRandomInt(2 * ms);

// Polling function that excutes the function passed into it after every interval until a timeout it reached
// the first execution of the polled function is done after an initial delay of the interval
// the function the executes will not return a value.
// By default we call the function passed into the poll function every 20 seconds for an hour
export const poll = async (fn, interval = 20000, timeout = 3600000, intervalGenerator) => {
  let timer;
  const endTime = Number(new Date()) + timeout;
  const polledFunction = (resolve, reject) => {
    if (timer) {
      clearTimeout(timer);
    }
    if (Number(new Date()) < endTime) {
      timer = setTimeout(polledFunction, interval, resolve, reject);
    } else {
      reject(new Error(`timed out for ${fn}`));
    }
    fn();
  };
  const delayInterval = typeof intervalGenerator === "function" ? intervalGenerator(interval) : interval;
  await delay(delayInterval);
  return new Promise(polledFunction);
};

/**
 * @description Remove a property from an object
 * @param {object} obj - Any Object
 * @param {string} prop - Name of the property
 */
export const omitPropertyFromObject = (obj, prop) => {
  if (!obj) {
    return null;
  }
  return List.isList(obj) ? obj.map((objItem) => omitPropertyFromObject(objItem, prop)) : omit({ ...obj }, prop);
};

import React, { useState } from "react";
import { FreeTextQuestion, SingleAnswerQuestion, SurveyQuestion, SurveyScaleNames } from "../../../presentational/Question";
import Paginate from "../../../presentational/Paginate";
import { QUESTION_TYPES } from "../../../../constants/types";
import { ScrollContainer, Card, MandatoryLabel } from "./styled";
import EnhancedEditorImages from "../../../presentational/EnhancedEditorImages";
import { SurveyCardWrapper } from "../../../presentational/Question/styled";

const AssessmentOpportunityPreview = ({ luc }) => {
  const { title, mandatory, showExplanationUponSubmission, questions } = luc;
  const [currentQuestionInView, setCurrentQuestionInView] = useState(1);
  const goToQuestion = (questionIndex) => {
    setCurrentQuestionInView(questionIndex);
  };

  const renderMandatory = () => {
    return mandatory && <MandatoryLabel>MANDATORY</MandatoryLabel>;
  };

  const deriveCorrectAnswerId = (answerOptions) => {
    const correctAnswer = answerOptions.find((ao) => ao.correctValue === true);
    if (correctAnswer) return correctAnswer._id;
    return null;
  };

  const hasQuestions = questions.size > 0;
  const firstSurvey = questions.find((q) => q.type === QUESTION_TYPES.SURVEY || q.type === QUESTION_TYPES.BINARY_RESPONSE);
  const isSurvey = !!firstSurvey;

  return (
    <ScrollContainer>
      {hasQuestions && (
        <EnhancedEditorImages>
          <Card title={title || "Quiz"} extra={renderMandatory()}>
            {isSurvey ? (
              <>
                <SurveyCardWrapper bodyStyle={{ padding: "20px" }}>
                  <SurveyScaleNames description={firstSurvey.preQuestionText} answerOptions={firstSurvey.answerOptions} />
                  {questions
                    .filter(({ type }) => type === QUESTION_TYPES.SURVEY || type === QUESTION_TYPES.BINARY_RESPONSE)
                    .map((question) => (
                      <SurveyQuestion
                        key={question._id}
                        question={question.question}
                        answerOptions={firstSurvey.answerOptions}
                        isSubmitted
                      />
                    ))}
                </SurveyCardWrapper>
                <SurveyCardWrapper bodyStyle={{ padding: "0" }}>
                  {questions
                    .filter(({ type }) => type === QUESTION_TYPES.FREE_TEXT)
                    .map((question, index) => (
                      <div key={index} className="surveyFreeTextQuestion">
                        <FreeTextQuestion question={question.question} showExplanation={false} explanation={""} />
                      </div>
                    ))}
                </SurveyCardWrapper>
              </>
            ) : (
              <Paginate currentPage={currentQuestionInView} goToPage={goToQuestion}>
                {questions.map((q, index) => {
                  const { question, type, explanation, answerOptions } = q;
                  switch (type) {
                    case QUESTION_TYPES.FREE_TEXT:
                      return (
                        <FreeTextQuestion
                          key={index}
                          question={question}
                          showExplanation={showExplanationUponSubmission}
                          explanation={explanation}
                        />
                      );
                    case QUESTION_TYPES.MULTIPLE_CHOICE:
                      return (
                        <SingleAnswerQuestion
                          key={index}
                          question={question}
                          answerOptions={answerOptions.toJSON()}
                          correctAnswerValue={deriveCorrectAnswerId(answerOptions)}
                          selectedAnswerValue={deriveCorrectAnswerId(answerOptions)}
                          explanation={explanation}
                          isSubmitted={true}
                          showExplanation={true}
                        />
                      );
                    default:
                      return null;
                  }
                })}
              </Paginate>
            )}
          </Card>
        </EnhancedEditorImages>
      )}
    </ScrollContainer>
  );
};

export default AssessmentOpportunityPreview;

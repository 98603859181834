import React from "react";
import { Card, QuestionText, TextInput, TextArea } from "../styled";

import { RichText } from "../../Snippet";
import Explanation from "../Explanation";

const FreeTextQuestion = ({
  question,
  isSubmitted = true,
  isLoading,
  explanation,
  answerValue,
  onAnswerChange,
  showExplanation,
  isNotMultiline,
}) => {
  const disabled = isSubmitted || isLoading;
  const Input = isNotMultiline ? TextInput : TextArea;

  const handleAnswerChange = (event) => {
    const { value } = event.target;
    onAnswerChange(value);
  };

  return (
    <Card bodyStyle={{ padding: "10px" }}>
      <QuestionText>
        <RichText html={question} />
      </QuestionText>
      <Input disabled={disabled} value={answerValue} onChange={handleAnswerChange} />
      <Explanation isSubmitted={isSubmitted} explanation={explanation} showExplanation={isSubmitted || showExplanation} />
    </Card>
  );
};

export { FreeTextQuestion };

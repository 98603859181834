import React from "react";
import { List } from "immutable";
import MediaCarousel from "../../../presentational/MediaCarousel";
import { MediaCarouselContainer } from "./styled";
import { parseIfJSON } from "../../../../utils/helpers";

const MediaCarouselPreview = ({ fileList }) => {
  // Convert the immutable list to JSON
  // const contents = fileList.toJSON();
  const mediaFileList = Array.isArray(parseIfJSON(fileList)) ? parseIfJSON(fileList) : fileList.toJSON();

  return (
    <MediaCarouselContainer>
      <MediaCarousel contents={mediaFileList} />
    </MediaCarouselContainer>
  );
};

export default MediaCarouselPreview;

import { notification } from "antd";

const fireNotification = (message, btn, key = `open${Date.now()}`, type = "open") => {
  const notify = notification[type];
  notify({
    message,
    top: 75,
    btn,
    key,
    duration: 0,
  });
};

export default fireNotification;

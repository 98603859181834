import React, { forwardRef } from "react";
import { Container, Content } from "./styled";

const CommentsPanel = forwardRef((props, ref) => {
  const { isVisible } = props;

  return (
    <Container isVisible={isVisible}>
      <Content className="commentsPanel" isVisible={isVisible} ref={ref} />
    </Container>
  );
});

export default CommentsPanel;

export const graphqlURI = {
  development: "http://localhost:5000/graphql",
  local_staging: "http://localhost:5000/graphql",
  local_production: "http://localhost:5000/graphql",
  prod_dev: "https://content-builder-development.herokuapp.com/graphql",
  prod_staging: "https://content-builder-staging.herokuapp.com/graphql",
  production: "https://mlu-builder.mdhs.unimelb.edu.au/graphql",
};

export const redirectURI = {
  development: "http://localhost:3000/callback",
  local_staging: "http://localhost:3000/callback",
  local_production: "http://localhost:3000/callback",
  prod_dev: "https://content-builder-development.herokuapp.com/callback",
  prod_staging: "https://content-builder-staging.herokuapp.com/callback",
  production: "https://mlu-builder.mdhs.unimelb.edu.au/callback",
};

export const defaultURL = {
  development: "http://localhost:3000/",
  local_staging: "http://localhost:3000/",
  local_production: "http://localhost:3000/",
  prod_dev: "https://content-builder-development.herokuapp.com/",
  prod_staging: "https://content-builder-staging.herokuapp.com/",
  production: "https://mlu-builder.mdhs.unimelb.edu.au/",
};

export const imageUploadURL = {
  development: "http://localhost:5000/upload/images",
  local_staging: "http://localhost:5000/upload/images",
  local_production: "http://localhost:5000/upload/images",
  prod_dev: "https://content-builder-development.herokuapp.com/upload/images",
  prod_staging: "https://content-builder-staging.herokuapp.com/upload/images",
  production: "https://mlu-builder.mdhs.unimelb.edu.au/upload/images",
};

export const assessmentPortalURL = {
  development: "http://localhost:3003/",
  local_staging: "http://localhost:3003/",
  local_production: "http://localhost:3003/",
  prod_dev: "https://assessment-portal-development.herokuapp.com/",
  prod_staging: "https://assessment-portal-staging.herokuapp.com/",
  production: "https://mlu-portal.mdhs.unimelb.edu.au/",
};

export const teachersPortalURL = {
  development: "http://localhost:3002/",
  local_staging: "http://localhost:3002/",
  local_production: "http://localhost:3002/",
  prod_dev: "https://teachers-portal-development.herokuapp.com/",
  prod_staging: "https://teachers-portal-staging.herokuapp.com/",
  production: "https://mlu-reporting.mdhs.unimelb.edu.au/",
};

export const authDomain = {
  development: "shy-haze-1756.au.auth0.com",
  local_staging: "icy-bird-1457.au.auth0.com",
  local_production: "mlu-login.mdhs.unimelb.edu.au",
  prod_dev: "shy-haze-1756.au.auth0.com",
  prod_staging: "icy-bird-1457.au.auth0.com",
  production: "mlu-login.mdhs.unimelb.edu.au",
};

export const authAudience = {
  development: "shy-haze-1756.au.auth0.com",
  local_staging: "icy-bird-1457.au.auth0.com",
  local_production: "quiet-heart-5190.au.auth0.com",
  prod_dev: "shy-haze-1756.au.auth0.com",
  prod_staging: "icy-bird-1457.au.auth0.com",
  production: "quiet-heart-5190.au.auth0.com",
};

export const clientId = {
  development: "csyXqcZGkTqxHg97M9fytbXVI4sroB80",
  local_staging: "hSgwyPoAsFQExSGVmHoJTWuPeUP7745w",
  local_production: "ZqQ2qk5YZfe3weEqDxdl7HM0sWecD9xb",
  prod_dev: "csyXqcZGkTqxHg97M9fytbXVI4sroB80",
  prod_staging: "hSgwyPoAsFQExSGVmHoJTWuPeUP7745w",
  production: "ZqQ2qk5YZfe3weEqDxdl7HM0sWecD9xb",
};

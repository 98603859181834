import React, { useEffect, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { PageLayout } from "./styled";
import { USER_TYPES } from "./helpers";
import Loading from "../../presentational/Loading";
import { handleAuthentication, getUserType, login } from "../../../utils/authHelpers";

export const renderLoadingMenu = () => (
  <PageLayout style={{ backgroundColor: "white" }}>
    <Loading />
  </PageLayout>
);

const directUser = (userType) => {
  switch (userType) {
    case USER_TYPES.STUDENT:
      return <Redirect to="/" />; // TODO: Ideally, we redirect students to Assessment Portal
    case USER_TYPES.CONTRIBUTOR:
      return <Redirect to="/" />;
    case USER_TYPES.BOTH:
      // TODO potentially remove this and instead take user to app they were last in rather than having them choose
      // return <Redirect to="/appswitcher" />;
      return <Redirect to="/" />;
    default:
      return <Redirect to="/" />;
  }
};

const getCallbackLink = () => {
  const searchParams = new URL(window.location.href).searchParams;
  const callbackLink = searchParams.get("return");
  return callbackLink ? window.decodeURIComponent(callbackLink) : null;
};

const Callback = () => {
  const [userT, setUserT] = useState("");
  useEffect(() => {
    // Extract and store Id Token in Session Storage
    async function saveToken() {
      try {
        await handleAuthentication();
        setUserT(getUserType());
      } catch (error) {
        // if authentication fails we ask user to login again
        login();
      }
    }
    saveToken();
  }, []);

  const callbackLink = getCallbackLink();
  return !userT ? renderLoadingMenu() : callbackLink ? <Redirect to={callbackLink} /> : directUser(userT);
};
export default withRouter(Callback);

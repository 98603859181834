import styled from "styled-components";
import { Menu, Button } from "antd";
import COLORS from "../../../../constants/colors";

// Submenu for add component buttons
export const ButtonMenu = styled(Menu).attrs({
  mode: "vertical",
})`
  background: transparent;
  width: 100%;
  border: none;

  &&& li {
    padding: 0; 
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-menu-submenu-title {
      padding: 0;
      margin: 0;
      height: 100%;
      line-height: 100%;
      .ant-menu-submenu-arrow { display: none; } 
    }

    .anticon {
      margin-right: 0;
    }
  }
`;
export const ButtonItem = styled(Menu.Item)`
  &&& {
    background-color: ${COLORS.WHITE};
  }
`;
export const CallbackButton = styled(Button).attrs({ type: "link" })`
  color: ${COLORS.DARKGREY};
  :hover, :focus {
    color: ${COLORS.BLUE};
  }
`;

import styled from "styled-components";
import { Button } from "antd";

export const ExportButton = styled(Button)`
  margin-top: 20px;
`;

export const ExportButtonLoading = styled(ExportButton).attrs({
  loading: true,
})``;

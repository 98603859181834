import styled from "styled-components";

import { TABLE_OF_CONTENT_WIDTH, HEADER_HEIGHT } from "../../../constants/miscellaneous";

export const SidebarContainer = styled.div`
  z-index: 10000;
  position: sticky;
  position: -webkit-sticky;
  ${({ viewHeight }) => (!viewHeight ? "" : `height: ${viewHeight}px`)};
  ${({ viewHeight }) => (!viewHeight ? "" : `max-height: ${viewHeight}px`)};
  background: #f7f7f7;
  ${({ collapsedMenu }) => (!collapsedMenu ? "width: 0px" : `width: ${TABLE_OF_CONTENT_WIDTH}px`)};
  display: block;
  transition: display 2s;
  overflow: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: width 0.1s;
  /* width: ${TABLE_OF_CONTENT_WIDTH}px;
  min-width: ${TABLE_OF_CONTENT_WIDTH}px; */
  /* display: ${({ collapsedMenu }) => (!collapsedMenu ? "none" : "block")}; */
`;

export const ErrorMessage = styled.div`
  margin-top: ${HEADER_HEIGHT}px;
  text-align: center;
`;

import React from "react";
import {
  VersionDisplayContainer,
  VersionTitle,
  VersionAuthor,
  VersionTimestamp,
  VersionInfo,
  VersionMenu,
  VersionMenuButton,
  VersionContainer,
} from "./styled";

const NoVersionDisplay = () => {
  return (
    <VersionContainer>
      <VersionDisplayContainer>
        <VersionInfo>
          <VersionTitle>None</VersionTitle>
        </VersionInfo>
      </VersionDisplayContainer>
    </VersionContainer>
  );
};

export default NoVersionDisplay;

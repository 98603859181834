import React, { memo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { getItemStyle } from "./helper";

const DraggableItem = ({ draggableId, index = 0, children, itemStyleGetter, dragHandle }) => {
  const deriveItemStyle = itemStyleGetter || getItemStyle;
  const Handle = dragHandle;
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => {
        const draggableDragHandleProps = !dragHandle ? provided.dragHandleProps : {};
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...draggableDragHandleProps}
            style={{
              ...deriveItemStyle(snapshot.isDragging, provided.draggableProps.style),
            }}
          >
            {dragHandle && (
              <div>
                <Handle {...provided.dragHandleProps} />
              </div>
            )}
            <div className="draggableItemChildren" style={{width:"100%"}}>{children}</div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default memo(DraggableItem);

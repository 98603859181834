import isString from "lodash/isString";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import isNumber from "lodash/isNumber";
import isFunction from "lodash/isFunction";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";

const raiseErrorIfNotOfType = (checkType, errMsg) => (input) => {
  if (!checkType(input)) {
    throw TypeError(`${errMsg}, it was found to be ${typeof input}.`);
  }
  return true;
};

const raiseErrorIfOfType = (checkType, errMsg) => (input) => {
  if (checkType(input)) {
    throw TypeError(`${errMsg}.`);
  }
  return true;
};

export const raiseErrorIfNotArray = (input) => raiseErrorIfNotOfType(isArray, "Type needs to be a array")(input);
export const raiseErrorIfNotObject = (input) => raiseErrorIfNotOfType(isObject, "Type needs to be a object")(input);
export const raiseErrorIfNotString = (input) => raiseErrorIfNotOfType(isString, "Type needs to be a string")(input);
export const raiseErrorIfNotNumber = (input) => raiseErrorIfNotOfType(isNumber, "Type needs to be a number")(input);
export const raiseErrorIfNotFunction = (input) => raiseErrorIfNotOfType(isFunction, "Type needs to be a function")(input);
export const raiseErrorIfNull = (input) => raiseErrorIfOfType(isNull, "Value needs to be defined")(input);
export const raiseErrorIfEmpty = (input) => raiseErrorIfOfType(isEmpty, "Value needs to be defined")(input);

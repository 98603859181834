import gql from "graphql-tag";

export const FILE_UPLOAD_MUTATION = gql`
  mutation uploadFiles($learningUnitId: String, $tutorialId: String, $files: [FileUploadInput]!) {
    uploadFiles(learningUnitId: $learningUnitId, tutorialId: $tutorialId, files: $files) {
      _id
      filename
      url
      thumbnailUrl
      type
      description
      height
      width
      autoPlay
      autoLoop
      size
    }
  }
`
import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_SUBJECT_BUILDER_MANIFEST } from "./queries";
import Loading from "../../presentational/Loading";
import { Container } from "../../../utils/globalStyled";
import Builder from "./Builder";
import { extractMessageFromGraphQLError } from "../../../utils/helpers";

const SubjectBuilder = ({ subjectId }) => {
  const { loading, error, data } = useQuery(GET_SUBJECT_BUILDER_MANIFEST, {
    variables: {
      subjectId,
    },
    fetchPolicy: "no-cache",
  });

  if (error)
    return (
      <Container>
        <div>Error: {extractMessageFromGraphQLError(error)}</div>
      </Container>
    );

  if (loading)
    return (
      <Container>
        <Loading />
      </Container>
    );

  if (data) {
    const { course, subject, tutorialsWithLearningUnits, templates } = data.getSubjectBuilderManifest;
    const orderedTemplates = templates.sort((a, b) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });

    return <Builder course={course} subject={subject} tutorials={tutorialsWithLearningUnits} templates={orderedTemplates} />;
  }
  return null;
};

export default SubjectBuilder;

import gql from "graphql-tag";

export const GET_PUBLISHED_SUBJECT_WITH_TUTORIAL_WITH_LEARNING_UNITS = gql`
  query getPublishedSubjectWithTutorialWithLearningUnits($subjectId: String!) {
    getPublishedSubjectWithTutorialWithLearningUnits(subjectId: $subjectId) {
      subject {
        _id
        name
      }
      tutorialsWithPublishedLearningUnits {
        _id
        name
        subjectTutorials {
          _id
          order
          displayName
        }
        learningUnits {
          _id
          name
          order
          isArchived
          isSubmittedForReview
          subjectAssessments {
            _id
            name
            displayName
          }
          learningUnitComponents {
            _id
            assessmentStyle
            displayStyle
            html
            fileList
            fileName
            isArchived
            position
            type
            title
            styleClass
          }
        }
      }
    }
  }
`;

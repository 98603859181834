import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import { Caption, CaptionText, CaptionToggle } from "./styled";

/**
 * @description Represents a media item's caption
 * @param {string} caption - The caption text
 */
const MediaCaption = ({ caption }) => {
  // Local state to show/hide the full caption
  const [showFullCaption, setShowFullCaption] = useState(false);

  // Toggle to show/hide the full caption
  const toggleShowFullCaption = () => {
    setShowFullCaption(!showFullCaption);
  };

  // Render function which determines the caption toggle icon
  const renderCaptionToggle = () => (!showFullCaption ? <Icon type="up" /> : <Icon type="down" />);

  // Capture the long caption
  const longCaption = !caption ? "" : caption;
  // Determine if the caption is a short caption
  const shortCaptionLength = 100;
  const isShortCaption = longCaption.length > shortCaptionLength;
  const shortCaption = isShortCaption ? `${longCaption.substring(0, shortCaptionLength)}...` : longCaption;
  // Determine which caption will be displayed based on toggle
  const displayedCaption = showFullCaption ? longCaption : shortCaption;

  // Render
  return (
    <Caption onClick={toggleShowFullCaption}>
      <CaptionText className="mediaCaptionText" aria-label="media-caption-text">
        {displayedCaption}
      </CaptionText>
      {isShortCaption && (
        <CaptionToggle className="mediaCaptionToggle" aria-label="media-caption-toggle">
          {renderCaptionToggle()}
        </CaptionToggle>
      )}
    </Caption>
  );
};

MediaCaption.propTypes = {
  caption: PropTypes.string.isRequired,
};

export default MediaCaption;

import { ADD_FILE, REMOVE_FILE, UPDATE_FILE, REORDER_FILE } from "./actions";

/**
 * @description Adds a file to the local state fileList
 * @param {object} list - List of files
 * @param {object} newFiles - New files
 * @param {function} commitChanges - Function to handle the Save outside of the local state
 */
const addFile = (list, newFiles, commitChanges) => {
  const files = list.push(...newFiles);
  commitChanges(files);
  return files;
};

/**
 * @description Updates a file in the local state fileList
 * @param {object} list - List of files
 * @param {number} fileIndex - Index of the file to update
 * @param {object} fileMetadataFields - Fields to update (e.g. { description: 'Hello' } )
 * @param {function} commitChanges - Function to handle the Save outside of the local state
 */
const updateFile = (list, fileIndex, fileMetadataFields, commitChanges) => {
  const files = list.update(fileIndex, (file) => ({
    ...file,
    ...fileMetadataFields
  }));
  commitChanges(files);
  return files;
};

/**
 * @description Reorder the file in the local state fileList
 * @param {object} list - List of files
 * @param {number} startIndex - Source index of the file's original position
 * @param {number} endIndex - Targert index of the file's new position
 * @param {function} commitChanges - Function to handle the Save outside of the local state
 */
const reorderFile = (list, startIndex, endIndex, commitChanges) => {
  const fileAtStartIndex = list.get(startIndex);
  const files = list.delete(startIndex).insert(endIndex, fileAtStartIndex);
  commitChanges(files);
  return files;
};

/**
 * @description Remove a file from the local state fileList
 * @param {object} list - List of files
 * @param {number} fileIndex - Index of the file to delete
 * @param {function} commitChanges - Function to handle the Save outside of the local state
 */
const removeFile = (list, fileIndex, commitChanges) => {
  const files = list.delete(fileIndex);
  commitChanges(files);
  return files;
};

/**
 * @description MediaCarouselBulder reducer
 * @param {object} state - State containing the fileList, lucId and commitChanges function
 * @param {object} action - Local actions (e.g. ADD_FILE, UPDATE_FILE, REORDER_FILE, REMOVE_FILE)
 */
const reducer = (state, action) => {
  switch (action.type) {
    case ADD_FILE:
      return {
        ...state,
        fileList: addFile(state.fileList, action.newFiles, state.commitChanges),
      };
    case UPDATE_FILE:
      return {
        ...state,
        fileList: updateFile(state.fileList, action.fileIndex, action.fileMetadataFields, state.commitChanges),
      };
    case REORDER_FILE:
      return {
        ...state,
        fileList: reorderFile(state.fileList, action.sourceIndex, action.destinationIndex, state.commitChanges),
      };
    case REMOVE_FILE:
      return {
        ...state,
        fileList: removeFile(state.fileList, action.fileIndex, state.commitChanges),
      };
    default:
      throw new Error();
  }
};

export default reducer;

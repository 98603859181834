export const HEADER_HEIGHT = 50;
export const FOOTER_HEIGHT = 40;
export const TABLE_OF_CONTENT_WIDTH = 300;
export const PLACEHOLDER_LU_COMPONENT_HEIGHT = 100;
export const PLACEHOLDER_QUESTION_COMPONENT_HEIGHT = 100;
export const PLACEHOLDER_ANSWER_COMPONENT_HEIGHT = 40;
export const STICKY_MENU_WIDTH = 50;
export const STICKY_MENU_WIDTH_WITH_MARGIN = 60;
export const DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS = 200;

export const EDITOR_LENGTH = {
  HTML: 32768,
  DEFAULT: 4000,
  BUFFER: 300,
  SURVEY_OPTION: 27, // 20 characters option text + 7 characters of html tag "<p></p>"
  SURVEY_QUESTION: 600,
};



import React, {useState, useEffect, useReducer} from "react"
import { Content } from "../styled";
import { getUploadInfo } from "./helpers";
import { List } from "immutable";
import reducer from "./reducer";
import { FILE_ATTACHMENT_UPLOAD_MUTATION } from "./queries";
import { parseIfJSON, extractMessageFromGraphQLError } from "../../../../utils/helpers";
import { useMutation } from "@apollo/react-hooks";
import { addFile, updateFile, reorderFile, removeFile } from "./actions";
import {
  BuilderContainer,
  FileInput,
  FileInputIcon,
  FileInputLabel,
  FileUploadContainer,
  FileUploadErrorMessage,
  IconAndTextContainer,
  UploadIcon,
  MediaEditorContainer,
  MediaEditorFormContainer,
  RemoveIcon,
  DraggableItemContainer,
} from "../MediaCarouselBuilder/styled";
import PropTypes from "prop-types";
import {IconContainer, FieldsContainer, MediaEditorForm, StyledInputText} from "./styled";
import DroppablePalette from "../../../presentational/DroppablePalette";
import DraggableItem from "../../../presentational/DraggableItem";
import DragHandle from "../../../presentational/DragHandle";
import { getIcon } from "./helpers";


/**
 * @description Initialise the reducer
 * @param {object} initialValues - Initial Values
 */
 const init = (initialValues) => {
  // Ensure that the file list is immutable
  const immutableFileList = Array.isArray(parseIfJSON(initialValues.fileList))
    ? List(parseIfJSON(initialValues.fileList))
    : initialValues.fileList;

    const immutableFileNameList = Array.isArray(parseIfJSON(initialValues.fileName))
    ? List(parseIfJSON(initialValues.fileName))
    : initialValues.fileName;

  return {
    fileList: immutableFileList,
    fileName: immutableFileNameList,
    lucId: initialValues.lucId,
    commitChanges: initialValues.commitChanges,
  };
};


const isTestRunning = process.env.NODE_ENV === "test" && process.env.JEST_WORKER_ID !== undefined;

 
/**
 * @description Media Editor for each file on the list
 * @param {string} mediaId - The file id
 * @param {object} file - File metadata
 * @param {function} localDispatch - Dispatches a reducer action
 * @param {number} fileIndex - A file's index on the list
 */

const MediaEditor = ({ mediaId, file, localDispatch, fileIndex }) => {
  return (
    <MediaEditorFormContainer key={mediaId} className="mediaEditorItem">
      <MediaEditorForm>
        <IconContainer key={`${file._id}.thumbnail`}>
        {getIcon(file.type)}
        </IconContainer>

        <FieldsContainer>
          <StyledInputText
            className="mediaEditorItemDescription"
            maxLength={1000}
            required
            value={file.filename}
            onChange={(event) =>{
              localDispatch(
                updateFile(fileIndex, {
                  filename: event.target.value
                })
              )
            }
             }></StyledInputText>
         </FieldsContainer>
      </MediaEditorForm>
      <RemoveIcon onClick={() => {
        localDispatch(removeFile(fileIndex))
      }} className="mediaEditorItemRemoveIcon" />
    </MediaEditorFormContainer>
  );
};


/**
 * 
 * @description The main component for the file upload builder
 * @param {function} onContentChange - Handles the change action
 *  
 * */
const FileUploadBuilder = ({ learningUnitId, tutorialId, media, onContentChange }) => {

    const [fileUploadError, setFileUploadError] = useState("");

    const fileList = !!media && media.fileList;
    const fileName = !!media && media.fileName;
    const mediaId = !!media && media._id;
    
    const [localState, localDispatch] = useReducer(
      reducer,
      { fileList, fileName, lucId: mediaId, commitChanges: onContentChange },
      init,
    );
    const [uploadFileAttachments, { loading, called, error, data }] = useMutation(FILE_ATTACHMENT_UPLOAD_MUTATION);

    useEffect(() => {
      if (!!error) {
        setFileUploadError("Your request has timed out. Please check your internet connection or upload a smaller file. ");
      }
    }, [error])

      /**
   * @description Handle the event when files have been selected
   * @param {object} validity - Object which contains validity info about the files
   * @param {object} files - Object which contains the list of files selected
   */
    const onChangeFiles = async ({ target: { validity, files } }) => {

      if (files) {
        // Reset error message
        setFileUploadError("");
        // Make files immutable
        const filesToUpload = List(files);
        try {
          const filesWithInfo = await Promise.all(filesToUpload.map((file) => getUploadInfo(file)));
          // Check if the files are valid
          if (!!filesWithInfo && (validity.valid || isTestRunning)) {
            try {
              const result = await uploadFileAttachments({ variables: { files: filesWithInfo, learningUnitId, tutorialId } });
              const newContent = List(result.data.uploadFiles);
              localDispatch(addFile(newContent, newContent.filename));
            } catch (graphQLError) {
              setFileUploadError(extractMessageFromGraphQLError(graphQLError));
            }
          }
        } catch (uploadInfoError) {
          setFileUploadError(uploadInfoError.message);
        }
      }
    };


  /**
   * @description Handle drag end
   * @param {*} result
   */
    
    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) return;
      localDispatch(reorderFile(result.source.index, result.destination.index));
    };
  

    return(
    <Content>
        <BuilderContainer>
            <FileUploadContainer>
        {loading ? ( // Loading state
            <IconAndTextContainer>
              <UploadIcon />
              <div>Uploading new files...</div>
            </IconAndTextContainer>
          ) : (
            // Render file input
            <FileInputLabel htmlFor={123}>
              <FileInput
                id={123}
                className="fileUploadField"
                multiple
                required
                onChange={onChangeFiles}
              />
              <IconAndTextContainer>
               <FileInputIcon />
                <div>Add or Drop Files</div>
              </IconAndTextContainer>
            </FileInputLabel>
          )}
          {fileUploadError && <FileUploadErrorMessage className="fileUploadErrorMsg">{fileUploadError}</FileUploadErrorMessage>}
          </FileUploadContainer>

          <MediaEditorContainer className="mediaEditorContainer">
          <DroppablePalette onDragEnd={onDragEnd}>
            {!!localState.fileList &&
            localState.fileList.map((file, index) => (
                <DraggableItemContainer key={file._id}>
                  <DraggableItem draggableId={file._id} index={index} dragHandle={DragHandle}>
                    <MediaEditor mediaId={mediaId} file={file} localDispatch={localDispatch} fileIndex={index}/>
                  </DraggableItem>
                </DraggableItemContainer>
              ))}
          </DroppablePalette>
        </MediaEditorContainer>

          </BuilderContainer>
  </Content>
    )
  };

  FileUploadBuilder.propTypes = {
    learningUnitId: PropTypes.string.isRequired,
    tutorialId: PropTypes.string.isRequired,
    media: PropTypes.shape({
      _id:PropTypes.string.isRequired,
      fileName:PropTypes.object.isRequired,
      fileList:PropTypes.object.isRequired,
    }).isRequired,
    onContentChange: PropTypes.func.isRequired,
  };


  

export default FileUploadBuilder;

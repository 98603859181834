import React from "react";
import { LoadingContainer, LoadingSpinner } from "./styled";

const Loading = () => (
  <LoadingContainer>
    <LoadingSpinner />
  </LoadingContainer>
);

export default Loading;

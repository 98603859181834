import gql from "graphql-tag";
export const FILE_ATTACHMENT_UPLOAD_MUTATION = gql`
  mutation uploadFileAttachments($learningUnitId: String, $tutorialId: String, $files: [FileUploadInput]!) {
    uploadFiles(learningUnitId: $learningUnitId, tutorialId: $tutorialId, files: $files) {
      _id
      filename
      url
      type
      size
    }
  }
`
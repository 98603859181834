import styled from "styled-components";
import { Layout, Icon, Dropdown, Menu, message, Col } from "antd";
import { UpToMobileWidth } from "../../../constants/mediaQueries";
import COLORS from "../../../constants/colors";

const { Content } = Layout;

export const StyledContentLayout = styled(Content)`
  height: 100%;
  padding: 5px;
  @media only screen and ${UpToMobileWidth} {
    padding: 0px 0px 50px 0px;
  }
`;

export const StyledSubjectViewContainer = styled.div`
  height: 100%;
  min-height: 100vh;
`;

export const Title = styled.h2`
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.95em;
  font-weight: 400;
  margin-bottom: 1px;
`;

export const Subtitle = styled.h5`
  color: ${COLORS.GRAY};
  font-weight: 600;
  font-size: 0.85em;
  letter-spacing: 0.3px;
  margin-bottom: 0;
`;

export const TitleContainer = styled(Col).attrs({
  xs: { span: 24, order: 2 },
  sm: { span: 24, order: 2 },
  md: { span: 16, order: 1 },
  lg: { span: 16, order: 1 },
  xl: { span: 12, order: 1 },
})``;

// .div`
//   max-width: 100%;
//   padding: 15px 15px 0px 10px;
//   margin-top: 20px;
//   @media only screen and ${uptoMobileWidth} {
//     padding: 15px 5px 0px 5px;
//   }
// `;

export const DownArrow = styled(Icon).attrs({ type: "down" })`
  font-size: 1em;
  padding-right: 10px;
  color: ${COLORS.BLACK};
  font-weight: 900;
`;

export const StyledDropdown = styled(Dropdown).attrs({ trigger: ["click"] })``;

export const StyledMenu = styled(Menu)``;
export const StyledMenuItem = styled(Menu.Item)`
  &&& {
    max-width: 50vw;
    white-space: normal;
  }
  @media only screen and ${UpToMobileWidth} {
    &&& {
      min-width: 93.5vw;
    }
  }
`;
export const MenuItemText = styled.div`
  padding: 5px 12px;
  font-size: 1.4em;
  font-weight: 500;
`;

export const DropdownTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

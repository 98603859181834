import React from "react";
import Snippet from "../../../presentational/Snippet";
import { SnippetContainer } from "./styled";
import EnhancedEditorImages from "../../../presentational/EnhancedEditorImages";

const SnippetPreview = ({ html, styleClass, pageURL }) => {
  return (
    <SnippetContainer>
      <EnhancedEditorImages>
        <Snippet html={html} styleClass={styleClass} pageURL={pageURL} />
      </EnhancedEditorImages>
    </SnippetContainer>
  );
};

export default SnippetPreview;

import gql from "graphql-tag";

export const GET_COURSES = gql`
  query {
    getCoursesWithSubjects {
      _id
      name
      affiliation
      description
      cardCover
      subjects {
        _id
        name
        course
        description
        numTutorials
        tutorials {
          _id
          name
        }
      }
    }
  }
`;

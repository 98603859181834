import ContextBase from "@ckeditor/ckeditor5-core/src/context";
import CommentsRepository from "@ckeditor/ckeditor5-comments/src/comments/commentsrepository";
import WideSidebar from "@ckeditor/ckeditor5-comments/src/annotations/widesidebar";

class Context extends ContextBase {}

export const createContext = ({ sidebarElement }) => {
  if (!sidebarElement) {
    return null;
  }

  // Plugins to include in the context.
  Context.builtinPlugins = [
    CommentsRepository, WideSidebar
  ];

  Context.defaultConfig = {
    // License Key
    licenseKey: "sOs3x0G68DpK1FTT5vfCyjJ2wLqWUfdQTdSRUYd3I1gTW7mk",
    sidebar: {
      container: sidebarElement,
    },
  };

  return Context;
};

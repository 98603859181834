import React from "react";
import Editor from "../../Editor";
import { EditorContainer } from "./styled";
import { EDITOR_LENGTH } from "../../../../constants/miscellaneous";

/**
 * A case study snippet type learning unit component
 * 
 * @param {string} learningUnitId - _id of learningUnit
 * @param {string} initialContent - input value
 * @param {function} onContentChange - function that handles content changes
 */
const CaseStudySolutionSnippetBuilder = ({ learningUnitId, initialContent, onContentChange }) => (
  <EditorContainer>
    <Editor maxLength={EDITOR_LENGTH.HTML} learningUnitId={learningUnitId} content={initialContent} onChange={onContentChange} inline />
  </EditorContainer>
);

export default CaseStudySolutionSnippetBuilder;

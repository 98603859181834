import React, { useEffect, useContext } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Builder from "./Builder";
import Loading from "../../presentational/Loading";
import { Container } from "../../../utils/globalStyled";
import { RESOLVE_SNAPSHOT_AND_GET_MANIFEST } from "./mutations";
import { extractMessageFromGraphQLError } from "../../../utils/helpers";
import CollaborationContext from "../CollaborationContext";
import { AUTOSAVE_STATUS } from "../../../constants/types";
import { useSaveStatusContext } from "./LearningUnitSaveStatusContext";

const LearningUnitBuilder = ({
  collapsedMenu,
  learningUnitId,
  subjectId,
  tutorialId,
  version,
  lus = [],
  refetchToC,
  setLastModifiedBy = () => {},
  refetchVersionMenu,
  showComments,
  toggleShowComments,
  setSelectedVersion,
}) => {
  const { saveStatus, dispatchSaveStatus } = useSaveStatusContext();
  const [upsertSnapshotAndGetManifest, { loading, called, error, data }] = useMutation(RESOLVE_SNAPSHOT_AND_GET_MANIFEST, {
    onCompleted: (manifest) => {
      setLastModifiedBy({
        name: get(manifest, "upsertSnapshotAndGetManifest.lastModifiedByContact.name", ""),
        timestamp: get(manifest, "upsertSnapshotAndGetManifest.lastSnapshotSaveTimeStamp", ""),
      });
      const saveS = get(manifest, "upsertSnapshotAndGetManifest.saveStatus", "");
      dispatchSaveStatus(saveS);
    },
  });

  // const { data: usersData } = useQuery(GET_USERS);

  useEffect(() => {
    upsertSnapshotAndGetManifest({
      variables: {
        learningUnitId,
        tutorialId,
        version,
        subjectId
      },
    });
  }, [learningUnitId, version]);

  const learningUnit = data && data.upsertSnapshotAndGetManifest;

  if (error)
    return (
      <Container>
        <div>Error: {extractMessageFromGraphQLError(error)}</div>
      </Container>
    );
  if (loading)
    return (
      <Container>
        <Loading />
      </Container>
    );
  if (data)
    return (
      <CollaborationContext.Provider>
        <Builder
          learningUnit={learningUnit}
          learningUnitId={learningUnitId}
          tutorialId={tutorialId}
          subjectId={subjectId}
          collapsedMenu={collapsedMenu}
          refetchToC={refetchToC}
          refetchVersionMenu={refetchVersionMenu}
          version={version}
          setLastModifiedBy={setLastModifiedBy}
          setSelectedVersion={setSelectedVersion}
          showComments={showComments}
          toggleShowComments={toggleShowComments}
        />
      </CollaborationContext.Provider>
    );
  return null;
};

LearningUnitBuilder.propTypes = {
  collapsedMenu: PropTypes.bool.isRequired,
  learningUnitId: PropTypes.string.isRequired,
  tutorialId: PropTypes.string.isRequired,
};

export default LearningUnitBuilder;

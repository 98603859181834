import gql from "graphql-tag";

export const REMOVE_LEARNING_UNIT_FROM_TUTORIAL = gql`
  mutation removeLearningUnitFromTutorial($tutorialId: String!, $learningUnitSyncId: String!) {
    removeLearningUnitFromTutorial(tutorialId: $tutorialId, learningUnitSyncId: $learningUnitSyncId)
  }
`;

export const UPDATE_LEARNING_UNIT_ORDER = gql`
  mutation updateLearningUnitOrder($tutorialId: String!, $learningUnitsWithOrder: [LearningUnitIdsWithOrder]) {
    updateLearningUnitOrder(tutorialId: $tutorialId, learningUnitsWithOrder: $learningUnitsWithOrder) {
      _id
      learningUnitSyncId
      tutorial
      order
    }
  }
`;

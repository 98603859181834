
import gql from "graphql-tag";

/**
 * @description Retrieves the table of contents by Subject ID
 */
export const GET_USERS = gql`
  query {
    getUsers {
      _id
      name
    }
  }
`;

export const GET_USER_ID = gql`
  query {
    getUserId
  }
`;
// AddNew
import styled from "styled-components";
import { Icon, Button, Input, Radio } from "antd";
import COLORS from "../../../../constants/colors";
import { LearningUnitLink } from "../Builder/styled";

export const AddNewRow = styled(LearningUnitLink).attrs({
  underline: true,
})`
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${COLORS.BLUE_FADED};
  color: ${COLORS.BLUE};
  cursor: pointer;
`;

export const AddNewIcon = styled(Icon).attrs({
  type: "plus",
})`
  margin-left: 35px;
  margin-right: 10px;
`;

export const AddNewInput = styled(Input).attrs({
  type: "text",
})`
  margin-top: 20px;
  box-shadow: none;
  width: 100%;
`;

export const AddNewInputContainer = styled.div`
  position: relative;
  padding: 0 10px;
`;

export const CharCount = styled.span`
  position: absolute;
  right: 10px;
  top: 25%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #999;
`;

export const AddNewButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const AddNewTemplateContainer = styled.div`
  margin: 20px 5px;
`;

export const AddNewSectionContainer = styled.div`
  padding: 20px;
`;

export const AddButton = styled(Button).attrs({ type: "primary" })`
  width: 150px;
  margin-left: 20px;
`;
export const CancelButton = styled(Button)`
  width: 150px;
  margin-right: 20px;
`;

export const TemplateRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-wrap: wrap;
  flex: 4;
  justify-content: "flex-start";
  &&& {
    .ant-radio-button-wrapper {
      line-height: 1.2rem;
    }
  }
`;

export const TemplateRadioButton = styled(Radio.Button)`
  padding: 5px;
  font-size: 1rem;
  text-align: center;
  display: flex;
  flex: 1;
  height: 180px;
  width: 120px;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  margin: 5px;
`;

export const AddNewLoadingIcon = styled(Icon).attrs({
  type: "loading",
})`
  margin-left: 35px;
  margin-right: 10px;
`;

import { flow, max, isArray } from "lodash/fp";

/**
 * @description Calculate the aspect ratio based on formula:
 * - If the original height is greater than original width, divide the original width by original height
 * - Otherwise, if the original width is greater than original height, divide the original height by original width
 * @param {number} height
 * @param {number} width
 */
export const calculateAspectRatio = (height, width) => {
  // Parse height and width. Ensure they are greater than 0.
  const floatHeight = parseFloat(height);
  const floatWidth = parseFloat(width);
  if (floatHeight <= 0 || floatWidth <= 0) {
    return 0;
  }
  // Ensure that we do not divide by 0
  const originalHeight = Number.isNaN(floatHeight) ? 0 : floatHeight;
  const originalWidth = Number.isNaN(floatWidth) ? 0 : floatWidth;
  if (!originalHeight && !originalWidth) {
    return 0;
  }
  // Calculate the aspect ratio
  const aspectRatio = originalWidth > originalHeight ? originalWidth / originalHeight : originalHeight / originalWidth;
  return aspectRatio;
};

/**
 * @description Calculate the content width based on its original content width and a set max width
 * @param {number} originalContentWidth - Given content width
 * @param {number} maxWidth - Given width upper limit
 */
export const getWidth = (originalContentWidth, maxWidth) => {
  const floatWidth = parseFloat(originalContentWidth);
  const originalWidth = Number.isNaN(floatWidth) ? 0 : floatWidth;
  return originalWidth > maxWidth ? maxWidth : originalWidth;
};

/**
 * @description Calculate the content height based on content width and aspect ratio
 * @param {number} contentWidth - Given content width
 * @param {number} aspectRatio - Aspect ratio
 */
export const calculateContentHeight = (contentWidth, aspectRatio) => (!aspectRatio ? 0 : contentWidth / aspectRatio);

/**
 * @description Calculate the content width based on content height and aspect ratio
 * @param {number} contentHeight - Given content height
 * @param {number} aspectRatio - Aspect ratio
 * @param {string} orientation - Image orientation (e.g. portrait, landscape)
 */
export const calculateContentWidth = (contentHeight, aspectRatio, orientation = "landscape") => {
  switch (orientation) {
    case "landscape":
      return !aspectRatio ? 0 : contentHeight * aspectRatio;
    case "portrait":
      return !aspectRatio ? 0 : contentHeight / aspectRatio;
    default:
      return 0;
  }
};

/**
 * @description Calculate all content heights using aspect ratio
 * @param {object} items - Object w/ width and height properties
 */
export const getHeights = (items) =>
  !items || !isArray(items)
    ? []
    : items.map((item) => {
        const contentWidth = getWidth(item.width);
        const aspectRatio = calculateAspectRatio(item.width, item.height);
        const adjustedContentHeight = calculateContentHeight(contentWidth, aspectRatio);
        return adjustedContentHeight;
      });
// const getWidths = items => items.map(item => parseFloat(item.width));

/**
 * @description Calculate the max height from all heights
 */
export const calculateMaxHeight = flow(getHeights, max);

/**
 * @description Calculate the media item's max parent container height
 * @param {object} contents - List with a height property
 */
export const calculateContainerHeight = (contents) => {
  const maxHeight = calculateMaxHeight(contents);
  const heightLimit = 450;
  const containerHeight = maxHeight > heightLimit ? heightLimit : maxHeight;
  return containerHeight;
};

/**
 * @description Calculate the dimensions (both height and width) of a media item
 * @param {object} content - The media item with a height and width property
 * @param {number} parentWidth - The width of media item's parent container
 * @param {number} parentHeight - The height of the media item's parent container
 */
export const calculateContentDimensions = (content, parentWidth, parentHeight) => {
  // The parent width minus adjustment for margins/arrow controls
  const maxContentWidth = parentWidth - 80;
  // Get the width and limit it to the max content width if it goes beyond it
  const contentWidth = getWidth(content.width, maxContentWidth);
  // Determine if the image needs adjustment
  const needsAdjustment = content.height > parentHeight || content.width > parentWidth;
  // Calculate the aspect ratio based on Original Width and Original Height
  const aspectRatio = calculateAspectRatio(content.width, content.height);
  // Calculate the orientation
  const orientation = content.height > content.width ? "portrait" : "landscape";
  // Calculate the adjust content height based on width and aspect ratio
  const adjustedContentHeight = needsAdjustment ? calculateContentHeight(contentWidth, aspectRatio) : content.height;
  // Calculate the adjust content width based on height and aspect ratio
  const adjustedContentWidth = needsAdjustment ? calculateContentWidth(adjustedContentHeight, aspectRatio, orientation) : contentWidth;
  // If height goes beyond parent container, use parent height
  const finalContentHeight = adjustedContentHeight > parentHeight ? parentHeight : adjustedContentHeight;
  // If height goes beyond parent container, recalculate width based on parent height
  const finalContentWidth =
    adjustedContentHeight > parentHeight ? calculateContentWidth(parentHeight, aspectRatio, orientation) : adjustedContentWidth;
  return {
    contentHeight: finalContentHeight,
    contentWidth: finalContentWidth,
  };
};

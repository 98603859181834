export const initialiseUsers = (editor, { userId, users }) => {
  // Build user data structure
  const allUsers = !users
    ? []
    : users.map((user) => {
        return {
          id: user._id,
          name: user.name,
          // Note that the avatar is optional.
          // avatar: "https://randomuser.me/api/portraits/thumb/men/26.jpg",
        };
      });

  if (!!allUsers) {
    // Initialise users plugin
    const usersPlugin = editor.plugins.get("Users");

    // Add each user
    allUsers.map((user) => {
      usersPlugin.addUser(user);
    });

    // Set current user
    const userFoundInUsers = allUsers.filter((user) => user.id === userId);
    if (!!userFoundInUsers) {
      usersPlugin.defineMe(userId);
    }
  }
};

const initialiseAnnotations = (editor, { sidebarElement }) => {
  const annotations = editor.plugins.get("Annotations");
  editor.plugins.get("Sidebar").setContainer(sidebarElement);

  if (window.innerWidth < 1300) {
    sidebarElement.classList.remove("narrow");
    sidebarElement.classList.add("hidden");
    annotations.switchTo("inline");
  }
  // else if ( window.innerWidth < 1300 ) {
  //   sidebarElement.classList.remove( 'hidden' );
  //   sidebarElement.classList.add( 'narrow' );
  //   annotations.switchTo( 'narrowSidebar' );
  // }
  else {
    sidebarElement.classList.remove("hidden", "narrow");
    annotations.switchTo("wideSidebar");
  }
};

const initialiseSidebarToggle = (editor, { onEditorCommentToolbarClick }) => {
  // When the comment toolbar button is clicked, open the CommentsPanel
  const addCommentThread = editor.commands.get("addCommentThread");
  addCommentThread.on("execute", () => {
    onEditorCommentToolbarClick();
  });

  // When the track changes toolbar button is clicked, open the CommentsPanel
  const trackChanges = editor.commands.get("trackChanges");
  trackChanges.on("execute", () => {
    onEditorCommentToolbarClick();
  });
};

export const initialiseComments = (editor, { comments, target }) => {
  const commentsRepository = editor.plugins.get("CommentsRepository");
  const commentThreads = !comments ? [] : JSON.parse(comments);
  if (!!commentThreads) {
    // Load the comment threads data.
    commentThreads.map((commentThread) => {
      if (!target) {
        commentsRepository.addCommentThread(commentThread);
      } else {
        commentsRepository.addCommentThread({ ...commentThread, target });
      }
    });
  }
  return commentsRepository;
};

const initialiseSuggetions = (editor, { suggestions }) => {
  const suggestionsArray = !suggestions ? [] : JSON.parse(suggestions);
  if (!!suggestionsArray) {
    const trackChanges = editor.plugins.get("TrackChanges");
    // Load the comment threads data.
    suggestionsArray.map((suggestion) => {
      trackChanges.addSuggestion(suggestion);
    });
  }
};

export const handleCollaborationInit = (editor, { sidebarElement, onEditorCommentToolbarClick, userId, users, comments, suggestions }) => {
  // Initialise users
  initialiseUsers(editor, { userId, users });

  // Initialise comments
  initialiseComments(editor, { comments });

  // Initialise suggestions
  initialiseSuggetions(editor, { suggestions });

  // Initialise annotations
  initialiseAnnotations(editor, { sidebarElement });

  // Initialise sidebar toggle
  initialiseSidebarToggle(editor, { onEditorCommentToolbarClick });
};

/**
 * @description Get comments data from the editor
 * @param {*} editor - CKEditor
 */
export const getCommentThreads = (editor) => {
  const commentsRepository = editor.plugins.get("CommentsRepository");
  const commentThreadsData = commentsRepository.getCommentThreads({
    skipNotAttached: true,
    skipEmpty: true,
  });
  const commentThreads = !commentThreadsData
    ? []
    : commentThreadsData.map((commentThread) => {
        const comments = commentThread.comments.map((comment) => {
          return {
            commentId: comment.id,
            authorId: comment.author.id,
            content: comment.content,
            createdAt: comment.createdAt,
          };
        });

        return {
          threadId: commentThread.id,
          comments,
        };
      });

  return commentThreads;
};

/**
 * @description Get suggestions data from the editor
 * @param {*} editor - CKEditor
 */
export const getSuggestions = (editor) => {
  return editor.plugins.get("TrackChanges").getSuggestions();
};

/**
 * @description Removes invalid comment xml data from contents
 *              if it does not exist in the comments array
 *
 *              https://ckeditor.com/docs/ckeditor5/latest/features/collaboration/comments/comments-integration.html
 *              #why-is-there-no-event-when-i-remove-a-comment-thread
 * @param {*} content
 * @param {*} comments
 */
export const sanitizeContentComments = (content, commentsStr) => {
  let sanitizedContent = content;
  const comments = !commentsStr ? null : JSON.parse(commentsStr);
  const commentThreadIds = !comments ? [] : comments.map((comment) => comment.threadId);
  const contentComments = !content ? null : content.match(new RegExp(/<comment(.+?)<\/comment>/g));

  if (!!contentComments) {
    // for each content comment, check if it does not exist in the comments array
    contentComments.forEach((contentComment) => {
      if (!commentThreadIds.some((commentThreadId) => contentComment.includes(commentThreadId))) {
        // remove content comment if it doesn't exist
        sanitizedContent = sanitizedContent.replace(contentComment, "");
      }
    });
  }
  return sanitizedContent;
};

import styled from "styled-components";
import { Spin } from "antd";

export const LoadingContainer = styled.div.attrs({ className: "LoadingContainer" })`
  min-height: inherit; /* to stretch to parent height */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingSpinner = styled(Spin).attrs({
  size: "large",
})``;

import styled from "styled-components";
import { Radio as StyledRadio, Icon as StyledIcon } from "antd";
import COLORS from "../../../../constants/colors";

export const IconContainer = styled.div`
  &&& {
    width: 30px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ colour }) => colour};
    color: ${COLORS.WHITE};
    margin: 0px 5px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ChildrenContainer = styled.div`
  margin: 5px 0px;
  flex: 1;
  padding: 0px 10px;
`;

export const Icon = styled(StyledIcon)`
  font-size: 20px;
  font-weight: bolder;
`;

const AnswerRadioButton = styled(StyledRadio.Button)`
  &&& {
    width: 100%;
    height: auto;
    margin: 5px 5px;
    border: solid 1px;
    border-radius: 3px;
  }
`;

const UnselectedRadioButton = styled(AnswerRadioButton)`
  &&& {
    border-color: ${COLORS.LIGHTGREY};
    background-color: ${COLORS.LIGHTGREY};
    color: ${COLORS.DARKGREY};

    .activeRadio :hover {
      border-color: ${COLORS.BLUE};
      color: ${COLORS.BLUE};
    }
  }
`;

const SelectedRadioButton = styled(AnswerRadioButton)`
  &&& {
    border-color: ${COLORS.BLUE};
    background-color: ${COLORS.BLUE_FADED};
    box-shadow: unset;
    color: ${COLORS.BLUE};
  }
`;

const CorrectRadioButton = styled(AnswerRadioButton)`
  &&& {
    background-color: ${COLORS.GREEN_FADED};
    border-color: ${COLORS.GREEN};
    color: ${COLORS.GREEN};
  }
`;

const IncorrectRadioButton = styled(AnswerRadioButton)`
  &&&& {
    background-color: ${COLORS.RED_FADED};
    border-color: ${COLORS.RED};
    color: ${COLORS.RED};
  }
`;

export const RadioButton = {
  UnselectedRadioButton,
  SelectedRadioButton,
  CorrectRadioButton,
  IncorrectRadioButton,
};

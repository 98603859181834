import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Title,
  Subtitle,
  DownArrow,
  StyledDropdown,
  StyledMenu,
  StyledMenuItem,
  MenuItemText,
  DropdownTitleContainer,
} from "./styled";

const SubjectTitle = ({ courseName, subjectName, remainingSubjects = [] }) => {
  const Dropdown = ({ children }) => {
    const menu = (
      <StyledMenu>
        {remainingSubjects
          .map((subject, index) => (
            <StyledMenuItem key={index}>
              <Link to={`/sbj${subject._id}`}>
                <MenuItemText>{subject.name}</MenuItemText>
              </Link>
            </StyledMenuItem>
          ))
          .reduce((accu, elem, idx) => {
            return accu === null ? [elem] : [...accu, <StyledMenu.Divider key={`div${idx}`} />, elem];
          }, null)}
      </StyledMenu>
    );

    return (
      <StyledDropdown overlay={menu}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <DropdownTitleContainer>{children}</DropdownTitleContainer>
        </a>
      </StyledDropdown>
    );
  };

  return (
    <Fragment>
      <Subtitle>{courseName.toUpperCase()}</Subtitle>
      {remainingSubjects.length > 0 ? (
        <Dropdown>
          <DownArrow />
          <Title>{subjectName}</Title>
        </Dropdown>
      ) : (
        <Title>{subjectName}</Title>
      )}
    </Fragment>
  );
};

export default SubjectTitle;

import React from "react";
import { Icon } from "antd";
import get from "lodash/get";
import {
  Menu,
  SubMenu,
  StyledLink,
  MenuItem,
  Container,
  StyledTutorialText,
  StyledTutorialNameContainer,
  StyledText,
  // StyledDashboardLinkContainer,
} from "./styled";

const TableOfContents = ({ tutorials, subjectId, selectedTutorialId, selectedLearningUnitId, collapse }) => {
  const renderSubMenu = (tutorial) => {
    // a tutorial is considered completed if all its learning units are marked as completed
    const isTutorialCompleted = get(tutorial, "studentTutorials.0.status") === "Completed";
    const tutorialName = get(tutorial, "subjectTutorials.0.displayName", tutorial.name) || tutorial.name;
    return (
      <SubMenu
        key={`toc-${tutorial._id}`}
        title={
          <StyledTutorialNameContainer>
            <Icon type={isTutorialCompleted ? "check-square" : "folder-open"} theme="filled" />
            <StyledTutorialText>{tutorialName}</StyledTutorialText>
          </StyledTutorialNameContainer>
        }
      >
        {tutorial.learningUnits
          .filter((lu) => !lu.isArchived)
          .map((learningUnit) => {
            const isLearningUnitCompleted = get(learningUnit, "studentLearningUnits.0.status") === "Completed";
            const learningUnitName = get(learningUnit, "subjectAssessments.0.displayName", learningUnit.name) || learningUnit.name;
            return (
              <MenuItem key={`tocMenuItem-${tutorial._id}-${learningUnit._id}`}>
                <StyledLink to={`/sbj${subjectId}/tut${tutorial._id}/buildlearningunit${learningUnit._id}`} className="learning-unit-link">
                  <Icon
                    type={isLearningUnitCompleted ? "check-circle" : "file-text"}
                    theme={selectedLearningUnitId === learningUnit._id ? "filled" : "outlined"}
                  />
                  <StyledText>{learningUnitName}</StyledText>
                </StyledLink>
              </MenuItem>
            );
          })}
      </SubMenu>
    );
  };

  return (
    <Container>
      <Menu
        selectedKeys={[selectedLearningUnitId]}
        defaultOpenKeys={collapse ? [] : [`toc-${selectedTutorialId}`]}
        forceSubMenuRender={true}
        mode="inline"
      >
        {!!tutorials && tutorials.map((tutorial) => renderSubMenu(tutorial))}
      </Menu>
    </Container>
  );
};

export default TableOfContents;

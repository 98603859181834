export default {
  WHITE: "rgba(255, 255, 255, 1)",
  TRANSLUCENT_WHITE: "rgba(255, 255, 255, 0.7)",
  TRANSLUCENT_LIGHT_BLUE_GRAY: "rgba(145, 163, 196, 0.3)",
  BLACK: "#000000",
  CREAM: "#F8F7F7",
  BLUE: "#1890FF",
  BLUE_EDIT: "#0085fa",
  BLUE_FADED: "#e8f4ff",
  SOFT_BLUE_GRAY: "#91a3c4",
  DEEP_BLUE: "#172B4D",
  LIGHTBLUE: "#b3d4ff",
  VERYLIGHTBLUE: "#deebff",
  RED: "rgba(255, 77, 79, 1)",
  RED_FADED: "rgba(255, 77, 79, 0.3)",
  PINK: "rgba(255, 192, 203, 1)",
  GREEN: "rgba(29, 185, 84, 1)",
  GREEN_FADED: "rgba(29, 185, 84, 0.3)",
  DARKGREEN: "#30CDAC",
  AQUA: "#53ddcd",
  LIGHTGREEN: "#CEF5F1",
  YELLOW: "#fec058",
  BRIGHTGREY: "#ecf0f1",
  BRIGHTGRAY: "#ecf0f1",
  VERYLIGHTGREY: "#f3f3f3",
  VERYLIGHTGRAY: "#f3f3f3",
  VERY_LIGHT_BLUE_GRAY: "#f0f2f5",
  LIGHTGREY: "#e7e7e7",
  LIGHTGRAY: "#e7e7e7",
  LIGHTERGREY: "#cbccce",
  LIGHTERGRAY: "#cbccce",
  GREY: "#a0a0a0",
  GRAY: "#a0a0a0",
  DARKGREY: "#888888",
  DARKGRAY: "#888888",
  DARKERGREY: "#484848",
  DARKERGRAY: "#484848",
};

// GraphQL Client
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
// import ApolloClient from "apollo-boost";
import fetch from "node-fetch";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { GRAPHQL_URI } from "./graphqlURI";
import { login, getIdToken } from "../../utils/authHelpers";

const graphqlURI = GRAPHQL_URI;

// Old setup
// const client = new ApolloClient({
//   uri: graphqlURI,
//   fetch,
// });

// New setup
// 1. Ensure we are using Node v12.16.2 locally similar to Heroku
// 2. Installed the following packages:
// npm uninstall apollo-boost
// npm install apollo-client apollo-cache-inmemory apollo-upload-client apollo-link-error apollo-link graphql-tag
// (References: https://github.com/apollographql/apollo-server/issues/3508 ; https://www.npmjs.com/package/npm-force-resolutions)

// For Auth0
// npm install apollo-link-context

/**
 * @description Capture errors in the apollo client
 * @param {*} graphQLErrors - GraphQL related errors
 * @param {*} networkError - Network related errors
 */
const errorHandler = ({ graphQLErrors, networkError }) => {
  let isAuthError = false;
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (extensions.code === "UNAUTHENTICATED") isAuthError = true;
    });
  // Redirect user the login page
  if (isAuthError) login();
  if (networkError) console.error(`[Network error]: ${networkError}`);
};

/**
 * @description Enhanced Apollo HttpLink which has the apollo-upload-client
 */
const uploadLink = createUploadLink({ uri: graphqlURI });

/**
 * @description Authorisation context for Auth0
 */
const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: getIdToken(),
  },
}));

/**
 * @description The Apollo Client
 */
const client = new ApolloClient({
  link: ApolloLink.from([onError(errorHandler), authLink, uploadLink]),
  cache: new InMemoryCache(),
  fetch,
});

export default client;

import { Typography, Input, Icon } from "antd";
import styled from "styled-components";
import COLORS from "../../../constants/colors";

const { Text } = Typography;

export const CustomText = styled(Text)`
  font-size: 24px;
  font-weight: 900px;
  color: black;
  padding: 10px 0px 5px 1px;
  line-height: 28px;
  word-break: break-all;
`;

export const CustomInput = styled(Input)`
  &&& {
    font-size: 24px;
    font-weight: 900px;
    padding: 0px;
    margin: 8px 0px 3px 0px;
    color: black;
  }
`;

export const CustomIcon = styled(Icon)`
  color: ${COLORS.BLUE_EDIT};
  font-size: 24px;
  margin-top: 13px;
  margin-left: 5px;
  text-align: center;
`;

export const EditIcon = styled(CustomIcon).attrs({ type: "edit" })``;

export const DoneIcon = styled(CustomIcon).attrs({ type: "check-circle" })``;

export const EditableTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

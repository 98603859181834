import { ADD_FILE, REMOVE_FILE, UPDATE_FILE, REORDER_FILE } from "./actions";

/**
 * @description Adds a file to the local state fileList
 * @param {object} list - List of files
 * @param {object} newFiles - New files
 * @param {function} commitChanges - Function to handle the Save outside of the local state
 */
const addFile = (fileList, fileName, newFiles, commitChanges) => {
  const files = fileList.push(...newFiles);
  const filenames = fileName.push(newFiles._tail.array[0].filename);
  commitChanges(files, filenames);
  return [files, filenames];
};


/**
 * @description Updates a file in the local state fileList
 * @param {object} list - List of files
 * @param {number} fileIndex - Index of the file to update
 * @param {object} fileMetadataFields - Fields to update (e.g. { description: 'Hello' } )
 * @param {function} commitChanges - Function to handle the Save outside of the local state
 */
const updateFile = (fileList, fileName, fileIndex, fileMetadataFields, commitChanges) => {
  const files = fileList.update(fileIndex, (file) => ({
    ...file,
    ...fileMetadataFields
  }));
  const filenames = fileName.update(fileIndex, (file) => fileMetadataFields.filename);
  commitChanges(files, filenames);
  return [files, filenames];
};

/**
 * @description Reorder the file in the local state fileList
 * @param {object} list - List of files
 * @param {number} startIndex - Source index of the file's original position
 * @param {number} endIndex - Targert index of the file's new position
 * @param {function} commitChanges - Function to handle the Save outside of the local state
 */
const reorderFile = (fileList, fileName, startIndex, endIndex, commitChanges) => {

  const fileAtStartIndex = fileList.get(startIndex);
  const fileNameAtStartIndex = fileName.get(startIndex);
  const files = fileList.delete(startIndex).insert(endIndex, fileAtStartIndex);
  const filenames = fileName.delete(startIndex).insert(endIndex, fileNameAtStartIndex);
  commitChanges(files, filenames);
  return [files, filenames];
};

/**
 * @description Remove a file from the local state fileList
 * @param {object} list - List of files
 * @param {number} fileIndex - Index of the file to delete
 * @param {function} commitChanges - Function to handle the Save outside of the local state
 */
const removeFile = (fileList, fileName, fileIndex, commitChanges) => {
  const files = fileList.delete(fileIndex);
  const filenames = fileName.delete(fileIndex);
  commitChanges(files, filenames);
  return [files, filenames];
};

/**
 * @description FileUploadBuilder reducer
 * @param {object} state - State containing the fileList, lucId and commitChanges function
 * @param {object} action - Local actions (e.g. ADD_FILE, UPDATE_FILE, REORDER_FILE, REMOVE_FILE)
 */
const reducer = (state, action) => {
  let fileList;
  let fileName; 
  switch (action.type) {

    case ADD_FILE:
     [fileList, fileName] = addFile(state.fileList, state.fileName, action.newFiles, state.commitChanges); 
      return {
        ...state,
        fileList,
        fileName
      };
    case UPDATE_FILE:
      [fileList, fileName] = updateFile(state.fileList, state.fileName, action.fileIndex, action.fileMetadataFields, state.commitChanges);
      return {
        ...state,
        fileList,
        fileName,
      };
    case REORDER_FILE:
      [fileList, fileName] = reorderFile(state.fileList, state.fileName, action.sourceIndex, action.destinationIndex, state.commitChanges);
      return {
        ...state,
        fileList,
        fileName
      };
    case REMOVE_FILE:
       [fileList, fileName] = removeFile(state.fileList, state.fileName, action.fileIndex, state.commitChanges); 
      return {
        ...state,
        fileList,
        fileName
      };
    default:
      throw new Error();
  }
};

export default reducer;

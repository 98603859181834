import auth0 from "auth0-js";
import jwt from "jsonwebtoken";
import { AUTH_DOMAIN, CLIENT_ID, REDIRECT_URI, DEFAULT_URL, AUTH_AUDIENCE } from "../services/auth/authURI";

const WEB_AUTH_DEFAULTS = {
  domain: AUTH_DOMAIN,
  clientID: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  audience: `https://${AUTH_AUDIENCE}/userinfo`,
  responseType: "token id_token",
  scope: "openid email user_type",
};

const TOKEN_KEY = "token";

const USER_TYPE_KEY = `https://${AUTH_AUDIENCE}user_type`;

const setSessionToken = (authResult) => {
  const token = authResult.idToken;
  sessionStorage.setItem(TOKEN_KEY, token);
};

const getNewWebAuth = (redirectUri) => {
  return new auth0.WebAuth({
    domain: WEB_AUTH_DEFAULTS.domain,
    clientID: WEB_AUTH_DEFAULTS.clientID,
    redirectUri: redirectUri ?? WEB_AUTH_DEFAULTS.redirectUri,
    audience: WEB_AUTH_DEFAULTS.audience,
    responseType: WEB_AUTH_DEFAULTS.responseType,
    scope: WEB_AUTH_DEFAULTS.scope,
  });
};

export const login = () => {
  let redirectUri;
  if (window.location.pathname !== "/") {
    redirectUri = `${REDIRECT_URI}?return=${window.encodeURIComponent(`${window.location.pathname}`)}`;
  }
  const auth = getNewWebAuth(redirectUri);
  auth.authorize();
};

export const logout = () => {
  // Remove Id Token from Session
  sessionStorage.removeItem(TOKEN_KEY);

  const auth = getNewWebAuth();
  auth.logout({
    returnTo: DEFAULT_URL,
  });
};

export const handleAuthentication = () => {
  const auth = getNewWebAuth();
  if (!handleAuthentication.instance) {
    handleAuthentication.instance = new Promise((resolve, reject) => {
      auth.parseHash((err, authResult) => {
        console.error(err);
        if (err) reject(err);
        if (!authResult || !authResult.idToken) reject(err);
        setSessionToken(authResult);
        resolve(authResult);
      });
    });
  }
  return handleAuthentication.instance;
};

export const checkSession = () => {
  const auth = getNewWebAuth();
  if (!checkSession.instance) {
    checkSession.instance = new Promise((resolve, reject) => {
      auth.checkSession({}, (err, authResult) => {
        if (err) {
          console.error(err);
          reject(err);
        } else if (!authResult || !authResult.idToken) {
          reject(new Error("No authResult or idToken"));
        } else {
          setSessionToken(authResult);
          resolve(authResult);
        }
      });
    });
  }
  return checkSession.instance;
};

export const isAuthenticated = () => {
  const authenticated = !!sessionStorage.getItem(TOKEN_KEY);
  return authenticated;
};

export const getIdToken = () => {
  return sessionStorage.getItem(TOKEN_KEY);
};

export const getUserType = () => {
  const decoded = !!sessionStorage.getItem(TOKEN_KEY) ? jwt.decode(sessionStorage.getItem(TOKEN_KEY), { json: true })[USER_TYPE_KEY] : null;
  return decoded;
};

export default {
  login,
  logout,
  handleAuthentication,
  isAuthenticated,
  getIdToken,
  getUserType,
};

import React from "react";
import PropTypes from "prop-types";
import Snippet from "../../Snippet";
import { SolutionCard } from "../styled";

const ExplanationCard = ({ title, explanation }) => {
  return (
    <SolutionCard title={title}>
      <Snippet html={explanation} />
    </SolutionCard>
  );
};

const Explanation = ({ isSubmitted, explanation, showExplanation }) => {
  if (!showExplanation || !isSubmitted || !explanation) return "";

  return <ExplanationCard title="EXPLANATION" explanation={explanation} />;
};

Explanation.propTypes = {
  explanation: PropTypes.string,
  showExplanation: PropTypes.bool,
  isCorrect: PropTypes.bool,
  isSubmitted: PropTypes.bool,
};

export default Explanation;

import React from "react";
import COLORS from "../../../../constants/colors";
import { RadioButton, IconContainer, ContentContainer, ChildrenContainer, Icon } from "./styled";

const { UnselectedRadioButton, SelectedRadioButton, CorrectRadioButton, IncorrectRadioButton } = RadioButton;
const AnswerOption = ({ type, children, value }) => {
  let RadioButtonType = UnselectedRadioButton;
  let iconType = "";
  let color = "";

  switch (type) {
    case "selected":
      RadioButtonType = SelectedRadioButton;
      iconType = "";
      color = COLORS.BLUE;
      break;

    case "correct":
      RadioButtonType = CorrectRadioButton;
      iconType = "check";
      color = COLORS.GREEN;
      break;

    case "incorrect":
      RadioButtonType = IncorrectRadioButton;
      iconType = "close";
      color = COLORS.RED;
      break;

    default:
      RadioButtonType = UnselectedRadioButton;
      iconType = "";
      color = COLORS.GREY;
      break;
  }

  return (
    <RadioButtonType value={value}>
      <ContentContainer>
        <ChildrenContainer>{children}</ChildrenContainer>
        {iconType && (
          <IconContainer>
            <Icon type={iconType} theme="outlined" style={{ fontSize: "25px", fontWeight: "bold", color }} />
          </IconContainer>
        )}
      </ContentContainer>
    </RadioButtonType>
  );
};

export default AnswerOption;

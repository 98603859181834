import gql from "graphql-tag";

export const INSERT_LEARNING_UNIT_TO_TUTORIAL = gql`
  mutation insertLearningUnitToTutorial($tutorialId: String!, $order: String!, $learningUnit: LearningUnitInput!, $templateId: String) {
    insertLearningUnitToTutorial(tutorialId: $tutorialId, order: $order, learningUnit: $learningUnit, templateId: $templateId) {
      _id
      name
      order
      subjectAssessments {
        _id
        displayName
      }
    }
  }
`;

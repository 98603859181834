import React from "react";
import styled from "styled-components";
import { Form, Checkbox } from "antd";
import { Strong } from "../../../../utils/globalStyled";
import COLORS from "../../../../constants/colors";

export const BoxCheck = styled(Checkbox)`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;

export const CheckboxGroup = styled(Checkbox.Group)`
  /* To match label height */
  padding-top: 1.62rem;
  display: flex;
  flex-direction: column;
  .ant-checkbox-wrapper {
    + .ant-checkbox-wrapper {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
`;

export const LeftPanel = styled.div`
  border-right: 1px solid ${COLORS.LIGHTGREY};
  padding-right: 20px;
  flex: 1;
`;

export const RightPanel = styled.div`
  padding-left: 20px;
  padding-top: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  .ant-checkbox-wrapper {
    + .ant-checkbox-wrapper {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const FormItem = styled(Form.Item).attrs(({ label, disabled }) => ({
  label: label ? <Strong disabled={disabled}>{label}</Strong> : null
}))`
  margin-bottom: 1rem;
`;

export const SettingsForm = styled(Form).attrs({
  layout: "vertical",
  colon: false
})``;
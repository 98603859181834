import { v4 } from "uuid";
import { List } from "immutable";
import { QUESTION_TYPES, ANSWER_ORDER } from "../../../../constants/types";

export const getNewQuestion = (type, answerOptions) => ({
  _id: `LOCAL-question-${v4()}`,
  question: "",
  explanation: "",
  correctAnswer: "",
  correctAnswerValue: "",
  type: type || QUESTION_TYPES.MULTIPLE_CHOICE,
  answerDisplayStyle: ANSWER_ORDER.ORDERED,
  active: true,
  answerOptions: answerOptions || List(),
});

export const getNewAnswerOption = (value = "", position = 0) => ({
  _id: `LOCAL-answer-${v4()}`,
  value: value,
  position,
});

import React, { forwardRef } from "react";
import Editor from "../../Editor";
import { EDITOR_LENGTH } from "../../../../constants/miscellaneous";
import { EditorContainer } from "./styled";

/**
 * A snippet type learning unit component
 *
 * @param {string} learningUnitId - _id of learningUnit
 * @param {string} initialContent - input value
 * @param {function} onContentChange - function that handles content changes
 * @param {ref} commentsPanelRef
 */
const SnippetBuilder = forwardRef((props, ref) => {
  const { learningUnitId, initialContent, initialComments, initialSuggestions, onContentChange, onEditorCommentToolbarClick } = props;

  return (
    <EditorContainer>
      <Editor
        maxLength={EDITOR_LENGTH.HTML}
        learningUnitId={learningUnitId}
        content={initialContent}
        comments={initialComments}
        suggestions={initialSuggestions}
        onChange={onContentChange}
        ref={ref}
        isCommentable={true}
        isTrackable={true}
        onEditorCommentToolbarClick={onEditorCommentToolbarClick}
        inline
      />
    </EditorContainer>
  );
});

export default SnippetBuilder;

export const REORDER = "REORDER";
export const TOGGLE_PREVIEW_MODE = "TOGGLE_PREVIEW_MODE";
export const UNDO = "UNDO";
export const REDO = "REDO";
export const ADD = "ADD";
export const UPDATE = "UPDATE";
export const SAVE_START = "SAVE_START";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const EDITABLE_SUCCESS = "EDITABLE_SUCCESS";
export const PUBLISHED_SUCCESS = "PUBLISHED_SUCCESS";
export const SUBMITTED_FOR_REVIEW_SUCCESS = "SUBMITTED_FOR_REVIEW_SUCCESS";
export const SAVE_OUTDATED = "SAVE_OUTDATED";
export const SAVE_FAILED = "SAVE_FAILED";
export const UPDATE_LEARNING_UNIT = "UPDATE_LEARNING_UNIT";
export const SET_READ_ONLY = "SET_READ_ONLY";
export const RESET_STATE = "RESET_STATE";
export const SHOW_COMMENTS = "SHOW_COMMENTS";
export const TOGGLE_SHOW_COMMENTS = "TOGGLE_SHOW_COMMENTS";

export const reorder = (sourceIndex, destinationIndex) => ({ type: REORDER, sourceIndex, destinationIndex });
export const togglePreviewMode = () => ({ type: TOGGLE_PREVIEW_MODE });
export const undo = () => ({ type: UNDO });
export const redo = () => ({ type: REDO });
export const add = (cmp) => ({ type: ADD, component: cmp });
export const saveStart = () => ({ type: SAVE_START });
export const saveSuccess = () => ({ type: SAVE_SUCCESS });
export const submitForReviewSuccess = () => ({ type: SUBMITTED_FOR_REVIEW_SUCCESS });
export const publishedSuccess = () => ({ type: PUBLISHED_SUCCESS });
export const editableSuccess = () => ({ type: EDITABLE_SUCCESS });
export const saveOutdated = () => ({ type: SAVE_OUTDATED });
export const saveFailed = () => ({ type: SAVE_FAILED });
export const setReadOnly = (value) => ({ type: SET_READ_ONLY, value });
export const updateLearningUnit = (learningUnitFields) => ({ type: UPDATE_LEARNING_UNIT, learningUnitFields });
export const resetState = (learningUnit) => ({ type: RESET_STATE, learningUnit });
export const showComments = () => ({ type: SHOW_COMMENTS });
export const toggleShowComments = () => ({ type: TOGGLE_SHOW_COMMENTS });

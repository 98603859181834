import { handleImage, handleVideo } from "./handleMedia";

/**
 * @description Supported Media MIME Types
 */
const SUPPORTED_MIME_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "video/mp4",
  "video/quicktime",
  "video/avi",
  "video/webm",
  "audio/ogg",
  "video/ogg",
  "audio/mpeg",
  "audio/m4a",
  "audio/x-m4a",
  "audio/wav",
  "audio/aac",
  "audio/flac",
  "audio/vnd.dlna.adts",
  "audio/mp4",
];

/**
 * @description Get the comma separated SUPPORTED_MIME_TYPES
 */
export const getAcceptedMimeTypes = () => SUPPORTED_MIME_TYPES.join(", ");

/**
 * @description Convert a file upload buffer to an object url
 * @param {*} buffer - The file buffer
 * @param {string} mimeType - The file mime type
 */
const createObjectUrlFromBuffer = (buffer, mimeType) => {
  // Convert the buffer to a blob
  const blob = new Blob([new Uint8Array(buffer)], { type: mimeType });

  // Create an object URL from the blob
  const objectUrl = URL.createObjectURL(blob);

  return objectUrl;
};

/**
 * @description Retrieve the file upload information (e.g. height and width)
 * @param {*} file - Value of event.target.files[x]
 */
export const getUploadInfo = (file) =>
  new Promise((resolve, reject) => {
    const mimeType = file.type;

    // Check if mime type is compatible
    if (!SUPPORTED_MIME_TYPES.includes(mimeType)) {
      reject(
        new Error(
          `You have selected an invalid media type "${mimeType}" for the following file: ${
            file.name
          }. Please consider converting the media to any of the following media types: ${getAcceptedMimeTypes()}`,
        ),
      );
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      // Capture the buffer
      const buffer = event.target.result;

      // Convert the buffer to object url
      const objectUrl = createObjectUrlFromBuffer(buffer, mimeType);

      // Validate if the mime type is an image, video or audio
      if (mimeType.startsWith("image")) {
        // If the file is an image
        handleImage(objectUrl)
          .then(({ height, width }) => {
            resolve({
              file,
              height,
              width,
            });
          })
          .catch((error) => {
            reject(error);
          });
      } else if (mimeType.startsWith("video") || mimeType.startsWith("audio")) {
        if (
          mimeType.startsWith("video/mp4") ||
          mimeType.startsWith("video/webm") ||
          mimeType.startsWith("audio/ogg") ||
          mimeType.startsWith("video/ogg")
        ) {
          // If the file is a video that support onloadedmetadata
          handleVideo(objectUrl)
            .then(({ height, width }) => {
              resolve({
                file,
                height,
                width,
              });
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({
            file,
            height: 405,
            width: 720,
          });
        }
      } else {
        reject(
          new Error(
            `You have selected an invalid media type "${mimeType}" for the following file: ${
              file.name
            }. Please consider converting the media to any of the following media types: ${getAcceptedMimeTypes()}`,
          ),
        );
      }
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsArrayBuffer(file);
  });

import React from "react";
import { Route } from "react-router-dom";
import { isAuthenticated, login } from "../../../utils/authHelpers";

const GuardRoute = (props) => {
  const { component: Component, path } = props;
  return (
    <Route
      path={path}
      exact
      render={() => {
        if (!isAuthenticated()) {
          return login();
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default GuardRoute;

import React from "react";
import { get } from "lodash";
import DraggableItem from "../../../presentational/DraggableItem";
import DragHandle from "../../../presentational/DragHandle";
import {
  LearningUnitLinkContainer,
  LearningUnitLinkTextContainer,
  LearningUnitLinkText,
  RemoveIcon,
  LoadingIcon,
  StyledLink,
} from "../Builder/styled";
import { LearningUnitStatusPill } from "../TutorialCardHeader/styled";
import { LEARNING_UNIT_STATUS } from "../helpers";
import COLORS from "../../../../constants/colors";
import ConfirmDialog from "../../../presentational/ConfirmDialog";

export const getItemStyle = (isDragging, draggableStyle, grid = 8) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // styles we need to apply on draggables
  ...draggableStyle,

  // conditional styles if dragging
  boxShadow: isDragging ? "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)" : "none",
  backgroundColor: isDragging ? `${COLORS.WHITE}` : `${COLORS.CREAM}`,

  // styles from <LearningUnitLink /> to replace it as surround div
  color: `${COLORS.VERYDARKGRAY}`,
  padding: "15px 10px 15px 30px",
  borderTop: `1px solid ${COLORS.LIGHTERGRAY}`,
  fontSize: "1.2em",
  fontWeight: "600",
  // BUG: selector not working
  "&:lastChild": {
    borderRadius: "0px 0px 10px 10px",
  },
});

const TutorialListItem = ({ tutorialId, subjectId, learningUnit, index = 0, handleItemRemove, loading, isReadOnly }) => {
  const { _id, name, isPublished, isSubmittedForReview } = learningUnit;

  const displayName = get(learningUnit, "subjectAssessments.0.displayName", null) || name;

  const renderPostLoadingIcon = () => {
    if (isPublished) {
      return <LearningUnitStatusPill>PUBLISHED</LearningUnitStatusPill>;
    }
    if (isSubmittedForReview) {
      return <LearningUnitStatusPill>IN REVIEW</LearningUnitStatusPill>;
    }
    if (!isReadOnly) {
      return (
        <ConfirmDialog placement="left" title="Are you sure you want to delete?" onConfirm={handleItemRemove}>
          <RemoveIcon data-testid="delete-button" />
        </ConfirmDialog>
      );
    }
    return null;
  };

  return (
    <DraggableItem draggableId={_id} index={index} itemStyleGetter={getItemStyle}>
      <LearningUnitLinkContainer>
        <LearningUnitLinkTextContainer>
          <DragHandle />
          <LearningUnitLinkText>
            <StyledLink to={`/sbj${subjectId}/tut${tutorialId}/buildlearningunit${_id}`}>{displayName}</StyledLink>
          </LearningUnitLinkText>
        </LearningUnitLinkTextContainer>
        {loading ? <LoadingIcon /> : renderPostLoadingIcon()}
      </LearningUnitLinkContainer>
    </DraggableItem>
  );
};

export default TutorialListItem;

import styled from "styled-components";
import COLORS from "../../../constants/colors";

export const Caption = styled.div`
  background-color: ${COLORS.WHITE};
  color: ${COLORS.DEEP_BLUE};
  bottom: 0px;
  padding: 5px;
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  min-height: 31px;
`;

export const CaptionText = styled.div`
  word-break: break-word;
  margin-right: 1px;
  font-size: 16pt;
  font-family: "Open sans";
  font-weight: Semi Bold;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
`;

export const CaptionToggle = styled.div`
  cursor: pointer;
`;


import React, { useState, forwardRef, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { isArray } from "lodash/fp";
import Loading from "../Loading";
import {
  MediaContainer,
  ControlContainer,
  ControlButton,
  BarContainer,
  PlayOverlay,
  Thumbnail,
  ThumbnailContainer,
  PageContainer,
  MediaTroubleLink,
} from "./styled";
import MediaCaption from "../MediaCaption";

/**
 * @description Displays a pagination bar containing Thumbnails
 * @param {object} contents - List of media items
 * @param {number} currentPage - Current content position index
 * @param {function} goToPage - Function to navigate to a content
 */
const MediaPaginationBar = forwardRef((props, ref) => {
  const { contents, currentPage, goToPage } = props;

  const renderThumbnail = () => {
    return contents.map((content, index) => {
      const currentlySelected = currentPage === index + 1;
      return (
        <ThumbnailContainer
          key={`${content._id}.thumbnail`}
          aria-label="thumbnail-item"
          className="thumbnailItem"
          selected={currentlySelected}
          onClick={() => goToPage(index + 1, false)}
        >
          {!!content.thumbnailUrl && <Thumbnail src={content.thumbnailUrl} alt="" />}
          {(content.type.startsWith("video") || content.type.startsWith("audio")) && <PlayOverlay />}
        </ThumbnailContainer>
      );
    });
  };

  return <BarContainer ref={ref}>{renderThumbnail()}</BarContainer>;
});

/**
 * @description Higher order component which will paginate the MediaCarousel
 * @param {object} children - List of MediaContainer components which contains a MediaContent
 * @param {object} contents - List of media items
 */
const MediaPaginate = forwardRef((props, ref) => {
  // Capture the children
  const { children, contents } = props;
  // Local state to track the current page
  const [currentPage, setCurrentPage] = useState(1);
  // Determine the number of media items
  const mediaCount = Array.isArray(children) ? children.length : 0;
  // Set the current content to the currentPage's position index
  const currentContent = !!contents && contents[currentPage - 1];
  // Set the current caption. Set it to blank if unavailable
  const currentCaption =
    !currentContent || !contents[currentPage - 1] || contents[currentPage - 1].hideCaptions ? "" : contents[currentPage - 1].description;

  const currentUrl = !currentContent || !contents[currentPage - 1] ? "" : contents[currentPage - 1].url;

  // Scroll ref is used to focus on a thumbnail when selected
  const scrollRef = useRef(null);

  /**
   * @description Scroll to a media thumbnail
   * @param {number} pageNum - The next content's position index
   */
  const scrollToThumbnail = (pageNum) => {
    const scrollSize = 100; // Because the thumbnail has a width of 100px
    const scrollTarget = pageNum * scrollSize - scrollSize;
    scrollRef.current.scrollLeft = scrollTarget;
  };

  /**
   * @description Navigate the currently displayed content
   * @param {number} pageNum - The next content's position index
   * @param {boolean} isScrolling - True if we want to scroll to a thumbnail. Otherwise, False
   */
  const goToPage = (pageNum, isScrolling = false) => {
    let newPageNum = pageNum;
    if (pageNum === 0) {
      newPageNum = mediaCount;
    } else if (pageNum === mediaCount + 1) {
      newPageNum = 1;
    }
    setCurrentPage(newPageNum);

    if (isScrolling) {
      // Initiate scroll to thumbnail
      scrollToThumbnail(newPageNum);
    }
  };

  /**
   * @description Component will render only if the content size is greater than 1
   * @param {object} cmp - The component toi render
   * @param {number} contentSize - The number of items being displayed
   * @param {number} minimumContentSize - The number of items expected to be display before showing the component
   */
  const renderByContentSize = (cmp, {contentSize, minimumContentSize}) => contentSize >= minimumContentSize && cmp;

  // Loading state
  if (!contents || !isArray(contents)) {
    return <Loading />;
  }

  // Render
  return (
    <PageContainer ref={ref}>
      <MediaContainer>
        <ControlContainer side="left">
          {renderByContentSize(
            <ControlButton className="mediaPrevBtn" type="left-circle" onClick={() => goToPage(currentPage - 1, true)} />,
            {contentSize: mediaCount, minimumContentSize: 2},
          )}
        </ControlContainer>

        <div className="displayedMediaContent">{Array.isArray(children) && children[currentPage - 1]}</div>

        <ControlContainer side="right">
          {renderByContentSize(
            <ControlButton className="mediaNextBtn" type="right-circle" onClick={() => goToPage(currentPage + 1, true)} />,
            {contentSize: mediaCount, minimumContentSize: 2},
          )}
        </ControlContainer>
      </MediaContainer>

      <MediaCaption caption={currentCaption} />

      {renderByContentSize(
        <MediaTroubleLink className="mediaTroubleLink">
          <a href={currentUrl} download>
            Trouble viewing the media? Download instead
          </a>
        </MediaTroubleLink>,
        {contentSize: mediaCount, minimumContentSize: 1},
      )}
      {renderByContentSize(
        <MediaPaginationBar ref={scrollRef} contents={contents} currentPage={currentPage} goToPage={goToPage} />,
        {contentSize: mediaCount, minimumContentSize: 2},
      )}
    </PageContainer>
  );
});

MediaPaginate.propTypes = {
  contents: PropTypes.array.isRequired,
};

export default MediaPaginate;

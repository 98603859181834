import styled from "styled-components";
import { Layout } from "antd";
import COLORS from "../../../constants/colors";
import { UpToMobileWidth } from "../../../constants/mediaQueries";
import { FOOTER_HEIGHT } from "../../../constants/miscellaneous";
import { FullWidth } from "../../../utils/globalStyled";

export const Footer = styled(Layout.Footer)`
  ${FullWidth};
  height: ${FOOTER_HEIGHT}px;
  font-size: 0.5rem;
  background: ${COLORS.WHITE};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.DARKGREY};

  &&& {
    > span {
      padding: 0;
    }
  }

  @media screen and ${UpToMobileWidth} {
    font-size: 0.75rem;
  }
`;

export const FooterItem = styled.span`
  &&& {
    display: inline-block;
    margin: 0 5px;
    padding: 3px 0 6px;
    text-decoration: none;
  }
`;

export const LinkTo = styled.a`
  color: ${COLORS.DARKGREY};
`;

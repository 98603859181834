import React from "react";
import styled from "styled-components";
import { Collapse, Icon } from "antd";
import COLORS from "../../../../constants/colors";

export const Accordion = styled(Collapse).attrs({
  accordion: true,
  bordered: false,
  defaultActiveKey: "0"
})`
  font-size: .75rem;
  max-width: 800px;
  &&& {
    .ant-collapse-item {
      border-bottom: none;
      .ant-collapse-header {
        padding: 0 0 .5em 0;
        span {
          text-transform: uppercase;
        }
      }
    }
  }
`;

export const CleanPanel = styled(Collapse.Panel).attrs({
  showArrow: false
})`
  background: ${COLORS.WHITE};
  overflow: hidden;

  &&& {
    .ant-collapse-content-box {
      padding-top: 0;
      padding-left: 0;
    }
  }
`;

export const Arrow = styled(Icon).attrs(({ rotate }) => ({
  type: "right",
  rotate
}))`
  margin-left: .5rem;
  font-size: 12px;
`;

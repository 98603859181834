import React, { memo, useState, forwardRef, useRef } from "react";
import debounce from "lodash/debounce";
import { UniqueInputFieldNames } from "graphql/validation/rules/UniqueInputFieldNames";
import { Container, RemoveFromPalette, LearningUnitActionsContainer, CloneComponent, Settings, ActionIconsContainer } from "./styled";
import SnippetBuilder from "./SnippetBuilder";
import SnippetSettings from "./SnippetSettings";
import AssessmentOpportunityBuilder from "./AssessmentOpportunityBuilder";
import AssessmentOpportunitySettings from "./AssessmentOpportunitySettings";
import MediaCarouselBuilder from "./MediaCarouselBuilder";
import FileUploadBuilder from "./FileUploadBuilder";
import { update, clone, remove } from "./actions";
import { LEARNING_UNIT_COMPONENT_TYPES } from "../../../constants/types";
import { saveFiles } from "./MediaCarouselBuilder/actions";
import { saveFilesAndNames } from "./FileUploadBuilder/actions";
import ConditionalDisplay from "../../presentational/ConditionalDisplay";
import CaseStudySolutionSnippetBuilder from "./CaseStudySolutionSnippetBuilder";
import TemplateHelpText from "./TemplateHelpText";
import { DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS } from "../../../constants/miscellaneous";
import ConfirmDialog from "../../presentational/ConfirmDialog";
import CommentsPanel from "../CommentsPanel";
import Commentable from "../Commentable";

const debouncedUpdateItem = debounce((dispatch, _id, cmp) => 
  dispatch(update(_id, cmp)), DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS);

const debouncedSaveFiles = debounce((dispatch, _id, files) =>
 dispatch(saveFiles(_id, files)), DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS);

const debouncedSaveFilesAndNames = debounce((dispatch, _id, files, filenames) => 
  dispatch(saveFilesAndNames(_id, files, filenames)), DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS);

/**
 * 
 * Builder that renders different types of learning unit components wrapped with action buttons
 *
 * @param  {string} learningUnitId
 * @param  {string} tutorialId
 * @param  {object} luc - learning unit component
 * @param  {function} dispatch - state function
 * @param  {number} index - order of the luc
 */
const LearningUnitComponentBuilder = ({ learningUnitId, tutorialId, luc, dispatch, index, showComments, onEditorCommentToolbarClick }) => {
  const localRef = useRef(null);

  const [settingsMode, setSettingsMode] = useState(false);
  const isRelevantType =
    luc.type === LEARNING_UNIT_COMPONENT_TYPES.ASSESSMENT_OPPORTUNITY || luc.type === LEARNING_UNIT_COMPONENT_TYPES.SNIPPET;

  const renderBuilders = () => {
    switch (luc.type) {
      case LEARNING_UNIT_COMPONENT_TYPES.SNIPPET:
        return (
          <SnippetBuilder
            learningUnitId={learningUnitId}
            initialContent={luc.html}
            initialComments={luc.comments}
            initialSuggestions={luc.suggestions}
            onContentChange={({ content, comments, suggestions }) =>
              debouncedUpdateItem(dispatch, luc._id, { html: content, comments, suggestions })
            }
            onEditorCommentToolbarClick={onEditorCommentToolbarClick}
            ref={localRef}
          />
        );
      case LEARNING_UNIT_COMPONENT_TYPES.CASE_STUDY_SOLUTION_SNIPPET:
        return (
          <CaseStudySolutionSnippetBuilder
            learningUnitId={learningUnitId}
            initialContent={luc.html}
            onContentChange={({ content }) => debouncedUpdateItem(dispatch, luc._id, { html: content })}
          />
        );
      case LEARNING_UNIT_COMPONENT_TYPES.ASSESSMENT_OPPORTUNITY:
        return (
          <Commentable
            ref={localRef}
            showComments={showComments}
            initialComments={luc.comments}
            onChange={({ comments }) => debouncedUpdateItem(dispatch, luc._id, { comments })}
            >
            <AssessmentOpportunityBuilder
              assessmentOpportunity={luc}
              dispatch={dispatch}
              ref={localRef}
              onContentChange={(lucSettings) => {
                debouncedUpdateItem(dispatch, luc._id, { ...lucSettings })
              }}
            />
          </Commentable>
        );
      case LEARNING_UNIT_COMPONENT_TYPES.FILE_UPLOAD:
        return (
          <Commentable
          ref={localRef}
          showComments={showComments}
          initialComments={luc.comments}
          onChange={({ comments }) => debouncedUpdateItem(dispatch, luc._id, { comments })}
        >
          <FileUploadBuilder
            learningUnitId={learningUnitId}
            tutorialId={tutorialId}
            media={luc}
            onContentChange={(files, filenames) => debouncedSaveFilesAndNames(dispatch, luc._id, files, filenames)}
          />
          </Commentable>
        );
      case LEARNING_UNIT_COMPONENT_TYPES.MEDIA_CAROUSEL:
        return (
          <Commentable
            ref={localRef}
            showComments={showComments}
            initialComments={luc.comments}
            onChange={({ comments }) => debouncedUpdateItem(dispatch, luc._id, { comments })}
          >
            <MediaCarouselBuilder
              learningUnitId={learningUnitId}
              tutorialId={tutorialId}
              media={luc}
              onContentChange={(files) => debouncedSaveFiles(dispatch, luc._id, files)}
            />
          </Commentable>
        );
      default:
        return null;
    }
  };

  const renderSettings = () => {
    switch (luc.type) {
      case LEARNING_UNIT_COMPONENT_TYPES.ASSESSMENT_OPPORTUNITY:
        return (
          <AssessmentOpportunitySettings
            assessmentOpportunity={luc}
            onFieldValueChange={(fieldValues) => debouncedUpdateItem(dispatch, luc._id, fieldValues)}
          />
        );
      case LEARNING_UNIT_COMPONENT_TYPES.SNIPPET:
        return (
          <SnippetSettings
            styleClass={luc.styleClass}
            onFieldValueChange={(fieldValues) => debouncedUpdateItem(dispatch, luc._id, fieldValues)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <ConditionalDisplay showIf={!settingsMode}>
        {luc.templateHelpTextHeading && <TemplateHelpText heading={luc.templateHelpTextHeading} body={luc.templateHelpTextBody} />}
        {renderBuilders()}
      </ConditionalDisplay>

      <ConditionalDisplay showIf={settingsMode}>{renderSettings()}</ConditionalDisplay>

      <LearningUnitActionsContainer>
        <ActionIconsContainer>
          <ConfirmDialog placement="left" title="Delete this component?" onConfirm={() => dispatch(remove(index))}>
            <RemoveFromPalette />
          </ConfirmDialog>
          <CloneComponent type="copy" onClick={() => dispatch(clone(index))} />
          {isRelevantType && (
            <Settings
              theme={settingsMode ? "filled" : "outlined"}
              onClick={() => setSettingsMode((prevSettingsMode) => !prevSettingsMode)}
            />
          )}
        </ActionIconsContainer>
      </LearningUnitActionsContainer>

      <CommentsPanel ref={localRef} isVisible={showComments} />
    </Container>
  );
};

export default memo(LearningUnitComponentBuilder);

import React from "react";
import { ArrowIconContainer, ArrowSvg } from "./styled";

const CollapseArrowIcon = (props) => (
  <ArrowIconContainer {...props}>
    <ArrowSvg />
  </ArrowIconContainer>
);

export default CollapseArrowIcon;

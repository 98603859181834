import styled from "styled-components";
import { Link } from "react-router-dom";
import { Row, Col, Icon, Popconfirm } from "antd";
import COLORS from "../../../../constants/colors";
import { LEARNING_UNIT_STATUS } from "../helpers";
import { UpToMobileWidth } from "../../../../constants/mediaQueries";

export const BuilderContainer = styled.div`
  background-color: ${COLORS.VERYLIGHTERGRAY};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PaletteContainer = styled.div`
  width: 100%;
  padding: 50px 0;
  min-height: 250px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const TutorialsColumn = styled(Col).attrs({
  xs: { span: 24, order: 2 },
  sm: { span: 24, order: 2 },
  md: { span: 16, order: 1 },
  lg: { span: 16, order: 1 },
  xl: { span: 12, order: 1 },
})``;

export const SubjectTitleContainer = styled(PaletteContainer)`
  width: 100%;
  margin-top: 20px;
  padding: 0px;
  min-height: 0px;
`;

// Learning Unit

export const LearningUnitLink = styled.div`
  display: block;
  color: ${COLORS.VERYDARKGRAY};
  /* padding: 15px 30px 15px 30px; */
  background-color: ${({ status }) => (status === LEARNING_UNIT_STATUS.COMPLETED ? COLORS.LIGHTGREEN : COLORS.CREAM)};
  border-top: 1px solid ${COLORS.LIGHTERGRAY};
  font-size: 1.2em;
  font-weight: 600;

  &:last-child {
    border-radius: 0px 0px 10px 10px;
  }
`;

export const LearningUnitLinkStatus = styled.div`
  align-items: center;
  padding-right: 15px;
  color: ${({ status }) => (status === LEARNING_UNIT_STATUS.COMPLETED ? COLORS.DARKERGRAY : COLORS.DARKGRAY)};
`;

export const LearningUnitLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const LearningUnitLinkTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 75%;
`;

export const LearningUnitLinkText = styled.div`
  flex-grow: 1;
  margin-left: 10px;
`;

export const LearningUnitName = styled.div`
  color: ${COLORS.MIDNIGHTBLUE};
  font-size: 0.8rem;
  font-weight: 600;
`;

export const AssessmentOpportunityTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatusIcon = styled(Icon)`
  font-size: 18px;
  color: ${COLORS.GREY};
`;

export const RightIcon = styled(Icon).attrs({ type: "arrow-right" })``;

export const RemoveIcon = styled(Icon).attrs({
  type: "delete",
})`
  margin-right: 15px;
`;

export const LoadingIcon = styled(Icon).attrs({
  type: "loading",
})`
  margin-right: 15px;
`;

export const StyledLink = styled(Link)`
  word-break: break-all;
  color: rgba(0, 0, 0, 0.65);
  &:hover {
    color: ${COLORS.BLUE};
  }
`;

export const QuestionIcon = styled(Icon).attrs({ type: "warning", theme: "filled", style: { color: "red" } })``;

export const ConfirmDelete = styled(Popconfirm).attrs({
  placement: "left",
  okText: "Yes",
  cancelText: "No",
  title: "Are you sure you want to delete?",
})``;

export const TitleRow = styled(Row)`
  @media only screen and ${UpToMobileWidth} {
    padding: 0px 10px;
  }
`;

export const TitleLeftArea = styled(Col).attrs({
  span: 18
})``;

export const TitleRightArea = styled(Col).attrs({
  span: 6
})`
  text-align: end;
`;
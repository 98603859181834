import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Menu, Icon, Dropdown, Progress } from "antd";
import { ExportButton, ExportButtonLoading } from "./styled";
import { GET_PUBLISHED_SUBJECT_WITH_TUTORIAL_WITH_LEARNING_UNITS } from "./queries";
import { buildPDF } from "./pdf";
import { buildEPUB } from "./epub";

const Export = ({ subjectId }) => {
  const [exporting, setExporting] = useState(false);
  const [percent, setPercent] = useState(0);

  const { loading, error, data } = useQuery(GET_PUBLISHED_SUBJECT_WITH_TUTORIAL_WITH_LEARNING_UNITS, {
    variables: { subjectId },
    fetchPolicy: "no-cache",
  });

  if (loading || error) return null; // hide export button if loading or error

  const EXPORT_TYPES = [
    {
      type: "PDF",
      icon: "file-pdf",
      handleExport: (subjectData) => buildPDF({ subjectData, setExporting, setPercent }),
    },
    {
      type: "EPUB",
      icon: "file-text",
      handleExport: (subjectData) => buildEPUB({ subjectData, setExporting, setPercent }),
    },
  ];

  const handleExportType = (e) => {
    EXPORT_TYPES.find(({ type }) => type === e.key).handleExport(data.getPublishedSubjectWithTutorialWithLearningUnits);
  };

  return exporting ? (
    <>
      <ExportButtonLoading>Exporting...</ExportButtonLoading>
      {!!percent && <Progress percent={percent} status="active" />}
    </>
  ) : (
    <Dropdown
      overlay={
        <Menu onClick={handleExportType}>
          {EXPORT_TYPES.map(({ type, icon }) => (
            <Menu.Item key={type}>
              <Icon type={icon} />
              {type}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <ExportButton>
        Export <Icon type="down" />
      </ExportButton>
    </Dropdown>
  );
};

export default Export;

import React, { useState, useRef, useEffect, Fragment } from "react";
import { CustomText, CustomInput, EditIcon, EditableTextContainer } from "./styled";

const ENTER_KEY_CODE = 13;
const fn = () => {};

const EditableText = ({ value = "EditableText", onChange = fn, onFocus = fn, onBlur = fn, isEditable = true, onEditStateChange = fn }) => {
  const [editText, setEditText] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (editText) {
      inputRef.current.focus();
    }
    onEditStateChange(editText);
  }, [editText]);

  const editCompleted = (e) => {
    setEditText(false);
    onBlur(e.target.value);
  };

  const onEnterCheck = (e) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      editCompleted(e);
    }
  };

  return (
    <EditableTextContainer>
      {editText ? (
        <Fragment>
          <CustomInput
            value={value}
            ref={inputRef}
            onChange={(e) => onChange(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onBlur={editCompleted}
            onKeyDown={onEnterCheck}
          />
        </Fragment>
      ) : (
        <Fragment>
          <CustomText>{value}</CustomText>
          {isEditable ? (
            <EditIcon
              onClick={(e) => {
                e.stopPropagation();
                setEditText(true);
                onFocus(e.target.value);
              }}
            />
          ) : (
            ""
          )}
        </Fragment>
      )}
    </EditableTextContainer>
  );
};

export default EditableText;

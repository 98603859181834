import gql from "graphql-tag";

export const UPDATE_CONTENT_SNAPSHOT = gql`
  mutation updateContentSnapshot(
    $learningUnitId: String!
    $tutorialId: String!
    $content: LearningUnitInput!
    $sessionUUID: String!
    $lastUpdateRequestTimeStamp: String!
  ) {
    updateContentSnapshot(
      learningUnitId: $learningUnitId
      tutorialId: $tutorialId
      content: $content
      sessionUUID: $sessionUUID
      lastUpdateRequestTimeStamp: $lastUpdateRequestTimeStamp
    ) {
      saveStatus
      lastSaveTimeStamp
      lastSnapshotSaveTimeStamp
      lastModifiedByContact {
        name
      }
    }
  }
`;

export const UPDATE_CONTENT_SNAPSHOT_AND_LEARNING_UNIT = gql`
  mutation updateContentSnapshotAndLearningUnit(
    $learningUnit: LearningUnitInput!
    $learningUnitId: String!
    $tutorialId: String!
    $sessionUUID: String!
    $lastUpdateRequestTimeStamp: String!
  ) {
    updateContentSnapshotAndLearningUnit(
      learningUnit: $learningUnit
      learningUnitId: $learningUnitId
      tutorialId: $tutorialId
      sessionUUID: $sessionUUID
      lastUpdateRequestTimeStamp: $lastUpdateRequestTimeStamp
    ) {
      _id
      name
      type
      saveStatus
      lastSaveTimeStamp
      lastSnapshotSaveTimeStamp
      lastModifiedByContact {
        name
      }
    }
  }
`;

export const UPDATE_SUBJECT_ASSESSMENT_DISPLAY_NAME = gql`
  mutation updateSubjectAssessmentDisplayName($subjectAssessmentId: String!, $tutorialId: String!, $displayName: String!) {
    updateSubjectAssessmentDisplayName(subjectAssessmentId: $subjectAssessmentId, tutorialId: $tutorialId, displayName: $displayName) {
      _id
      displayName
    }
  }
`;

export const SUBMIT_LEARNING_UNIT_FOR_REVIEW = gql`
  mutation submitLearningUnitForReview(
    $learningUnitId: String!
    $tutorialId: String!
    $sessionUUID: String!
    $lastUpdateRequestTimeStamp: String!
  ) {
    submitLearningUnitForReview(
      learningUnitId: $learningUnitId
      tutorialId: $tutorialId
      sessionUUID: $sessionUUID
      lastUpdateRequestTimeStamp: $lastUpdateRequestTimeStamp
    ) {
      _id
      name
      type
      saveStatus
      lastSaveTimeStamp
    }
  }
`;

export const MAKE_LEARNING_UNIT_EDITABLE = gql`
  mutation makeLearningUnitEditable($learningUnitId: String!, $tutorialId: String!) {
    makeLearningUnitEditable(learningUnitId: $learningUnitId, tutorialId: $tutorialId) {
      _id
      name
      type
      isPublished
      isArchived
      isSubmittedForReview
      saveStatus
    }
  }
`;

export const PUBLISH_LEARNING_UNIT = gql`
  mutation publishLearningUnit($learningUnitId: String!, $tutorialId: String!) {
    publishLearningUnit(learningUnitId: $learningUnitId, tutorialId: $tutorialId) {
      _id
      name
      type
      isPublished
      isArchived
      isSubmittedForReview
      saveStatus
      currentVersion
      currentVersionSyncId
    }
  }
`;

import React from "react";
import { Layout } from "antd";
import Header from "../container/Header";
import PageFooter from "../container/Footer";
import { ContentLayout } from "./styled";
import SubjectBuilder from "../container/SubjectBuilder";

const SubjectBuilderPage = ({ computedMatch: match }) => {
  const { subjectId } = match.params;
  return (
    <Layout>
      <Header fixed={true} />
      <ContentLayout>
        <SubjectBuilder subjectId={subjectId} />
      </ContentLayout>
      <PageFooter />
    </Layout>
  );
};

export default SubjectBuilderPage;

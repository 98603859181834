import React from "react";
import Snippet from "../../../presentational/Snippet";
import { SolutionCard } from "../../../presentational/Question/styled";
import { SnippetContainer } from "./styled";
import EnhancedEditorImages from "../../../presentational/EnhancedEditorImages";

const CaseStudySolutionSnippetPreview = ({ html, styleClass, pageURL }) => {
  return (
    <SolutionCard title={"SOLUTION"}>
      <SnippetContainer>
        <EnhancedEditorImages>
          <Snippet html={html} styleClass={styleClass} pageURL={pageURL} />
        </EnhancedEditorImages>
      </SnippetContainer>
    </SolutionCard>
  );
};

export default CaseStudySolutionSnippetPreview;

import React, { createContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_USERS, GET_USER_ID } from "./queries";

/**
 * @description Creates the Context API for Collaboration
 *              e.g. const collaborationContext = useContext(CollaborationContext.Context);
 */
const Context = createContext({});

/**
 * @description Performs queries and creates Context Provider
 *              with values corresponding to the results of those queries
 *              e.g. <CollaborationContext.Provider> your components </<CollaborationContext.Provider>>
 * @param {object} children - Child components
 */
const Provider = ({ children }) => {

  // Query userId
  const { loading: userIdLoading, error: userIdError, data: userIdData } = useQuery(GET_USER_ID);
  const userId = userIdData && userIdData.getUserId;

  // Query users
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(GET_USERS);
  const users = usersData && usersData.getUsers;

  const collaborationContext = {
    userId, // the login user
    users,
  };

  return <Context.Provider value={collaborationContext}>{children}</Context.Provider>;
};

/**
 * @description When not using useContext, we can use Consumer as alternate syntax
 *              e.g. <CollaborationContext.Consumer>{ value => ... }</CollaborationContext.Consumer>
 */
const { Consumer } = Context;

export default {
  Context,
  Provider,
  Consumer,
}
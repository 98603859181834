import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Footer, FooterItem, LinkTo } from "./styled";
import { GET_COPYRIGHT_YEAR } from "./queries";

const PageFooter = () => {
  const { data } = useQuery(GET_COPYRIGHT_YEAR);
  return (
    <Footer>
      <FooterItem>
        <LinkTo href="https://about.unimelb.edu.au/strategy/governance/compliance-obligations/privacy">PRIVACY POLICY</LinkTo>
      </FooterItem>
      |{data && <FooterItem>COPYRIGHT© {data.getCopyrightYear}</FooterItem>}
    </Footer>
  );
};

export default PageFooter;

import styled, { css } from "styled-components";
import { Button, Typography, Tooltip, Divider } from "antd";
import { MobileVersionXS, UpToContentPageWidth, UpToTabletWidth } from "../../../../constants/mediaQueries";
import COLORS from "../../../../constants/colors";
import { HEADER_HEIGHT, STICKY_MENU_WIDTH, STICKY_MENU_WIDTH_WITH_MARGIN } from "../../../../constants/miscellaneous";
import { FullWidth } from "../../../../utils/globalStyled";

export const BuilderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  /* width: 100%; */
  /* min-height: 100%; */
`;

export const BuilderInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 100%;
  // max-width: 960px;
  // max-width: 1260px;
  max-width: ${({ showComments }) => (!showComments ? "960" : "1260")}px;
  // background: blue;
`;

// STICKY MENU

const collapsedStyle = css`
  /* Not using the colors constants here because it'll cause a bug */
  background-color: white;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
`;

export const EditButton = styled(Button).attrs({ type: "primary", icon: "form" })`
  margin-top: 10px;
  margin-right: 5px;
  width: 150px;
`;

export const PublishButton = styled(Button).attrs({ type: "primary", icon: "check-square" })`
  margin-top: 10px;
  margin-left: 5px;
  width: 150px;
`;

// Reusable buttons for menubar
const button = styled(Button).attrs({ type: "primary", shape: "circle" })`
  .ant-btn-primary {
    box-shadow: none;
  }
`;
const ghostButton = styled(button).attrs({ ghost: true })`
  border: transparent;
  box-shadow: none;
`;
const commandButton = styled(ghostButton)`
  &&& {
    color: ${({ disabled }) => disabled && `${COLORS.GREY}`};
  }
  > .anticon {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }
`;

export const StickyContainer = styled.div.attrs({
  className: "StickyContainer",
})`
  position: sticky;
  width: ${STICKY_MENU_WIDTH}px;
  height: 100vh;
  left: 0;
  top: 0;
  margin-right: 10px;
  padding-top: 103px; /* Hard coded value of ediable title */
  /* To avoid blocking the tooltips and dialog box which are z-index: 1060 */
  /* Edit to higher numbers if other components just happens to overlap */
  z-index: 1050;

  /* display: flex;
  justify-content: flex-start;
  @media only screen and ${MobileVersionXS} {
    justify-content: center;
  } */
`;

export const StickyComponent = styled.div`
  width: 100%;
`;

export const MenuBar = styled.div`
  width: 100%;
  padding: 1rem 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > *:not([role="separator"]) + *:not([role="separator"]) {
    margin-top: 1.5rem;
  }

  @media only screen and ${UpToTabletWidth} {
    ${({ collapsedMenu }) => collapsedMenu && `${collapsedStyle}`};
  }
  @media only screen and ${UpToContentPageWidth} {
    ${collapsedStyle};
  }
`;

// Menu Items
export const SubmitForReviewButton = styled(button).attrs({ icon: "file-done" })`
  > .anticon {
    font-size: 1.2rem;
    line-height: 1.5rme;
  }
`;
export const PreviewToggleButton = styled(button).attrs({ type: "primary", ghost: true })`
  > .anticon {
    line-height: 1.5rem;
  }
`;
export const AddNewButton = styled(ghostButton).attrs({ icon: "plus-circle" })`
  > .anticon {
    font-size: 1.75rem;
  }
`;
export const UndoButton = styled(commandButton).attrs({
  icon: "undo",
})``;
export const RedoButton = styled(commandButton).attrs({
  icon: "redo",
})``;
export const ScrollToTopButton = styled(commandButton).attrs({
  icon: "up",
})``;

export const ScrollToTopButtonFixed = styled(ScrollToTopButton)`
  position: fixed;
  right: 20px;
  bottom: 20px;
`;

export const ShowCommentsButton = styled(button).attrs({ icon: "message", type: "primary" })`
  > .anticon {
    line-height: 1.5rem;
  }
`;
export const SaveStatusText = styled.div.attrs({
  className: "SaveStatusText",
})`
  color: ${COLORS.GREY};
  font-size: 0.8rem;
  text-align: center;
  text-transform: capitalize;
`;

// Adjusts placement whether if table of contents is open
export const Tips = styled(Tooltip).attrs((props) => ({
  placement: props.placement,
}))``;

// END STICKY MENU

export const LearningUnitEditableTitle = styled(Typography.Paragraph)`
  &&& {
    display: flex;
    font-size: 1.6rem;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    & > textarea {
      font-size: 1.4rem;
      max-width: 600px;
    }
    & > i {
      display: none;
    }
  }
`;

export const EditableContainer = styled.div.attrs({
  className: "EditableContainer",
})`
  position: relative;
  width: 100%;
  max-width: 900px;
  padding: 30px 10px;
  /* margin: 30px 0; */
`;

export const HiddenDivToRemoveInlineEditorFocusOnDrag = styled.div.attrs({ tabIndex: 0 })`
  opacity: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: 0;
`;

export const PaletteContainer = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 50px 0;
  // max-width: 900px;
  // max-width: 1200px;
  max-width: ${({ showComments }) => (!showComments ? "900" : "1200")}px;
  // background: purple;
  min-height: 250px;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const PageMessage = styled.div`
  background-color: ${COLORS.VERYLIGHTBLUE};
  width: 100%;
  padding: 20px 20px 20px 60px;
  margin-bottom: 20px;
  border-radius: 3px;
  border: 1px solid;
  position: relative;
  border-color: ${COLORS.LIGHTBLUE};
  text-align: center;
  font-size: 1.1rem;
`;

export const LearningUnitContainer = styled.div`
  width: 100%;
  // max-width: 900px;
  max-width: 1200px;
  // background: brown;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NotificationButton = styled(Button).attrs({ size: "small", type: "primary" })``;

export const Divide = styled.div`
  height: 1px;
  width: 30px;
  background-color: ${COLORS.LIGHTERGREY};
`;

export const CharCount = styled.span`
  position: absolute;
  right: 10px;
  top: 20px;
  font-size: 14px;
  color: #999;
`;

export const FullScreenContainer = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 1060; /* To avoid blocking the tooltips and dialog box which are z-index: 1060 */
  background-color: ${COLORS.WHITE};
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FullScreenInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 66.7%;
  height: 66.7%;
  transform: scale(1.5);
  transform-origin: center center;
`;

export const SlideShowControl = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1070;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  gap: 10px;
`;

export const SlideShowStartButton = styled(commandButton).attrs({ icon: "fullscreen" })`
  position: fixed;
  right: 50px;
  bottom: 20px;
  z-index: 1080;
`;

export const SlideShowPrevButton = styled(commandButton).attrs({ icon: "left-circle" })``;

export const SlideShowNextButton = styled(commandButton).attrs({ icon: "right-circle" })``;

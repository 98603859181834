import gql from "graphql-tag";

export const GET_SUBJECT_BUILDER_MANIFEST = gql`
  query getSubjectBuilderManifest($subjectId: String!) {
    getSubjectBuilderManifest(subjectId: $subjectId) {
      subject {
        _id
        name
      }
      course {
        _id
        name
      }
      tutorialsWithLearningUnits {
        _id
        name
        accessLevel
        subjectTutorials {
          _id
          order
          displayName
        }
        learningUnits {
          _id
          name
          order
          isPublished
          isArchived
          isSubmittedForReview
          subjectAssessments {
            _id
            name
            displayName
          }
        }
      }
      templates {
        _id
        name
        order
      }
    }
  }
`;

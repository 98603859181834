import styled from "styled-components";
import { Link as StyledLink } from "react-router-dom";
import { Icon, Row, Col } from "antd";
import COLORS from "../../../constants/colors";

export const CardsRow = styled(Row)`
  margin: 2rem auto 0 auto;
  max-width: 1600px;
`;

export const CardsCol = styled(Col).attrs({
  md: 12,
  xl: 8,
  xxl: 6
})`
  margin-bottom: 1.5rem;
`;

export const CourseLayout = styled.div`
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  &&& {
    position: relative;
    min-width: 250px;
    max-width: 320px;
    height: 375px;
    border: 2px solid #79abff;
    border-radius: 10px;
    background-color: ${COLORS.WHITE};
    -moz-box-shadow: 1px 2px 10px #79ABFF61;
    -webkit-box-shadow: 1px 2px 10px #79ABFF61;
    box-shadow: 1px 2px 10px #79ABFF61;
    margin: 0 auto;
    overflow: hidden;
  }
`;

export const CardCover = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  height: 60%;
  max-width: 320px;
  overflow: hidden;
  background: ${COLORS.BRIGHTGRAY};
  span {
    height: 100%;
  }
  img {
    width: 320px;
    height: 100%;
    display: block;
  }
`;

export const CardCoverOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: .5s ease;
  background-color: ${COLORS.WHITE};

  ${CardCover}:hover & {
    cursor: pointer;
    opacity: .8;
  }
`;

export const OverlayText = styled.div`
  color: ${COLORS.MIDNIGHBLUE};
  font-size: .8rem;
  font-weight: bold;
  letter-spacing: .4rem;
`;

export const OverlayIcon = styled(Icon).attrs({
  theme: "outlined"
})`
  margin: 6px;
  padding: 5px;
`;

export const CardBody = styled.div`
  max-height: 50%;
  transition: all 0.2s ease;
  background-color: ${COLORS.WHITE};
`;

const HeaderShadow = "0px 6px 5px 0px rgba(0,0,0,0.14);";

export const CardBodyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px;
  background-color: ${COLORS.WHITE};
  -webkit-box-shadow: ${(props) => (props.show ? HeaderShadow : "none")};
  -moz-box-shadow: ${(props) => (props.show ? HeaderShadow : "none")};
  box-shadow: ${(props) => (props.show ? HeaderShadow : "none")};
  margin-bottom: ${(props) => (props.show ? "9px" : "0")};
`;

export const CardBodyContent = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0 16px 16px 16px;
  display: ${(props) => (props.show ? "block" : "none")};
`;

export const CardTitle = styled.p`
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.show ? 1 : 3)};
  -webkit-box-orient: vertical;
  font-weight: bold;
  margin-right: 0.5em;
  margin-bottom: 0 !important;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
`;

export const ExpandIndicator = styled(Icon).attrs((props) => ({
  type: props.type
}))`
  margin: 0;
  font-size: 24px;
`;

export const CardLogo = styled.img.attrs({
  alt: ""
})`
  height: 40px;
`;

export const CardResumeButton = styled.a.attrs(({ to }) => ({
  href: to
}))`
  &&& {
    position: absolute;
    right: 8px;
    bottom: 0;
    padding: 0 7px;
    border: 1px solid ${COLORS.WHITE};
    border-radius: 2px;
    font-weight: bold;
    text-align: center;
    margin: 1em 0;
  }
`;

export const CardList = styled.div`
  overflow-y: hidden;
  background-color: ${COLORS.WHITE};
  transition-duration: 0.2s;
  transition-timing-function: ease;
`;

export const CardListHeader = styled.div`
  cursor: pointer;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  padding: 1em;
`;

export const CardListHeaderText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.5em;
  margin-bottom: 0;
`;

export const CardListItem = styled.li`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${COLORS.WHITE};
  padding: 0.5em 1em;
  border-bottom: 3px solid #bbbcc4;
  margin-bottom: 0.1em;
`;

export const ItemIcon = styled(Icon).attrs({
  theme: "filled"
})`
  margin: 6px;
  padding: 5px;
`;

export const Link = styled(StyledLink)`
  color: #172b4d;
  display: block;
  padding: 5px;
`;

export const A = styled.a`
  color: #172b4d;
  display: block;
  font-size: 14px;
`;

export const ListContainer = styled.div`display: ${(props) => (props.show ? "block" : "none")};`;

export const SubjectSection = styled.section`
  max-height: 291px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: .4em;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    /* half of the scrollbar width */
    border-radius: .2em;
    background-color: ${COLORS.GREY};
  }
`;

export const SubjectListItem = styled.div`
  display: flex;
  align-items: center;
  min-height: 49px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${COLORS.WHITE};
  padding: 0.5em 1em;
  border-bottom: 3px solid #bbbcc4;
  margin-bottom: 0.1em;
`;

const YES = "Yes";
const NO = "No";

// If (true), return "Yes", anything else returns null
const boolToTerm = (bool) => (bool ? YES : NO);

// Return true if parameter = "Yes"
const termToBool = (term) => term === YES;

module.exports = {
  boolToTerm,
  termToBool,
};

import React from "react";
import { DragHandleContainer } from "./styled";
import ReorderIcon from "./svg/reorder.svg";

const DragHandle = (props) => (
  <DragHandleContainer {...props}>
    <ReorderIcon />
  </DragHandleContainer>
);

export default DragHandle;

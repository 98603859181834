import styled from "styled-components";
import COLORS from "../../../../constants/colors";
import { PLACEHOLDER_LU_COMPONENT_HEIGHT } from "../../../../constants/miscellaneous";
import { Content } from "../styled";

export const EditorContainer = styled(Content)`
  padding: 0;
  border-color: ${COLORS.GREY};
  background-color: ${COLORS.LIGHTGREY};
  .ck.ck-content.ck-editor__editable {
    min-height: ${PLACEHOLDER_LU_COMPONENT_HEIGHT}px;
  }
`;

import gql from "graphql-tag";

export const CLONE_EXISTING_LEARNING_UNIT_VERSION = gql`
  mutation cloneExistingLearningUnitVersion($learningUnitVersionId: String!) {
    cloneExistingLearningUnitVersion(learningUnitVersionId: $learningUnitVersionId) {
      _id
      learningUnitName
    }
  }
`;

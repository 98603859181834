export const ASSESSMENT_OPPORTUNITY_STATUS = {
  COMPLETED: "Completed",
  FAILED: "Failed",
  IN_PROGRESS: "In Progress",
  RESITS_REQUIRED: "Resits Required",
  ACTION_REQUIRED: "Action Required",
};

export const LEARNING_UNIT_STATUS = {
  COMPLETED: "Completed",
  FAILED: "Failed",
  IN_PROGRESS: "In Progress",
  RESITS_REQUIRED: "Resits Required",
  ACTION_REQUIRED: "Action Required",
  PUBLISHED: "Published",
};

export const TUTORIAL_STATUS = {
  COMPLETED: "Completed",
  FAILED: "Failed",
  IN_PROGRESS: "In Progress",
  RESITS_REQUIRED: "Resits Required",
  ACTION_REQUIRED: "Action Required",
};

import React, { useEffect, useState } from "react";
import { checkSession, login } from "../../../utils/authHelpers";
import { renderLoadingMenu } from "../Callback";
import { Redirect } from "react-router-dom/";

const RedirectRoute = () => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    const check = async () => {
      try {
        await checkSession();
        setIsChecked(true);
      } catch (error) {
        console.error("RedirectRoute checkSession error", error);
        login();
      }
    };
    check();
  }, []);

  return !isChecked ? renderLoadingMenu() : <Redirect to={"/"} />;
};

export default RedirectRoute;

import { getNewAnswerOption, getNewQuestion } from "./helpers";

export const ADD_QUESTION = "ADD_QUESTION";
export const REMOVE_QUESTION = "REMOVE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const ADD_ANSWER_OPTION = "ADD_ANSWER_OPTION";
export const REMOVE_ANSWER_OPTION = "REMOVE_ANSWER_OPTION";
export const UPDATE_ANSWER_OPTION = "UPDATE_ANSWER_OPTION";
export const UPDATE_CORRECT_ANSWER_OPTION = "UPDATE_CORRECT_ANSWER_OPTION";
export const RESET_QUESTIONS = "RESET_QUESTIONS";
export const ADD_SCALE_OPTIONS_TO_ALL_QUESTIONS = "ADD_SCALE_OPTIONS_TO_ALL_QUESTIONS";
export const UPDATE_SCALE_OPTIONS_TO_ALL_QUESTIONS = "UPDATE_SCALE_OPTIONS_TO_ALL_QUESTIONS";
export const REMOVE_SCALE_OPTIONS_TO_ALL_QUESTIONS = "REMOVE_SCALE_OPTIONS_TO_ALL_QUESTIONS";
export const UPDATE_QUESTION_TEXT_IN_ALL_QUESTIONS = "UPDATE_QUESTION_TEXT_IN_ALL_QUESTIONS";
export const REMOVE_QUESTION_TEXT_IN_ALL_QUESTIONS = "REMOVE_QUESTION_TEXT_IN_ALL_QUESTIONS";
export const SET_DEFAULT_SCALE_OPTIONS = "SET_DEFAULT_SCALE_OPTIONS";

export const addNewQuestion = (lucId, type, answerOptions) => ({
  type: ADD_QUESTION,
  lucId,
  question: getNewQuestion(type, answerOptions),
});
export const removeQuestion = (lucId, questionId, questionIndex) => ({ type: REMOVE_QUESTION, lucId, questionId, questionIndex });
export const updateQuestion = (lucId, questionId, questionIndex, questionFields, clearUndo) => ({
  type: UPDATE_QUESTION,
  lucId,
  questionId,
  questionIndex,
  questionFields,
  clearUndo,
});

export const addAnswerOption = (lucId, questionId, questionIndex) => ({
  type: ADD_ANSWER_OPTION,
  lucId,
  questionId,
  questionIndex,
  answerOption: getNewAnswerOption(),
});
export const removeAnswerOption = (lucId, questionId, questionIndex, answerOptionId, answerOptionIndex) => ({
  type: REMOVE_ANSWER_OPTION,
  lucId,
  questionId,
  questionIndex,
  answerOptionId,
  answerOptionIndex,
});
export const updateAnswerOption = (lucId, questionId, questionIndex, answerOptionId, answerOptionIndex, answerOptionFields, clearUndo) => ({
  type: UPDATE_ANSWER_OPTION,
  lucId,
  questionId,
  questionIndex,
  answerOptionId,
  answerOptionIndex,
  answerOptionFields,
  clearUndo,
});
export const updateCorrectAnswerOptionForQuestion = (lucId, questionId, questionIndex, answerOptionId, answerOptionIndex, clearUndo) => ({
  type: UPDATE_CORRECT_ANSWER_OPTION,
  lucId,
  questionId,
  questionIndex,
  answerOptionId,
  answerOptionIndex,
  clearUndo,
});
export const resetQuestions = (lucId, question) => ({
  type: RESET_QUESTIONS,
  lucId,
  question,
});
export const addScaleOptionToAllQuestions = (lucId) => ({
  type: ADD_SCALE_OPTIONS_TO_ALL_QUESTIONS,
  lucId,
  answerOption: getNewAnswerOption(),
});
export const setDefaultScaleOptions = (lucId) => ({
  type: SET_DEFAULT_SCALE_OPTIONS,
  lucId,
  answerOptions: {
    yesOption: getNewAnswerOption("Yes", 0),
    noOption: getNewAnswerOption("No", 1),
    naOption: getNewAnswerOption("N/A", 2),
    otherOption: getNewAnswerOption("Other", 3),
  },
});
export const updateScaleOptionToAllQuestions = (lucId, answerOptionIndex, answerOptionFields, clearUndo) => ({
  type: UPDATE_SCALE_OPTIONS_TO_ALL_QUESTIONS,
  lucId,
  answerOptionIndex,
  answerOptionFields,
  clearUndo,
});
export const removeScaleOptionToAllQuestions = (lucId, answerOptionIndex) => ({
  type: REMOVE_SCALE_OPTIONS_TO_ALL_QUESTIONS,
  lucId,
  answerOptionIndex,
});
export const updateQuestionTextInAllQuestions = (lucId, questionIndex, questionFields, clearUndo) => ({
  type: UPDATE_QUESTION_TEXT_IN_ALL_QUESTIONS,
  lucId,
  questionIndex,
  questionFields,
  clearUndo,
});
export const removeQuestionTextInAllQuestions = (lucId, questionIndex) => ({
  type: REMOVE_QUESTION_TEXT_IN_ALL_QUESTIONS,
  lucId,
  questionIndex,
});

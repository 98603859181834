import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import TableOfContents from "../../presentational/TableOfContents";
import { SidebarContainer, ErrorMessage } from "./styled";
import { GET_TABLE_OF_CONTENTS } from "./queries";
import { useWindowSize } from "../../../utils/hooks";
import { extractMessageFromGraphQLError } from "../../../utils/helpers";

/**
 * @description Sidebar component which shows the Table of Contents on a learning unit builder page
 * @param {boolean} collapsedMenu - Initial value for the collapsable menu
 * @param {string}  subjectId - Subject ID
 * @param {string}  tutorialId - Tutorial ID
 * @param {string}  learningUnitId - Learning Unit Id
 * @param {boolean} closeMenuIfOnMobile -
 * @param {boolean} isMobile -
 * @param {object}  tutorials - List of tutorials
 * @param {number}  viewHeight - Window view height
 */
const Sidebar = ({ collapsedMenu, subjectId, tutorialId, learningUnitId, viewHeight, setRefetch = () => {} }) => {
  const { data, error, refetch } = useQuery(GET_TABLE_OF_CONTENTS, {
    variables: {
      subjectId,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setRefetch({ refetch });
  }, []);

  return (
    <SidebarContainer viewHeight={viewHeight} collapsedMenu={collapsedMenu}>
      {!!error ? (
        <ErrorMessage>Error: {extractMessageFromGraphQLError(error)}</ErrorMessage>
      ) : (
        !!data && (
          <TableOfContents
            tutorials={data.getTableOfContents}
            subjectId={subjectId}
            selectedLearningUnitId={learningUnitId}
            selectedTutorialId={tutorialId}
            collapse={false}
          />
        )
      )}
    </SidebarContainer>
  );
};

Sidebar.propTypes = {
  collapsedMenu: PropTypes.bool.isRequired,
  subjectId: PropTypes.string.isRequired,
  tutorialId: PropTypes.string.isRequired,
  learningUnitId: PropTypes.string.isRequired,
  viewHeight: PropTypes.number.isRequired,
};

export default Sidebar;

import gql from "graphql-tag";

export const UPDATE_TUTORIAL_NAME = gql`
  mutation updateSubjectTutorialDisplayName($tutorialId: String!, $subjectTutorialId: String!, $name: String!) {
    updateSubjectTutorialDisplayName(tutorialId: $tutorialId, subjectTutorialId: $subjectTutorialId, name: $name) {
      _id
      name
    }
  }
`;

// mutation{
//     updateSubjectTutorialDisplayName(tutorialId:"a345D0000000FVQQA2", name:"Non test tutorial"){
//       _id
//       name
//     }
//   }

export const ADD_FILE = "ADD_FILE";
export const UPDATE_FILE = "UPDATE_FILE";
export const REMOVE_FILE = "REMOVE_FILE";
export const REORDER_FILE = "REORDER_FILE";
export const SAVE_FILES_AND_NAMES = "SAVE_FILES_AND_NAMES";

/**
 * @description Add a file (i.e. Uploads a new file)
 * @param {object} newFiles - List of files to add
 * @param {object} fileName - List of file names 
 */
export const addFile = (newFiles, fileName) => ({
  type: ADD_FILE,
  newFiles,
  fileName,
});

/**
 * @description Update a file metadata field
 * @param {number} fileIndex - Index of file to update
 * @param {object} fileMetadataFields - Object containing file field and values to update
 */
export const updateFile = (fileIndex, fileMetadataFields) => ({
  type: UPDATE_FILE,
  fileIndex,
  fileMetadataFields,
});

/**
 * @description Remove a file from the list of files
 * @param {number} fileIndex
 */
export const removeFile = (fileIndex) => ({
  type: REMOVE_FILE,
  fileIndex,
});

/**
 * @description Reorder the file in the file list
 * @param {number} sourceIndex - Source list index
 * @param {number} destinationIndex - Target list index
 */
export const reorderFile = (sourceIndex, destinationIndex) => ({
  type: REORDER_FILE,
  sourceIndex,
  destinationIndex,
});

/**
 * @description Save the files by committing them to the global dispatch
 * @param {string} lucId - Learning Unit Component Id
 * @param {object} files - List of files to submit to the global dispatch
 * @param {object} filenames - List of file names to submit to the global dispatch
 */
export const saveFilesAndNames = (lucId, files, filenames) => ({
  type: SAVE_FILES_AND_NAMES,
  lucId,
  files,
  filenames,
})

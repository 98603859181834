import React from "react";
import styled from "styled-components";
import { Strong } from "../../../../utils/globalStyled";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: .4rem;
  }
`;

export const Label = styled(Strong)`
  white-space: nowrap;
`;
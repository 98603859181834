import { List } from "immutable";
import {
  ADD,
  REORDER,
  TOGGLE_PREVIEW_MODE,
  UNDO,
  REDO,
  SAVE_OUTDATED,
  SAVE_START,
  SAVE_SUCCESS,
  SAVE_FAILED,
  UPDATE_LEARNING_UNIT,
  SET_READ_ONLY,
  RESET_STATE,
  SUBMITTED_FOR_REVIEW_SUCCESS,
  PUBLISHED_SUCCESS,
  EDITABLE_SUCCESS,
  SHOW_COMMENTS,
  TOGGLE_SHOW_COMMENTS,
} from "./actions";
import {
  ADD_QUESTION,
  REMOVE_QUESTION,
  UPDATE_QUESTION,
  ADD_ANSWER_OPTION,
  REMOVE_ANSWER_OPTION,
  UPDATE_ANSWER_OPTION,
  UPDATE_CORRECT_ANSWER_OPTION,
  RESET_QUESTIONS,
  ADD_SCALE_OPTIONS_TO_ALL_QUESTIONS,
  UPDATE_SCALE_OPTIONS_TO_ALL_QUESTIONS,
  REMOVE_SCALE_OPTIONS_TO_ALL_QUESTIONS,
  UPDATE_QUESTION_TEXT_IN_ALL_QUESTIONS,
  REMOVE_QUESTION_TEXT_IN_ALL_QUESTIONS,
  SET_DEFAULT_SCALE_OPTIONS,
} from "../LearningUnitComponentBuilder/AssessmentOpportunityBuilder/actions";
import { CLONE, REMOVE, UPDATE } from "../LearningUnitComponentBuilder/actions";
import { SAVE_FILES } from "../LearningUnitComponentBuilder/MediaCarouselBuilder/actions";
import { SAVE_FILES_AND_NAMES } from "../LearningUnitComponentBuilder/FileUploadBuilder/actions";
import { getNewItemId, initState } from "./Builder/helpers";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const itemAtStartIndex = list.get(startIndex);
  return list.delete(startIndex).insert(endIndex, itemAtStartIndex);
};

const update = (list, _id, updatedItem) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({ ...cmp, ...updatedItem }));
};

const computeStacks = (state, clearUndo = false) => {
  const undoStack = clearUndo ? state.undoStack.clear() : state.undoStack.push(state.learningUnitComponents);
  const redoStack = state.redoStack.clear();
  return {
    undoStack,
    redoStack,
  };
};

const cloneComponentAndAddToList = (learningUnitComponents, index) => {
  const cmpToClone = learningUnitComponents.get(index);
  const _id = getNewItemId(cmpToClone.type);
  const cloneCmp = { ...cmpToClone, _id };
  return learningUnitComponents.insert(index + 1, cloneCmp);
};

const addQuestionToLuc = (list, _id, question) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({ ...cmp, questions: cmp.questions.push(question) }));
};

const updateQuestion = (list, _id, questionIndex, questionFields) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({
    ...cmp,
    questions: cmp.questions.update(questionIndex, (question) => ({
      ...question,
      ...questionFields,
    })),
  }));
};

const removeQuestionFromLuc = (list, _id, questionIndex) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({ ...cmp, questions: cmp.questions.delete(questionIndex) }));
};

const addAnswerToQuestionInLuc = (list, _id, questionIndex, answerOption) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({
    ...cmp,
    questions: cmp.questions.update(questionIndex, (question) => ({
      ...question,
      answerOptions: question.answerOptions.push(answerOption),
    })),
  }));
};

const removeAnswerFromQuestionInLuc = (list, _id, questionIndex, answerOptionIndex) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({
    ...cmp,
    questions: cmp.questions.update(questionIndex, (question) => ({
      ...question,
      answerOptions: question.answerOptions.delete(answerOptionIndex),
    })),
  }));
};

const updateAnswerOption = (list, _id, questionIndex, answerOptionIndex, answerOptionFields) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({
    ...cmp,
    questions: cmp.questions.update(questionIndex, (question) => ({
      ...question,
      answerOptions: question.answerOptions.update(answerOptionIndex, (answerOption) => ({ ...answerOption, ...answerOptionFields })),
    })),
  }));
};

const updateCorrectAnswerOption = (list, _id, questionIndex, answerOptionIndex, answerOptionId) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({
    ...cmp,
    questions: cmp.questions.update(questionIndex, (question) => ({
      ...question,
      correctAnswerValue: JSON.stringify([answerOptionId]),
      correctAnswer: `${answerOptionIndex}`,
      answerOptions: question.answerOptions.map((answerOption, answerIndex) => ({
        ...answerOption,
        // the answer vlaue with the index passed from the action is the correct one, the others are false
        correctValue: answerIndex === answerOptionIndex,
      })),
    })),
  }));
};

const resetQuestions = (list, _id, question) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({ ...cmp, questions: List().push(question) }));
};

const saveFiles = (list, _id, files) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => {
    const fileList = cmp.fileList.clear().push(...files);
    return {
      ...cmp,
      fileList,
    };
  });
};

const saveFilesAndNames = (list, _id, files, filenames) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => {
    const fileList = cmp.fileList.clear().push(...files);
    const fileName = cmp.fileName.clear().push(...filenames);
    return {
      ...cmp,
      fileList,
      fileName,
    };
  });
};

const addAnswerOptionToAssessmentQuestions = (list, _id, newAnswerOption) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({
    ...cmp,
    questions: cmp.questions.map((question) => ({
      ...question,
      answerOptions: question.answerOptions.push(newAnswerOption),
    })),
  }));
};

const setDefaultScaleOptions = (list, _id, newAnswerOptions) => {
  const { yesOption, noOption, naOption, otherOption } = newAnswerOptions;
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({
    ...cmp,
    questions: cmp.questions.map((question) => ({
      ...question,
      answerOptions: question.answerOptions.push(yesOption, noOption, naOption, otherOption),
    })),
  }));
};

const updateAnswerOptionToAssessmentQuestions = (list, _id, answerOptionIndex, answerOptionFields) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({
    ...cmp,
    questions: cmp.questions.map((question) => ({
      ...question,
      answerOptions:
        question.type === "Survey"
          ? question.answerOptions.update(answerOptionIndex, (answerOption) => ({
              ...answerOption,
              ...answerOptionFields,
            }))
          : question.answerOptions,
    })),
  }));
};

const removeAnswerOptionToAssessmentQuestions = (list, _id, answerOptionIndex) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({
    ...cmp,
    questions: cmp.questions.map((question) => ({
      ...question,
      answerOptions: question.answerOptions.delete(answerOptionIndex),
    })),
  }));
};

const updateQuestionTextInAssessmentQuestions = (list, _id, questionIndex, questionFields) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({
    ...cmp,
    questions: cmp.questions.update(questionIndex, (question) => ({
      ...question,
      ...questionFields,
    })),
  }));
};

const removeQuestionTextInAssessmentQuestions = (list, _id, questionIndex) => {
  const index = list.findIndex((item) => item._id === _id);
  return list.update(index, (cmp) => ({
    ...cmp,
    questions: cmp.questions.delete(questionIndex),
  }));
};

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_LEARNING_UNIT:
      return { ...state, learningUnitFields: { ...state.learningUnitFields, ...action.learningUnitFields } };
    case SAVE_START:
      return { ...state, snapshotSaveStatusText: "saving..." };
    case SAVE_OUTDATED:
      return { ...state, snapshotSaveStatusText: "", isReadOnly: true };
    case SAVE_FAILED:
      return { ...state, snapshotSaveStatusText: "save failed :(", isReadOnly: true };
    case SAVE_SUCCESS:
      return { ...state, snapshotSaveStatusText: "saved", isReadOnly: false };
    case PUBLISHED_SUCCESS:
      return { ...state, isReadOnly: true, isSubmittedForReview: false, isPublished: true };
    case SUBMITTED_FOR_REVIEW_SUCCESS:
      return { ...state, isReadOnly: true, isSubmittedForReview: true };
    case EDITABLE_SUCCESS:
      return { ...state, isReadOnly: false, isSubmittedForReview: false, isPublished: false };
    case TOGGLE_PREVIEW_MODE:
      return { ...state, isPreviewMode: !state.isPreviewMode, isShowComments: false };
    case SHOW_COMMENTS:
      return { ...state, isShowComments: true };
    case TOGGLE_SHOW_COMMENTS:
      return { ...state, isShowComments: !state.isShowComments };
    case SET_READ_ONLY:
      return { ...state, isReadOnly: action.value };
    case ADD:
      return { ...state, ...computeStacks(state), learningUnitComponents: state.learningUnitComponents.push(action.component) };
    case REMOVE:
      return { ...state, ...computeStacks(state), learningUnitComponents: state.learningUnitComponents.delete(action.index) };
    case CLONE:
      return {
        ...state,
        ...computeStacks(state),
        learningUnitComponents: cloneComponentAndAddToList(state.learningUnitComponents, action.index),
      };
    case REORDER:
      return {
        ...state,
        ...computeStacks(state),
        learningUnitComponents: reorder(state.learningUnitComponents, action.sourceIndex, action.destinationIndex),
      };
    case UPDATE: {
      return {
        ...state,
        ...computeStacks(state, action.clearUndo),
        learningUnitComponents: update(state.learningUnitComponents, action._id, action.component),
      };
    }
    case UNDO:
      return {
        ...state,
        learningUnitComponents: state.undoStack.peek() || state.learningUnitComponents,
        undoStack: state.undoStack.pop(),
        redoStack: state.redoStack.push(state.learningUnitComponents),
      };
    case REDO:
      return {
        ...state,
        learningUnitComponents: state.redoStack.peek() || state.learningUnitComponents,
        undoStack: state.undoStack.push(state.learningUnitComponents),
        redoStack: state.redoStack.pop(),
      };
    case ADD_QUESTION:
      return {
        ...state,
        ...computeStacks(state),
        learningUnitComponents: addQuestionToLuc(state.learningUnitComponents, action.lucId, action.question),
      };
    case REMOVE_QUESTION:
      return {
        ...state,
        ...computeStacks(state),
        learningUnitComponents: removeQuestionFromLuc(state.learningUnitComponents, action.lucId, action.questionIndex),
      };
    case UPDATE_QUESTION:
      return {
        ...state,
        ...computeStacks(state, action.clearUndo),
        learningUnitComponents: updateQuestion(state.learningUnitComponents, action.lucId, action.questionIndex, action.questionFields),
      };
    case ADD_ANSWER_OPTION:
      return {
        ...state,
        ...computeStacks(state),
        learningUnitComponents: addAnswerToQuestionInLuc(
          state.learningUnitComponents,
          action.lucId,
          action.questionIndex,
          action.answerOption,
        ),
      };
    case REMOVE_ANSWER_OPTION:
      return {
        ...state,
        ...computeStacks(state),
        learningUnitComponents: removeAnswerFromQuestionInLuc(
          state.learningUnitComponents,
          action.lucId,
          action.questionIndex,
          action.answerOptionIndex,
        ),
      };
    case UPDATE_ANSWER_OPTION:
      return {
        ...state,
        ...computeStacks(state, action.clearUndo),
        learningUnitComponents: updateAnswerOption(
          state.learningUnitComponents,
          action.lucId,
          action.questionIndex,
          action.answerOptionIndex,
          action.answerOptionFields,
        ),
      };
    case UPDATE_CORRECT_ANSWER_OPTION:
      return {
        ...state,
        ...computeStacks(state, action.clearUndo),
        learningUnitComponents: updateCorrectAnswerOption(
          state.learningUnitComponents,
          action.lucId,
          action.questionIndex,
          action.answerOptionIndex,
          action.answerOptionId,
        ),
      };
    case RESET_QUESTIONS:
      return {
        ...state,
        ...computeStacks(state, action.clearUndo),
        learningUnitComponents: resetQuestions(state.learningUnitComponents, action.lucId, action.question),
      };
    case SAVE_FILES:
      return {
        ...state,
        ...computeStacks(state),
        learningUnitComponents: saveFiles(state.learningUnitComponents, action.lucId, action.files),
      };
    case SAVE_FILES_AND_NAMES:
      return {
        ...state,
        ...computeStacks(state),
        learningUnitComponents: saveFilesAndNames(state.learningUnitComponents, action.lucId, action.files, action.filenames),
      };
    case RESET_STATE: {
      return initState(action.learningUnit);
    }
    case ADD_SCALE_OPTIONS_TO_ALL_QUESTIONS:
      return {
        ...state,
        ...computeStacks(state),
        learningUnitComponents: addAnswerOptionToAssessmentQuestions(state.learningUnitComponents, action.lucId, action.answerOption),
      };
    case SET_DEFAULT_SCALE_OPTIONS:
      return {
        ...state,
        ...computeStacks(state),
        learningUnitComponents: setDefaultScaleOptions(state.learningUnitComponents, action.lucId, action.answerOptions),
      };
    case UPDATE_SCALE_OPTIONS_TO_ALL_QUESTIONS:
      return {
        ...state,
        ...computeStacks(state, action.clearUndo),
        learningUnitComponents: updateAnswerOptionToAssessmentQuestions(
          state.learningUnitComponents,
          action.lucId,
          action.answerOptionIndex,
          action.answerOptionFields,
        ),
      };
    case REMOVE_SCALE_OPTIONS_TO_ALL_QUESTIONS:
      return {
        ...state,
        ...computeStacks(state),
        learningUnitComponents: removeAnswerOptionToAssessmentQuestions(
          state.learningUnitComponents,
          action.lucId,
          action.answerOptionIndex,
        ),
      };
    case UPDATE_QUESTION_TEXT_IN_ALL_QUESTIONS:
      return {
        ...state,
        ...computeStacks(state, action.clearUndo),
        learningUnitComponents: updateQuestionTextInAssessmentQuestions(
          state.learningUnitComponents,
          action.lucId,
          action.questionIndex,
          action.questionFields,
        ),
      };
    case REMOVE_QUESTION_TEXT_IN_ALL_QUESTIONS:
      return {
        ...state,
        ...computeStacks(state, action.clearUndo),
        learningUnitComponents: removeQuestionTextInAssessmentQuestions(state.learningUnitComponents, action.lucId, action.questionIndex),
      };
    default:
      throw new Error();
  }
};

export default reducer;

import React, { useState, forwardRef, useContext } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Enter from "@ckeditor/ckeditor5-enter/src/enter";
import Typing from "@ckeditor/ckeditor5-typing/src/typing";
import Undo from "@ckeditor/ckeditor5-undo/src/undo";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Image from "@ckeditor/ckeditor5-image/src/image";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import UploadAdapter from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TrackChanges from "@ckeditor/ckeditor5-track-changes/src/trackchanges";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Comments from "@ckeditor/ckeditor5-comments/src/comments";
import { IMAGE_UPLOAD_URL, getImageUploadHeaders } from "../../../services/upload/uploadImages";
import { Container, MaxLengthReachedMessageContainer } from "./styled";
import { EDITOR_LENGTH } from "../../../constants/miscellaneous";
import H5PEmbed from "./plugins/H5PEmbed";
import CollaborationContext from "../CollaborationContext";
import Loading from "../../presentational/Loading";
import { sanitizeContentComments, handleCollaborationInit, getCommentThreads, getSuggestions } from "./helpers";

const Editor = forwardRef((props, ref) => {
  const {
    className,
    learningUnitId,
    content,
    comments,
    suggestions,
    inline,
    basic,
    offsetTop,
    maxLength = Number.MAX_SAFE_INTEGER,
    isCommentable,
    isTrackable,
    onEditorCommentToolbarClick,
    onChange = () => {},
  } = props;

  const buffer = maxLength < EDITOR_LENGTH.BUFFER ? maxLength : maxLength - EDITOR_LENGTH.BUFFER;
  const [contentLength, setContentLength] = useState(content && content.length ? content.length : 0);
  const [isLimitOver, setIsLimitOver] = useState(contentLength > buffer);
  const remainingChars = buffer - contentLength;

  const collaborationContext = useContext(CollaborationContext.Context);

  const { userId, users } = collaborationContext;
  if (isCommentable && (!collaborationContext || !userId || !users)) {
    return (
      <div style={{ display: "flex" }}>
        <Container isLimitOver={isLimitOver}>
          <Loading />
        </Container>
      </div>
    );
  }

  const getConfig = (isBasicEditor, isCommentableEditor, isTrackableEditor) => {
    const basicConfig = {
      plugins: [
        Enter,
        Typing,
        Undo,
        Bold,
        Italic,
        Font,
        Highlight,
        Superscript,
        Subscript,
        Image,
        Essentials,
        List,
        Autoformat,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        SimpleUploadAdapter,
        ImageResize,
      ],
      toolbar: {
        viewportTopOffset: offsetTop,
        items: [
          "bold",
          "italic",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "highlight",
          "subscript",
          "superscript",
          "|",
          "imageUpload",
          "|",
          "undo",
          "redo",
        ],
      },
      image: {
        toolbar: [
          "imageStyle:full",
          "imageStyle:alignLeft",
          "imageStyle:alignCenter",
          "imageStyle:alignRight",
          "|",
          "imageTextAlternative",
        ],
        styles: ["full", "alignLeft", "alignCenter", "alignRight"],
      },
      simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: IMAGE_UPLOAD_URL,
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: getImageUploadHeaders(learningUnitId),
      },
      // This value must be kept in sync with the language defined in webpack.config.js.
      language: "en",
      fontSize: {
        options: [9, 11, 13, "default", 17, 19, 21],
      },
    };

    const defaultConfig = {
      plugins: [
        Enter,
        Typing,
        Undo,
        Bold,
        Italic,
        Font,
        Highlight,
        Superscript,
        Subscript,
        Image,
        Essentials,
        Heading,
        Link,
        List,
        Base64UploadAdapter,
        Autoformat,
        UploadAdapter,
        // BlockQuote,
        Table,
        TableToolbar,
        TableProperties,
        TableCellProperties,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        Alignment,
        MediaEmbed,
        H5PEmbed,
        Indent,
        SimpleUploadAdapter,
        ImageResize,
      ],
      toolbar: {
        viewportTopOffset: offsetTop,
        items: [
          "heading",
          "bold",
          "italic",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "highlight",
          "subscript",
          "superscript",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "alignment",
          "indent",
          "outdent",
          "|",
          "imageUpload",
          // "blockQuote",
          "insertTable",
          "mediaEmbed",
          "h5pEmbed",
          "|",
          "undo",
          "redo",
          "|",
        ],
      },
      image: {
        toolbar: [
          "imageStyle:full",
          "imageStyle:alignLeft",
          "imageStyle:alignCenter",
          "imageStyle:alignRight",
          "|",
          "imageTextAlternative",
        ],
        styles: ["full", "alignLeft", "alignCenter", "alignRight"],
      },
      table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
      },
      mediaEmbed: {
        removeProviders: ["instagram", "twitter", "googleMaps", "flickr", "facebook"],
        // setting previewsInData to 'true' prevents embed into becoming semantic oembed which then needs to be deciphered later
        previewsInData: true,
        extraProviders: [
          {
            name: "h5p",
            url: [/^h5p\.org\/(\d+)/, /^h5p\.org\/h5p\/embed\/(\d+)/, /^mobile-learning\.h5p\.com\/content\/(\d+)\/embed/],
            html: (match) => {
              const [url, id] = match;
              return `
              <div style="position: relative;">
              \n <iframe src="https://${url}" aria-label="KVD 1.1.2 Hotspot 1" width="1088" height="537" frameborder="0" allowfullscreen="allowfullscreen" allow="autoplay *; geolocation *; microphone *; camera *; encrypted-media *"></iframe>
              \n <script src="https://mobile-learning.h5p.com/js/h5p-resizer.js"charset="UTF-8"></script>
              </div>`;
            },
          },
          {
            name: "cloudfront",
            url: /https?:\/\/.*\/.*\.mp4/,
            html: (match) => {
              const [url, id] = match;
              return `
            <div style="position: relative;">
              \n <video style="max-width: 100%;" controls="" src="${url}"></video>
            </div>`;
            },
          },
        ],
      },
      simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: IMAGE_UPLOAD_URL,
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: getImageUploadHeaders(learningUnitId),
      },
      // This value must be kept in sync with the language defined in webpack.config.js.
      language: "en",
      heading: {
        options: [
          { model: "paragraph", title: "Normal text", class: "ck-heading_paragraph" },
          { model: "heading2", view: "h2", title: "Heading 1", class: "ck-heading_heading2" },
          { model: "heading3", view: "h3", title: "Heading 2", class: "ck-heading_heading3" },
          { model: "heading4", view: "h4", title: "Heading 3", class: "ck-heading_heading4" },
        ],
      },
      fontSize: {
        options: [9, 11, 13, "default", 17, 19, 21],
      },
    };

    const collaborationConfig = {
      ...defaultConfig,
      plugins: [...defaultConfig.plugins, Comments, TrackChanges],
      toolbar: {
        ...defaultConfig.toolbar,
        items: [...defaultConfig.toolbar.items, isCommentable ? "comment" : [], isTrackable ? "trackChanges" : []].flat(),
      },
      licenseKey: "sOs3x0G68DpK1FTT5vfCyjJ2wLqWUfdQTdSRUYd3I1gTW7mk",
    };

    if (isBasicEditor) {
      return basicConfig;
    }
    if (isCommentableEditor || isTrackableEditor) {
      return collaborationConfig;
    }
    return defaultConfig;
  };

  return (
    <div style={{ display: "flex" }}>
      <Container isLimitOver={isLimitOver}>
        <CKEditor
          className={className}
          editor={inline ? InlineEditor : ClassicEditor}
          config={getConfig(basic, isCommentable)}
          // data={content}
          onInit={(editor) => {
            // You can store the "editor" and use when it is needed.

            if (isCommentable && !!collaborationContext) {
              handleCollaborationInit(editor, {
                ...collaborationContext,
                sidebarElement: ref.current,
                onEditorCommentToolbarClick,
                comments,
                suggestions,
              });
            }

            // We need to load content after loading everything else (i.e. users, comments, suggestions)
            if (!!content) {
              editor.setData(sanitizeContentComments(content, comments));
            }
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            // set isLimitOver flag
            setIsLimitOver(data.length > buffer);
            setContentLength(data.length);
            // check data limit. only fire handler if length is within limit

            if (isCommentable && !!collaborationContext) {
              const updatedComments = getCommentThreads(editor);
              const updatedSuggestions = getSuggestions(editor);

              if (data.length <= buffer) {
                onChange({
                  content: data,
                  comments: JSON.stringify(updatedComments),
                  suggestions: JSON.stringify(updatedSuggestions),
                });
              }
            } else if (data.length <= buffer) {
              onChange({
                content: data,
              });
            }
          }}
          onBlur={(event, editor) => {
            // const trackChanges = editor.plugins.get("TrackChanges");
          }}
          onFocus={(event, editor) => {}}
        />
        {isLimitOver && <MaxLengthReachedMessageContainer>{remainingChars} character(s) over</MaxLengthReachedMessageContainer>}
      </Container>
    </div>
  );
});
export default Editor;

import React from "react";
import styled from "styled-components";

import downloadSvg from "../../../../../public/download.svg"
import pdfSvg from "../../../../../public/pdf_icon.svg"
import txtSvg from "../../../../../public/txt_icon.svg"
import imgSvg from "../../../../../public/img_icon.svg"
import vidSvg from "../../../../../public/vid_icon.svg"
import fileSvg from "../../../../../public/file_icon.svg"
import audioSvg from "../../../../../public/music_icon.svg"


export const DownloadIcon = styled(downloadSvg)`
display: inline;
width: 17px;
height: 17px;
margin-left: 5px;
`;

export const PdfIcon = styled(pdfSvg)`
display: inline-block;
width: 40px;
height: 40px;
padding-right: 0.6rem;
fill: !important #172b4d;

`;

export const TxtIcon = styled(txtSvg)`
display: inline-block;
width: 40px;
height: 40px;
padding-right: 0.6rem;

`;

export const ImgIcon = styled(imgSvg)`
display: inline-block;
width: 40px;
height:40px;
padding-right: 0.6rem;
`;

export const VideoIcon = styled(vidSvg)`
display: inline-block;
width: 40px;
height:40px;
padding-right: 0.6rem;

`;



export const FileIcon = styled(fileSvg)`
display: inline-block;
width: 40px;
height:40px;
padding-right: 0.6rem;

`;

export const AudioIcon = styled(audioSvg)`
display: inline-block;
width: 40px;
height:40px;
padding-right: 0.6rem;
color: !important #172b4d;

`;



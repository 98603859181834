import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { getListStyle } from "./helpers";

const DroppablePalette = ({ children, onDragStart, onDragEnd }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div className="droppablePalette" {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DroppablePalette;

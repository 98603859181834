import styled from "styled-components";
 
/**
* @description Default maximum width of an image when an alignment is initiated
*/
const CKImageMaxWidth = "50%";
 
/**
* @description Default image style margin
*/
const CKImageStyleSpace = "1.5em";
 
/**
* @description Image styles to enable Image Alignment
*              Ref: https://github.com/ckeditor/ckeditor5-image/blob/master/theme/imagestyle.css
*/
const CKImageStyles = `
 figure.image-style-side,
 figure.image-style-align-left,
 figure.image-style-align-center,
 figure.image-style-align-right {
   max-width: ${CKImageMaxWidth};
 }
 
 figure.image-style-side {
   float: right;
   margin-left: ${CKImageStyleSpace};
 }
 
 figure.image-style-align-left {
   float: left;
   margin-right: ${CKImageStyleSpace};
 }
 
 figure.image-style-align-center {
   margin-left: auto;
   margin-right: auto;
 }
 
 figure.image-style-align-right {
   float: right;
   margin-left: ${CKImageStyleSpace};
 }

 /* Only allows resized images to have full width*/
 figure.image_resized.image-style-side,
 figure.image_resized.image-style-align-left,
 figure.image_resized.image-style-align-center,
 figure.image_resized.image-style-align-right {
   max-width: 100%;
 }

  /* Center the img in the figure */
 figure.image_resized img {
   width: 100%;
 }
 
 /* Center the img in the figure */
 figure {
   margin-left: auto;
   margin-right: auto;   
 }
 
 img {
   max-width:100%;
   max-height:100%;
 }
`;
 
export const Container = styled.div`
 ${CKImageStyles}
`;


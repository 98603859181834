import React, { memo, useState, forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import { List } from "immutable";
import { debounce, get, isEmpty, isObject } from "lodash";
import Editor from "../../Editor";
import Paginate from "../../../presentational/Paginate";
import {
  BuilderContainer,
  QuestionBuilderContainer,
  Section,
  QuestionEditorContainer,
  AnswerBuilderListContainer,
  ScaleBuilderListContainer,
  AnswerRadioGroup,
  AddAnswerOptionButton,
  AddScaleOptionButton,
  Remove,
  Label,
  AddQuestionButton,
  AssessmentTop,
  QuestionSelect,
  AnswerSelect,
  Input,
} from "./styled";
import { Content } from "../styled";
import { QUESTION_TYPES, ANSWER_ORDER, ASSESSMENT_STYLE } from "../../../../constants/types";
import {
  addNewQuestion,
  updateQuestion,
  removeQuestion,
  addAnswerOption,
  updateCorrectAnswerOptionForQuestion,
  resetQuestions,
  addScaleOptionToAllQuestions,
  setDefaultScaleOptions,
} from "./actions";
import AnswerOption from "./AnswerOptionsBuilder";
import { ScaleOption, QuestionRow } from "./SurveyBuilder";
import { EDITOR_LENGTH, DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS } from "../../../../constants/miscellaneous";
import { getNewQuestion, getNewAnswerOption } from "./helpers";
import ConfirmDialog from "../../../presentational/ConfirmDialog";

const { Option } = Select;

export const efficientUpdateQuestion = debounce((dispatch, lucId, questionId, questionIndex, questionFields) => {
  dispatch(updateQuestion(lucId, questionId, questionIndex, questionFields, true));
}, DEFAULT_DEBOUNCE_WAIT_INTERVAL_IN_MS);

const QuestionTypePicker = ({ initialValue, handleChange, questionIndex }) => {
  return (
    <QuestionSelect value={initialValue} onChange={handleChange}>
      <Option value={QUESTION_TYPES.MULTIPLE_CHOICE}>{QUESTION_TYPES.MULTIPLE_CHOICE}</Option>
      <Option value={QUESTION_TYPES.FREE_TEXT}>{QUESTION_TYPES.FREE_TEXT}</Option>
      <Option value={QUESTION_TYPES.SURVEY} disabled={questionIndex !== 0}>
        {QUESTION_TYPES.SURVEY}
      </Option>
      <Option value={QUESTION_TYPES.BINARY_RESPONSE} disabled={questionIndex !== 0}>
        {QUESTION_TYPES.BINARY_RESPONSE}
      </Option>
    </QuestionSelect>
  );
};

const AnswerDisplayStylePicker = ({ initialValue, handleChange }) => {
  return (
    <AnswerSelect value={initialValue} onChange={handleChange}>
      <Option value={ANSWER_ORDER.RANDOMISED}>{ANSWER_ORDER.RANDOMISED}</Option>
      <Option value={ANSWER_ORDER.ORDERED}>{ANSWER_ORDER.ORDERED}</Option>
    </AnswerSelect>
  );
};

const QuestionBuilder = memo(({ assessmentQuestion, questionIndex, dispatch, assessmentOpportunityId, onAfterDelete, onContentChange }) => {
  const handleQuestionTypeChange = (value) => {
    if (value === QUESTION_TYPES.SURVEY || value === QUESTION_TYPES.BINARY_RESPONSE) {
      const lucSettings = {
        assessmentStyle: ASSESSMENT_STYLE.NOT_SCORED,
        showResultsUponSubmission: "No",
        passThreshold: 0,
        resitsAllowed: "No",
      };
      onContentChange(lucSettings);
      dispatch(resetQuestions(assessmentOpportunityId, getNewQuestion(value)));
    } else {
      dispatch(updateQuestion(assessmentOpportunityId, assessmentQuestion._id, questionIndex, { type: value, answerOptions: List() }));
    }
  };

  const handleAnswerDisplayStyleChange = (value) =>
    dispatch(updateQuestion(assessmentOpportunityId, assessmentQuestion._id, questionIndex, { answerDisplayStyle: value }));

  const onCorrectAnswerChange = (e, correctAnswerId, answerOptions) => {
    const correctAnswerIndex = e.target.value;
    const cAnsID = answerOptions.get(correctAnswerIndex)._id;
    dispatch(
      updateCorrectAnswerOptionForQuestion(assessmentOpportunityId, assessmentQuestion._id, questionIndex, cAnsID, correctAnswerIndex),
    );
  };

  const deriveCorrectAnswerValue = (answerOptions) => {
    const correctAnswerOptionIndex = answerOptions.findIndex((answerOption) => answerOption.correctValue === true);
    return correctAnswerOptionIndex;
  };

  const deriveCorrectAnswerValueId = (answerOptions) => {
    const correctAnswerOption = answerOptions.find((answerOption) => answerOption.correctValue === true) || [];
    const correctAnswerOptionId = get(correctAnswerOption, "_id", null);
    return correctAnswerOptionId;
  };

  const hasAnswerOptions = assessmentQuestion.answerOptions.size > 0;

  return (
    <BuilderContainer>
      <QuestionBuilderContainer>
        {/* TYPE */}
        <Section>
          <Label>Quiz Type</Label>
          <QuestionTypePicker
            initialValue={assessmentQuestion.type}
            handleChange={handleQuestionTypeChange}
            questionIndex={questionIndex}
          />
        </Section>

        {/* QUESTION */}
        <Section>
          <Label>Question</Label>
          <QuestionEditorContainer>
            <Editor
              maxLength={EDITOR_LENGTH.DEFAULT}
              content={assessmentQuestion.question}
              inline
              onChange={({ content }) =>
                efficientUpdateQuestion(dispatch, assessmentOpportunityId, assessmentQuestion._id, questionIndex, { question: content })
              }
            />
          </QuestionEditorContainer>
        </Section>

        {/* ANSWER OPTIONS */}
        {assessmentQuestion.type === QUESTION_TYPES.MULTIPLE_CHOICE && (
          <Section>
            <AssessmentTop>
              <Label>Answer Options</Label>
              <AnswerDisplayStylePicker
                initialValue={assessmentQuestion.answerDisplayStyle}
                handleChange={handleAnswerDisplayStyleChange}
              />
            </AssessmentTop>
            <AnswerBuilderListContainer>
              {hasAnswerOptions && (
                <AnswerRadioGroup
                  onChange={(e) =>
                    onCorrectAnswerChange(e, deriveCorrectAnswerValueId(assessmentQuestion.answerOptions), assessmentQuestion.answerOptions)
                  }
                  value={deriveCorrectAnswerValue(assessmentQuestion.answerOptions)}
                  buttonStyle="solid"
                >
                  {assessmentQuestion.answerOptions.map((answerOption, index) => (
                    <AnswerOption
                      key={answerOption._id}
                      answerOption={answerOption}
                      answerOptionIndex={index}
                      questionId={assessmentQuestion._id}
                      questionIndex={questionIndex}
                      assessmentOpportunityId={assessmentOpportunityId}
                      dispatch={dispatch}
                    />
                  ))}
                </AnswerRadioGroup>
              )}
              <AddAnswerOptionButton
                onClick={() => dispatch(addAnswerOption(assessmentOpportunityId, assessmentQuestion._id, questionIndex))}
              >
                Add Answer Option
              </AddAnswerOptionButton>
            </AnswerBuilderListContainer>
          </Section>
        )}

        {/* EXPLANATION */}
        <Section>
          <Label>Explanation</Label>
          <QuestionEditorContainer>
            <Editor
              maxLength={EDITOR_LENGTH.DEFAULT}
              content={assessmentQuestion.explanation}
              onChange={({ content }) =>
                efficientUpdateQuestion(dispatch, assessmentOpportunityId, assessmentQuestion._id, questionIndex, { explanation: content })
              }
              inline
            />
          </QuestionEditorContainer>
        </Section>
      </QuestionBuilderContainer>

      <ConfirmDialog placement="left" title="Delete this question?" onConfirm={() => {
        dispatch(removeQuestion(assessmentOpportunityId, assessmentQuestion._id, questionIndex));
        onAfterDelete(questionIndex);
      }}>
        <Remove
          className="RemoveQuestion"
          title="Remove current question"
        />
      </ConfirmDialog>
    </BuilderContainer>
  );
});

const BinaryResponseBuilder = memo(({ assessmentQuestions, dispatch, assessmentOpportunityId, onContentChange }) => {
  useEffect(() => {
    const hasDefaultOptions = assessmentQuestions.some((question) => {
      const options = question.answerOptions || [];
      return options.some((option) => option.value === "Yes" || option.value === "No");
    });
    if (!hasDefaultOptions) {
      dispatch(setDefaultScaleOptions(assessmentOpportunityId));
    }
  }, [assessmentQuestions, assessmentOpportunityId, dispatch]);

  const handleQuestionTypeChange = (value) => {
    const lucSettings = {
      assessmentStyle: ASSESSMENT_STYLE.NOT_SCORED,
      showResultsUponSubmission: "No",
      passThreshold: 0,
      resitsAllowed: "No",
    };
    onContentChange(lucSettings);
    dispatch(resetQuestions(assessmentOpportunityId, getNewQuestion(value)));
  };
  // use the first assessmentQuestion's answerOptions as the scaleOptions
  const assessmentQuestion = assessmentQuestions.get(0);
  return (
    <BuilderContainer>
      <QuestionBuilderContainer>
        {/* TYPE */}
        <Section>
          <Label>Quiz Type</Label>
          <QuestionTypePicker initialValue={QUESTION_TYPES.BINARY_RESPONSE} handleChange={handleQuestionTypeChange} questionIndex={0} />
        </Section>

        {/* QUESTIONS */}
        <AssessmentTop>
          <Label>Binary Questions</Label>
        </AssessmentTop>
        <ScaleBuilderListContainer>
          {assessmentQuestions.map(
            (question, index) =>
              question.type === QUESTION_TYPES.BINARY_RESPONSE && (
                <QuestionRow
                  key={question._id}
                  question={question.question}
                  questionIndex={index}
                  assessmentOpportunityId={assessmentOpportunityId}
                  dispatch={dispatch}
                  charLimit={EDITOR_LENGTH.SURVEY_QUESTION}
                />
              ),
          )}
          <AddAnswerOptionButton
            onClick={() =>
              dispatch(
                addNewQuestion(
                  assessmentOpportunityId,
                  QUESTION_TYPES.BINARY_RESPONSE,
                  assessmentQuestion.answerOptions.map((ao) => {
                    if (ao._id === null || ao._id.startsWith("LOCAL")) {
                      return ao;
                    }
                    return getNewAnswerOption(ao.value, ao.position);
                  }),
                ),
              )
            }
          >
            Add Question
          </AddAnswerOptionButton>
        </ScaleBuilderListContainer>

        {/* FREE TEXT QUESTIONS */}
        <AssessmentTop>
          <Label>Free Text Questions</Label>
        </AssessmentTop>
        <ScaleBuilderListContainer>
          {assessmentQuestions.map(
            (question, index) =>
              question.type === QUESTION_TYPES.FREE_TEXT && (
                <QuestionRow
                  key={question._id}
                  question={question.question}
                  questionIndex={index}
                  assessmentOpportunityId={assessmentOpportunityId}
                  dispatch={dispatch}
                />
              ),
          )}
          <AddAnswerOptionButton onClick={() => dispatch(addNewQuestion(assessmentOpportunityId, QUESTION_TYPES.FREE_TEXT))}>
            Add Free Text Question
          </AddAnswerOptionButton>
        </ScaleBuilderListContainer>
      </QuestionBuilderContainer>
    </BuilderContainer>
  );
});

const SurveyBuilder = memo(({ assessmentQuestions, dispatch, assessmentOpportunityId, onContentChange }) => {
  const handleQuestionTypeChange = (value) => {
    const lucSettings = {
      assessmentStyle: ASSESSMENT_STYLE.NOT_SCORED,
      showResultsUponSubmission: "No",
      passThreshold: 0,
      resitsAllowed: "No",
    };
    onContentChange(lucSettings);
    dispatch(resetQuestions(assessmentOpportunityId, getNewQuestion(value)));
  };
  // use the first assessmentQuestion's answerOptions as the scaleOptions
  const assessmentQuestion = assessmentQuestions.get(0);
  const hasScaleOptions = assessmentQuestion.answerOptions && assessmentQuestion.answerOptions.size > 0;
  const canAddScaleOptions = assessmentQuestion.answerOptions && assessmentQuestion.answerOptions.size < 10;

  return (
    <BuilderContainer>
      <QuestionBuilderContainer>
        {/* TYPE */}
        <Section>
          <Label>Quiz Type</Label>
          <QuestionTypePicker initialValue={QUESTION_TYPES.SURVEY} handleChange={handleQuestionTypeChange} questionIndex={0} />
        </Section>

        {/* SCALE OPTIONS */}
        <Section>
          <AssessmentTop>
            <Label>Scale Options</Label>
          </AssessmentTop>
          <ScaleBuilderListContainer>
            {hasScaleOptions &&
              assessmentQuestion.answerOptions.map((answerOption, index) => (
                <ScaleOption
                  key={answerOption._id}
                  answerOption={answerOption}
                  answerOptionIndex={index}
                  assessmentOpportunityId={assessmentOpportunityId}
                  dispatch={dispatch}
                />
              ))}
            {canAddScaleOptions && (
              <AddScaleOptionButton onClick={() => dispatch(addScaleOptionToAllQuestions(assessmentOpportunityId))}>
                Add Scale Option
              </AddScaleOptionButton>
            )}
          </ScaleBuilderListContainer>
        </Section>

        {/* SCALE OPTIONS DESCRIPTION */}
        <AssessmentTop>
          <Label>Scale Options Description (Optional)</Label>
        </AssessmentTop>
        <Input
          maxLength={EDITOR_LENGTH.SURVEY_OPTION_DESCRIPTION}
          defaultValue={assessmentQuestion.preQuestionText}
          onChange={(e) => {
            const { value } = e.target;
            efficientUpdateQuestion(dispatch, assessmentOpportunityId, assessmentQuestion._id, 0, { preQuestionText: value });
          }}
        />

        {/* QUESTIONS */}
        <AssessmentTop>
          <Label>Question Rows</Label>
        </AssessmentTop>
        <ScaleBuilderListContainer>
          {assessmentQuestions.map(
            (question, index) =>
              question.type === QUESTION_TYPES.SURVEY && (
                <QuestionRow
                  key={question._id}
                  question={question.question}
                  questionIndex={index}
                  assessmentOpportunityId={assessmentOpportunityId}
                  dispatch={dispatch}
                  charLimit={EDITOR_LENGTH.SURVEY_QUESTION}
                />
              ),
          )}
          <AddAnswerOptionButton
            onClick={() =>
              dispatch(
                addNewQuestion(
                  assessmentOpportunityId,
                  QUESTION_TYPES.SURVEY,
                  assessmentQuestion.answerOptions.map((ao) => {
                    if (ao._id === null || ao._id.startsWith("LOCAL")) {
                      return ao;
                    }
                    return getNewAnswerOption(ao.value, ao.position);
                  }),
                ),
              )
            }
          >
            Add Question
          </AddAnswerOptionButton>
        </ScaleBuilderListContainer>

        {/* FREE TEXT QUESTIONS */}
        <AssessmentTop>
          <Label>Free Text Questions</Label>
        </AssessmentTop>
        <ScaleBuilderListContainer>
          {assessmentQuestions.map(
            (question, index) =>
              question.type === QUESTION_TYPES.FREE_TEXT && (
                <QuestionRow
                  key={question._id}
                  question={question.question}
                  questionIndex={index}
                  assessmentOpportunityId={assessmentOpportunityId}
                  dispatch={dispatch}
                />
              ),
          )}
          <AddAnswerOptionButton onClick={() => dispatch(addNewQuestion(assessmentOpportunityId, QUESTION_TYPES.FREE_TEXT))}>
            Add Free Text Question
          </AddAnswerOptionButton>
        </ScaleBuilderListContainer>
      </QuestionBuilderContainer>
    </BuilderContainer>
  );
});

/**
 * An assessment opportunity (quiz) type learning unit component
 *
 * @param {object} assessmentOpportunity
 * @param {function} dispatch -
 */
const AssessmentOpportunityBuilder = memo(
  forwardRef((props, ref) => {
    const { assessmentOpportunity, dispatch, onContentChange } = props;

    // renders an <Error /> if assessmentOpportunity is empty or not an object
    if (isEmpty(assessmentOpportunity) || !isObject(assessmentOpportunity)) {
      return <Content>Error loading assessment opportunity!</Content>;
    }

    const { _id, questions } = assessmentOpportunity;
    if (!questions.find) return <div>Error</div>;
    const firstSurvey = questions.find((q) => q.type === QUESTION_TYPES.SURVEY);
    const isSurvey = !!firstSurvey;
    const fistBinaryResponse = questions.find((q) => q.type === QUESTION_TYPES.BINARY_RESPONSE);
    const isBinaryResponse = !!fistBinaryResponse;

    const [currentQuestionInView, setCurrentQuestionInView] = useState(1);
    const goToQuestion = (questionIndex) => {
      setCurrentQuestionInView(questionIndex);
    };
    const goToNextQuestionIfExists = (questionIndex) => {
      const indexToGoToAfterDelete = questionIndex + 1 < questions.size ? questionIndex + 1 : questionIndex;
      const indexToGoTo = indexToGoToAfterDelete > 0 ? indexToGoToAfterDelete : 1;
      setCurrentQuestionInView(indexToGoTo);
    };
    const appendQuestion = () => {
      setCurrentQuestionInView(questions.size + 1);
      dispatch(addNewQuestion(_id));
    };

    const hasQuestions = questions.size > 0;

    return (
      <Content>
        {hasQuestions &&
          (isSurvey ? (
            <SurveyBuilder
              assessmentQuestions={questions}
              dispatch={dispatch}
              assessmentOpportunityId={_id}
              onContentChange={onContentChange}
            />
          ) : isBinaryResponse ? (
            <BinaryResponseBuilder
              assessmentQuestions={questions}
              dispatch={dispatch}
              assessmentOpportunityId={_id}
              onContentChange={onContentChange}
            />
          ) : (
            <Paginate paginationBarOnTop currentPage={currentQuestionInView} goToPage={goToQuestion}>
              {questions.map((question, index) => (
                <QuestionBuilder
                  key={question._id}
                  assessmentQuestion={question}
                  questionIndex={index}
                  dispatch={dispatch}
                  assessmentOpportunityId={_id}
                  onAfterDelete={goToNextQuestionIfExists}
                  onContentChange={onContentChange}
                />
              ))}
            </Paginate>
          ))}
        {!isSurvey && !isBinaryResponse && <AddQuestionButton onClick={appendQuestion}>ADD QUESTION</AddQuestionButton>}
      </Content>
    );
  }),
);

AssessmentOpportunityBuilder.propTypes = {
  assessmentOpportunity: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    questions: PropTypes.instanceOf(List).isRequired,
  }).isRequired,
};

export default memo(AssessmentOpportunityBuilder);

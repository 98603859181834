import React, { useState } from "react";
import { List } from "immutable";
import { PaginationContainer, Page, PaginationBar, PaginationBarContainer } from "./styled";

const Paginate = ({ children, currentPage = 1, goToPage, paginationBarOnTop }) => {
  const numOfQuestions = children.length || children.size || 0;

  const renderPaginationBar = () => (
    <PaginationBarContainer>
      <PaginationBar
        hideOnSinglePage={true}
        total={numOfQuestions}
        defaultPageSize={1}
        current={currentPage}
        onChange={goToPage}
        defaultPageSize={1}
        hideOnSinglePage
        showQuickJumper
        showSizeChanger={false}
      />
    </PaginationBarContainer>
  );

  const pageToRender = (Array.isArray(children) && children[currentPage - 1]) || (List.isList(children) && children.get(currentPage - 1));

  return (
    <div>
      {paginationBarOnTop && renderPaginationBar()}
      <PaginationContainer>
        <Page>{pageToRender}</Page>
      </PaginationContainer>
      {!paginationBarOnTop && renderPaginationBar()}
    </div>
  );
};

export default Paginate;

import React, { useLayoutEffect, useRef, useState, memo } from "react";
import PropTypes from "prop-types";
import { isArray } from "lodash/fp";
import MediaPaginate from "../MediaPaginate";
import Loading from "../Loading";
import { MediaContainer, Image, Video } from "./styled";
import { calculateContainerHeight, calculateContentDimensions } from "./helper";

/**
 * @description Represents a single file of Media Content (i.e. image or video)
 * @param {object} content - A media item file
 * @param {number} parentWidth - The parent container's width
 * @param {number} parentHeight - The parent container's height
 */
const MediaContent = ({ content, parentWidth, parentHeight }) => {
  // Calculate the content dimensions
  const { contentHeight, contentWidth } = calculateContentDimensions(content, parentWidth, parentHeight);

  if (content.type.startsWith("video") || content.type.startsWith("audio")) {
    return (
      <div>
        <Video
          width={contentWidth}
          height={contentHeight}
          controls
          autoPlay={content.autoPlay}
          loop={content.autoLoop}
          src={content.url}
          type={content.type}
        ></Video>
      </div>
    );
  }
  return <Image src={content.url} alt="" width={contentWidth} height={contentHeight} />;
};

/**
 * @description The main component for displaying the Media Carousel
 * @param {object} contents - List of media files
 */
const MediaCarousel = ({ contents }) => {
  // Calculate the container height
  const containerHeight = calculateContainerHeight(contents);
  // Target ref is used to forwardRef the MediaPaginate component
  const targetRef = useRef(null);
  // Local state to store the container width based on the targetRef
  const [containerWidth, setContainerWidth] = useState(0);

  /**
   * @description Use layout effect to capture the container width
   */
  useLayoutEffect(() => {
    if (targetRef.current) {
      setContainerWidth(targetRef.current.offsetWidth);
      const handleResize = () => {
        if (targetRef.current) {
          setContainerWidth(targetRef.current.offsetWidth);
        }
      };
      window.addEventListener("resize", handleResize);
    }
  }, []);

  // Loading State
  if (!contents || !isArray(contents)) {
    return <Loading />;
  }

  // Render
  return (
    <MediaPaginate ref={targetRef} contents={contents}>
      {contents.map((content, index) => (
        <MediaContainer key={content._id} height={containerHeight}>
          <MediaContent key={content._id} index={index} content={content} parentWidth={containerWidth} parentHeight={containerHeight} />
        </MediaContainer>
      ))}
    </MediaPaginate>
  );
};

MediaCarousel.propTypes = {
  contents: PropTypes.array.isRequired,
};

export default memo(MediaCarousel);
